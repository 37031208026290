import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { VideojsModule } from "../../../../components/videojs/videojs.module";
import { LocalStorageService } from "../../../../services/storage.service";

@Component({
    selector: 'nabi-video',
    templateUrl: 'nabi-video.segment.html',
    styleUrls: [ 'nabi-video.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        VideojsModule
    ]
})
export class NabiVideoSegment {
    public language: any;
    public videojsNabiPromoOptions;

    constructor (private storage: LocalStorageService) {
        this.language = this.storage.get('language');

        this.videojsNabiPromoOptions = {
            fluid: true,
            techOrder: [
                'youtube'
            ],
            sources: [{
                type: 'video/youtube',
                src: 'https://www.youtube-nocookie.com/embed/7V9j1Xl1MPk'
            }],
            youtube: {
                hl: this.language,
                cc_load_policy: 1,
                customVars: {
                    cc_lang_pref: this.language
                }
            }
        }
    }

    openPage($event: MouseEvent, url: string) {
        $event.preventDefault();
        window.open(url, '_blank');
    }

    protected readonly open = open;
}
