<div class="footer user-select-none">
    @if(!isContactActive){
    <section class="contact-div">
        <div class="container m-0 p-0 justify-content-center align-items-center">
            @if(windowWidth <= 768){
                <div class="space adaptive large"></div>
            }@else{
                <div class="space adaptive large"></div>
            }
            <div class="container-fluid m-0 p-0 d-flex flex-column flex-md-row justify-content-center align-items-center">
                <div class="h-auto col-10 col-md-4 m-0 p-0 d-flex justify-content-center">
                    <button type="button" class="contact-btn mb-4 mb-md-0" routerLink="/contact">
                       {{ "FOOTER_CONTACT_US" | translate }}
                        <fa-icon class="fa" [icon]="faAngleRight"></fa-icon>
                    </button>
                </div>
                <div class="col-12 col-md-8 contact-desc">
                   {{ "FOOTER_WRITE_US" | translate }}
                </div>
            </div>
            <div class="space adaptive large"></div>
        </div>
    </section>
    }
    <section class="footer-div">
        <div class="container">
            <div
                class="row justify-content-center align-items-center d-flex m-0 p-0">
                <div class="d-flex justify-content-md-start justify-content-center align-items-center mt-5 mb-md-0 mb-4">
                    <img class="logo" src="assets/logos/logowhite.png" alt="logo" routerLink="/home"/>
                </div>
            </div>
            <div class="row justify-content-center align-items-center m-0 p-0">
                <div class="col-12 col-md-10 row m-0 p-0 gap-4 gap-md-0 pb-3 pb-md-0" >
<!--                    <div class="col-md-3 col-12 text-center text-md-start d-flex flex-column justify-content-start" >-->
<!--                        <span class="d-inline-block ">-->
<!--                            <b>{{ "FOOTER_UPCOMING" | translate }}</b>-->
<!--                        </span>-->
<!--                        <ul>-->
<!--                            <li>-->
<!--                                <a href="https://www.hannovermesse.de/en/" target="_blank">Hannover Messe  - Apr 22-26-->
<!--                                    <fa-icon-->
<!--                                        class="fa"-->
<!--                                        [icon]="faAngleRight">-->
<!--                                    </fa-icon>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li>-->
<!--                                <a href="https://www.bvv.cz/en/urbis" target="_blank">Urbis Smart City - Jun 4-6-->
<!--                                    <fa-icon-->
<!--                                        class="fa"-->
<!--                                        [icon]="faAngleRight">-->
<!--                                    </fa-icon>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
                    <div class="col-md-3 col-12 text-center text-md-start text-md-left d-flex flex-column justify-content-start">
                        <span class="d-inline-block ">
                            <b>
                                {{ "FOOTER_INDUSTRIES" | translate }}
                            </b>
                        </span>
                        <ul>
                            <li>
                                <a routerLink="/construction">{{ "FOOTER_CONSTRUCTION" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/logistics">{{ "FOOTER_LOGISTICS" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/consumer">{{ "FOOTER_CONSUMER" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-3 col-12 text-center text-md-start text-md-left d-flex flex-column justify-content-start">
                        <span class="d-inline-block " >
                            <b>
                                {{ "FOOTER_PRODUCTS"|translate }}
                            </b>
                        </span>
                        <ul>
                            <li>
                                <a (click)="scrollToProducts()"> {{ "FOOTER_TRACKING" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                            <!-- li>
                                <a routerLink="/solution"> {{ "FOOTER_CAR_SECURITY" | translate }} </a>
                            </li -->
                        </ul>
                    </div>
                    <div class="col-md-3 col-12 text-center text-md-start text-md-left d-flex flex-column justify-content-start">
                        <span class="d-inline-block ">
                            <b>
                                {{ "FOOTER_ABOUT_US" | translate }}
                            </b>
                        </span>
                        <ul>
                            <li>
                                <a routerLink="/who-we-are"> {{ "FOOTER_WHO_WE_ARE" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.nabitrack.com/press" target="_blank"> {{ "FOOTER_PRESS" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                            <li>
                                <a routerLink="/contact"> {{ "FOOTER_CONTACT_US" | translate }}
                                    <fa-icon
                                        class="fa"
                                        [icon]="faAngleRight">
                                    </fa-icon>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-md-2 d-flex flex-column align-items-center">

                    <div style="padding: 10px">
                        <a  href="https://appgallery.huawei.com/app/C105271775?sharePrepath=ag&channelId=Website&id=23a4923f7430471abf42e52fa25fa3b0&s=9402AC99DEC89DBCBBBCA0FD22AE34293AD7801F4CDFCC740FBB2201C4727EA2&detailType=0&v=&callType=AGDLINK&installType=0000" target="_blank">
                            <img alt="huawei_app" src="assets/huawei_appgallery_en.svg"/>
                        </a>
                    </div>
                    <div style="padding: 10px">
                        <a  href="https://play.google.com/store/apps/details?id=hu.generalmechatronics.trackerapp" target="_blank">
                            <img alt="google_app" src="assets/Google%20play.svg"/>
                        </a>
                    </div>
                    <div style="padding: 10px">
                        <a href="https://apps.apple.com/us/app/general-track/id1353012691?ls=1" target="_blank">
                            <img  alt="apple_app" src="assets/Apple.svg"/>
                        </a>
                    </div>
                </div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-12 col-md-2 d-flex justify-content-center justify-content-md-start text-center text-md-start pb-4 pb-md-0">
                    <ul>
                        <li>
                            <a class="text-nowrap" href="https://www.general-track.com/aszf_en.html" target="_blank">
                               {{ "FOOTER_TERM" | translate }}
                            </a>
                        </li>
                        <li>
                            <a href="https://www.general-track.com/aszf_en.html#nyolc" target="_blank">
                                {{ "FOOTER_PRIVACY" | translate }}
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-md-8 d-flex flex-column justify-content-center justify-content-md-end text-center align-items-center" style="font-family: poppins; line-height: 1em; color: #fff">
                    <!-- <p style="margin: 0;">&copy;<span id="site-cp-year" style="margin-right: 5px;">{{ year }}</span> <span>{{ "GENERAL_MECHATRONICS_KFT_ALL_RIGHTS_RESERVED" | translate }}</span></p> -->
                    <!-- <p style="margin: 0;">{{ "ALL_TRADEMARKS_REFERENCED_HEREIN_ARE_THE_PROPERTIES_OF_THEIR_RESPECTIVE_OWNERS" | translate }}</p> -->
                    <p class="d-flex flex-md-row flex-column align-items-center"
                       style="margin: 0">
                        <span id="site-cp-year" style="margin-right: 5px; font-size: 14px">
                            2024
                        </span>
                        <span style="font-size: 10px">
                            {{ "FOOTER_GENERAL_MECHATRONICS_KFT_ALL_RIGHTS_RESERVED" | translate }}
                        </span>
                    </p>
                    <p style="margin: 0; font-size: 10px">
                        {{ "FOOTER_ALL_TRADEMARKS_REFERENCED_HEREIN_ARE_THE_PROPERTIES_OF_THEIR_RESPECTIVE_OWNERS" | translate }}
                    </p>
                </div>
                <div class="col-12 col-md-2"></div>
            </div>
        </div>
    </section>
</div>
