<section class="Pane Pane-tiles">
    <div class="Pane-content flex-column">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 InfoBox">
                    <h3>API Documentation</h3>
                    <p>
                        Explore our APIs. Read our guides to learn how to integrate and get started in order to access
                        your data.<br>
                        <b>Host</b>: note that General Track API request paths follow this format:
                        <code>api.general-track.com/{{ "{" }}API version{{ "}" }}/{{ "{" }}API path{{ "}" }}</code>.<br>
                        The current api version is <code>v2</code>
                    </p>
                    <p>
                        The Device API refers to interface devices connected to the internet (identified by the IMEI),
                        which send data (measurements, events, etc..) to the server.
                    </p>
                    <p>
                        You can get your sensor data, events and coordinates via both Device API and Synchronization API
                        endpoints. The main differences between them are as follows:
                    </p>
                    <ul>
                        <li>
                            At <b>Device API</b> endpoints, sensor data can be requested according to the IMEI of the sender
                            device and the time period of the event/measurement. The most common use case is that the
                            data to be displayed is requested directly from here instead of a WEB- or mobile backend
                            database. Get data directly from Device API instead of own database.
                        </li>
                        <li>
                            The <b>Synchronization API</b> helps you mirror the database by getting your data by database
                            insertion time. For example, your server can requests the newly inserted lines once in a
                            minute. You can insert results to your own database and you can generate alarms based on the
                            received sensor values/events.<br>
                            For more detailed information, please read the guide following <a>this link</a>
                        </li>
                    </ul>
                </div>
                <div class="col-12">
                    <div id="swagger-ui"></div>
                </div>

                <div class="col-12 getting-started" id="getting-started">
                    <section>
                        <h3>Getting Started</h3>
                        <p>
                            Welcome to our API reference page! Here you can learn about the tools which we provide you to
                            enrich any of your applications or websites with information received from your devices.
                        </p>
                    </section>
                    <section #loginOrRegisterAnAccount>
                        <h4>Log in or register an account</h4>
                        <p>
                            In order to get started with our API you need to have a General Track device.
                        </p>
                        <p>
                            By purchasing the device you will be invited to the General Track website where you can get
                            your credentials to use the API. We will send you an e-mail with details on how to finish
                            registration and then you can login to the system.
                        </p>
                    </section>
                    <section #testApiInYourBrowser>
                        <h4>Test API in your browser, without programming</h4>
                        <p>
                            Apart from API key authentication, API can also authenticate your request based on your
                            website login WEB session.
                            <a href="https://www.general-track.com/fleet/">https://www.general-track.com/fleet/</a>
                            points to the latest version of the API.
                        </p>
                        <p>
                            Just log in to general-track.com, then open a new browser tab, and list your devices by
                            visiting the following link:
                            <a href="https://www.general-track.com/fleet/device/list">
                                https://www.general-track.com/fleet/device/list
                            </a>
                        </p>
                        <p>
                            You can similarly test the rest of the endpoints as well.
                        </p>
                    </section>
                    <section #obtainingApiKey>
                        <h4>Obtaining API and Private key</h4>
                        <p>
                            Before getting started, you have to generate your own api key which identifies you, and a
                            private key to sign your requests. It can be achieved by visiting the general track website
                            <a href="https://www.general-track.com/fleet/api.php" target="_blank">
                                API manager page <i class="fa fa-external-link-alt"></i>
                            </a>
                        </p>
                    </section>
                    <section #generatingASignature>
                        <h4>Generating a signature</h4>
                        <p>
                            Our API server needs to identify your request to be sure that you are the one who made the
                            request. To achieve this you need to generate a signature and include it in your request
                            header.
                        </p>
                        <p>
                            We use Private and Public keys to verify your identity. You sign your request with the
                            private key, and we validate it by its public key component. When you login to our website
                            you can request your private key, which we show you only once for security reasons, so you
                            need to save it then.
                        </p>
                        <p>
                            We generated the codes required for signature, you simply need to insert them into your
                            programme and use them as presented below.
                        </p>
                        <p>The parameters we use:</p>
                        <ul>
                            <li>
                                <p class="m-0"><code>data</code> - String</p>
                                <p>
                                    The requested API URI with the version number. If there is whitespace in the URI
                                    because of the date, encode it like the code below:
                                </p>
                                <b>Example:</b>
                                <p>
                                    <code>
                                        $data = str_replace(" ', '%20', '/v2/device/track/894823952?from=2019-04-27 10:10:10&to=2019-04-27 10:10:10')
                                    </code>
                                </p>
                            </li>
                            <li>
                                <p class="m-0"><code>keyPath</code> - String</p>
                                <p>
                                    Relative path to the private key.
                                </p>
                                <b>Example:</b>
                                <p><code>'file://privateKey.pem'</code></p>
                            </li>
                        </ul>

                        <div class="CodeExample">
                            <header>
                                <span>PHP</span>
                                <a href="https://www.general-track.com/fleet/static/nabi/libraries/OpenSSL.php.zip">
                                    <i class="fa fa-download"></i>
                                    Download the PHP signature library
                                </a>
                            </header>
                            <pre>
                                <code [highlight]="signatureCode.php" [languages]="['php']"></code>
                            </pre>
                        </div>
                        <div class="CodeExample">
                            <header>
                                <span>NodeJS</span>
                                <a href="https://www.general-track.com/fleet/static/nabi/libraries/OpenSSL.js.zip">
                                    <i class="fa fa-download"></i>
                                    Download the PHP signature library
                                </a>
                            </header>
                            <pre>
                                <code [highlight]="signatureCode.nodejs" [languages]="['typescript']"></code>
                            </pre>
                        </div>
                        <div class="CodeExample">
                            <header>
                                <span>Java</span>
                                <a href="https://www.general-track.com/fleet/static/nabi/libraries/OpenSSL.java.zip">
                                    <i class="fa fa-download"></i>
                                    Download the PHP signature library
                                </a>
                            </header>
                            <pre>
                                <code [highlight]="signatureCode.java" [languages]="['java']"></code>
                            </pre>
                        </div>
                    </section>
                    <section #launchingApiRequests>
                        <h4>Launching API requests</h4>
                        <p>
                            We provide you with a simple PHP code to make use of the signature module and API endpoints.
                            We designed it to be straightforward, but let us briefly summarize the following:
                        </p>

                        <h5>Signing the request</h5>
                        <p>
                            The GT API requires a different signature for each URI request. You need to generate it
                            before every request using the module and the private key.
                        </p>

                        <h5>Generating a URI</h5>
                        <p>
                            You need to use the following syntax:<br>
                            <code>https://api.general-track.com/{{ "{" }}API version{{ "}" }}/{{ "{" }}API path{{ "}" }}/{{ "{" }}IMEI (Optional){{ "}" }}?{{ "{" }}parameters (Optional){{ "}" }}</code><br>
                            The keyword 'optional' indicates that a parameter is not required by each URI:<br>
                            <code>https://api.general-track.com/v2/device/list</code>
                        </p>

                        <div style="display: flex; align-items: center;font-size: var(--fontSize)">
                            <span class="badge" style="background-color: #dc3545; margin-right: 5px;">IMPORTANT</span>
                            <span style="color: #dc3545;">You need to compose the URL by putting the query parameters into alphabetical order.</span>
                        </div>



                        <h5>Making API requests</h5>
                        <p>
                            The code example assumes that the OpenSSL mobule file and the .PEM private key are in the
                            root directory next to the script to be run. Here you can set the endpoint and the API key
                            and make a request to the endpoint with CURL. The key is generated and attached to the
                            request header by the OpenSSL module.
                        </p>

                        <div class="CodeExample">
                            <header>
                                <span>PHP</span>
                            </header>
                            <pre>
                                <code [highlight]="apiRequest" [languages]="['java']"></code>
                            </pre>
                        </div>

                    </section>
                </div>
            </div>
        </div>
    </div>
</section>
