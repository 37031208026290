import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    Validators,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { animate, style, transition, trigger } from '@angular/animations';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { slideInHorizontallyDelay } from "../../animations/slide-in-horizontally-delay.animation";
import { LoadingDirective } from "../../directives/loading.directive";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    standalone: true,
    selector: 'contact-form',
    templateUrl: 'contact-form.component.html',
    styleUrls: ['contact-form.component.scss'],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        DropdownComponent,
        LoadingDirective,
        TranslateModule,
    ],
    animations: [
        trigger('slideVertically', [
            transition(
                ':enter',
                [
                    style({ transform: 'translateY(-15px)', opacity: 0 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(0)', opacity: 1 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
            transition(
                ':leave',
                [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(-15px)', opacity: 0 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
        ]),
        slideInHorizontallyDelay
    ],
})
export class ContactFormComponent implements OnInit {
    private url = 'https://4uuhw4pn6b.execute-api.eu-central-1.amazonaws.com/dev/email/contact';
    private form: FormGroup = new FormGroup({
        interestConstruction: new FormControl(false, []),
        interestLogistics: new FormControl(false, []),
        interestConsumer: new FormControl(false, []),
        interestOther: new FormControl(false, []),
        name: new FormControl(null, [
            Validators.required,
            Validators.minLength(1),
        ]),
        company: new FormControl(null, [
            Validators.required,
            Validators.minLength(1),
        ]),
        email: new FormControl(null, []),
        phone: new FormControl(null, []),
        callbackTime: new FormControl(null, []),
        message: new FormControl(null, []),
        'g-recaptcha-response': new FormControl(null, [ Validators.required ]),
    });

    public response: { success: boolean; message: string } | null = null;
    public isLoading = false;

    public _selectedContactType = new BehaviorSubject<number>(
        ContactType.MESSAGE
    );

    public timeRanges: { name: string; value: any }[] = [
        { name: '9:00 - 11:00 CET', value: 0 },
        { name: '13:00 - 17:00 CET', value: 1 },
    ];

    constructor(private http: HttpClient, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this._selectedContactType.subscribe((contactType) => {
            console.log(contactType);

            this.form.controls['email'].setValidators(
                contactType === 1 ? null : [Validators.required]
            );
            this.form.controls['email'].updateValueAndValidity();
            this.form.controls['message'].setValidators(
                contactType === 1 ? null : [Validators.required]
            );
            this.form.controls['message'].updateValueAndValidity();
            this.form.controls['phone'].setValidators(
                contactType === 0 ? null : [Validators.required]
            );
            this.form.controls['phone'].updateValueAndValidity();
            this.form.controls['callbackTime'].setValidators(
                contactType === 0 ? null : [Validators.required]
            );
            this.form.controls['callbackTime'].updateValueAndValidity();
        });
    }

    public get selectedContactType(): number {
        return this._selectedContactType.value;
    }
    set selectedContactType(value: number) {
        this._selectedContactType.next(value);
    }

    get interestOther() {
        return this.form.controls['interestOther'] as FormControl;
    }
    get interestConsumer() {
        return this.form.controls['interestConsumer'] as FormControl;
    }
    get interestLogistics() {
        return this.form.controls['interestLogistics'] as FormControl;
    }
    get interestConstruction() {
        return this.form.controls['interestConstruction'] as FormControl;
    }
    get name() {
        return this.form.controls['name'] as FormControl;
    }
    get company() {
        return this.form.controls['company'] as FormControl;
    }
    get email() {
        return this.form.controls['email'] as FormControl;
    }
    get phone() {
        return this.form.controls['phone'] as FormControl;
    }
    get message() {
        return this.form.controls['message'] as FormControl;
    }
    get recaptcha() {
        return this.form.controls['g-recaptcha-response'] as FormControl;
    }
    get callbackTime() {
        return this.form.controls['callbackTime'] as FormControl;
    }

    set name(value) {
        this.form.controls['name'].setValue(value);
    }
    set company(value) {
        this.form.controls['company'].setValue(value);
    }
    set email(value) {
        this.form.controls['email'].setValue(value);
    }
    set phone(value) {
        this.form.controls['phone'].setValue(value);
    }
    set message(value) {
        this.form.controls['message'].setValue(value);
    }
    set callbackTime(value) {
        this.form.controls['callbackTime'].setValue(value);
    }
    set recaptcha(value) {
        this.form.controls['g-recaptcha-response'].setValue(value);
    }

    public send() {
        if (!this.isLoading) {
            this.response = null;
            this.isLoading = true;
            this.cdr.detectChanges();

            if (this.isFormValid()) {
                const headers = new HttpHeaders({
                    'g-recaptcha-response': this.recaptcha.value,
                });
                const request = this.http
                    .post(
                        this.url,
                        {
                            page: 'NaBi Meter',
                            name: this.name.value,
                            company_name: this.company.value,
                            email: this.email.value,
                            phone: this.phone.value,
                            message: (() => {
                                switch (this._selectedContactType.value) {
                                    case ContactType.MESSAGE:
                                        return this.message.value;
                                    default:
                                        return `Callback time: ${ this.callbackTime?.value?.name || 'Any time' }`
                                }
                            })(),
                            interests: {
                                construction: this.interestConstruction.value,
                                logistics: this.interestLogistics.value,
                                consumer: this.interestConsumer.value,
                                other: this.interestOther.value
                            },
                            'g-recaptcha-response': this.recaptcha.value,
                        },
                        {
                            headers,
                            responseType: 'json',
                        }
                    )
                    .subscribe({
                        next: (response) => {
                            this.resetFormData();
                            this.response = response as HttpResponse;

                            this.isLoading = false;
                            request.unsubscribe();

                            this.cdr.detectChanges();
                        },
                        error: (error) => {
                            console.log(error);
                            this.response = {
                                success: error.error.success,
                                message: error.error.message,
                            };
                            this.isLoading = false;

                            this.cdr.detectChanges();
                        },
                    });
            } else {
                this.response = {
                    success: false,
                    message: 'All fields are required',
                };
                this.isLoading = false;

                setTimeout(() => (this.response = null), 3000);

                this.cdr.detectChanges();
            }
        }
    }

    private isFormValid() {
        return Object.keys(this.form.controls).reduce((acc, key) => {
            const control = this.form.controls[key] as FormControl;
            control.markAllAsTouched();
            return acc && !control.invalid;
        }, true);
    }

    private resetFormData() {
        for (let key of Object.keys(this.form.controls)) {
            this.form.controls[key].setValue(null);
            this.form.controls[key].markAsUntouched();
        }
    }

    get ContactType() {
        return ContactType;
    }

    get siteKey() {
        return environment.recaptcha.key;
    }
}

interface HttpResponse extends Object {
    success: boolean;
    message: string;
}

export enum ContactType {
    MESSAGE,
    RECALL,
}
