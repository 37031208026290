<div class="Page-container">
    <div class="Page-content">
        <section class="Pane Pane-title">
            <div class="Pane-content flex-column" style="max-width: 1024px">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h1>{{ "FOOTER_WHO_WE_ARE" | translate }}</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="Pane Pane">
            <div class="Pane-content flex-column" style="max-width: 1024px">
                <div class="container-fluid gap-80">
                    <div class="row">
                        <div class="col-12">
                            <h2>{{ "FOOTER_ABOUT_US" | translate }}</h2>

                            <p [innerHTML]='"ABOUT_US_1" | translate'></p>

                            <blockquote [innerHTML]='"ABOUT_US_2" | translate'></blockquote>

                            <p [innerHTML]='"ABOUT_US_3" | translate'></p>
                            <p [innerHTML]='"ABOUT_US_4" | translate'></p>
                            <p [innerHTML]='"ABOUT_US_5" | translate'></p>

                            <blockquote [innerHTML]='"ABOUT_US_6" | translate'></blockquote>

                            <!-- p>
                                We are a proud subsidiary of General
                                Mechatronics Ltd.. Our dedication to
                                sustainability is at the core of everything we
                                do. With our flagship product, the Smart Meter,
                                we aim to combat water leakage and promote water
                                conservation, ensuring that every drop counts.
                            </p>
                            <p>
                                General Mechatronics Ltd. is more than just a
                                manufacturer and vendor of our own branded
                                products. We are dedicated to creating
                                innovative solutions that make a positive impact
                                on the world. Our core expertise lies in the
                                full-range development and serial production of
                                electronic devices.
                            </p>
                            <p>
                                NaBi Meter, one of our flagship solutions
                                crafted carefully to eliminate Non-Revenue Water
                                (NRW) and ensure efficient water usage. Through
                                its state-of-the-art technology, the NaBi Meter
                                empowers users with real-time information about
                                potential water leakages, allowing swift actions
                                and reduced wastage. Water scarcity is a
                                pressing concern, and our planet can no longer
                                afford to lose this precious resource through
                                inefficiencies and leakages. By addressing this
                                very concern, the NaBi Meter isn't just a
                                product; it's a statement of our commitment to
                                safeguarding Earth's most vital resource. With
                                every installation, we take a step closer to a
                                world where water is cherished, conserved, and
                                used wisely.
                            </p>
                            <p>
                                We are more than a team of professionals. We are
                                innovators, sustainability advocates, and
                                technology enthusiasts. Together, we are driving
                                change and making a positive difference in the
                                world, one innovation at a time.
                            </p -->
                        </div>
                    </div>
                    <div class="row meet-the-team">
                        <div class="col-12 p-0 m-0">
                           <h2 class="col-12">{{ "ABOUT_US_MEET_THE_TEAM" | translate }}</h2>
                            <img src="assets/who_we_are/meet_the_team.png" alt="Meet the team" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <h2 [innerHTML]='"ABOUT_US_7" | translate'></h2>
                        </div>
                        <div class="col-12">
                            <h3 [innerHTML]='"ABOUT_US_8" | translate'></h3>

                            <a
                                href="https://www.generalmechatronics.com"
                                target="_blank"
                            >
                                <div class="Banner container-fluid">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <img
                                                src="assets/who_we_are/gm_logo.svg"
                                                alt="General Mechatronics Kft. Logo"
                                            />
                                        </div>
                                        <div
                                            class="d-none d-md-flex col-12 col-md-6"
                                        >
                                            <p style="color: #222">
                                                www.generalmechatronics.com
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <div class="space adaptive large"></div>
                        </div>
                        <div class="col-12">
                            <h3 [innerHTML]='"ABOUT_US_9" | translate'></h3>

                            <a routerLink="/construction">
                                <div class="Banner darker container-fluid" style="background: url(/assets/who_we_are/construction.webp) center center no-repeat;">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4 class="text-center text-md-left">
                                                {{ "CONSTRUCTION" | translate }}
                                            </h4>
                                        </div>
                                        <div class="d-none d-md-flex col-12 col-md-6">
                                            <p>
                                                www.general-track.com/construction
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a routerLink="/logistics">
                                <div
                                    class="Banner darker container-fluid"
                                    style="
                                        background: url(/assets/who_we_are/logistics.webp)
                                            center center no-repeat;
                                    "
                                >
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4
                                                class="text-center text-md-left"
                                            >
                                                {{ "LOGISTICS" | translate }}
                                            </h4>
                                        </div>
                                        <div
                                            class="d-none d-md-flex col-12 col-md-6"
                                        >
                                            <p>www.general-track.com/logistics</p>
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a routerLink="/consumer">
                                <div class="Banner darker container-fluid" style="background: url(/assets/who_we_are/consumer.webp) center center no-repeat;">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <h4 class="text-center text-md-left">
                                                {{ "CONSUMER" | translate }}
                                            </h4>
                                        </div>
                                        <div class="d-none d-md-flex col-12 col-md-6">
                                            <p>www.general-track.com/consumer</p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-12">
                            <div class="space adaptive large"></div>
                        </div>
                        <div class="col-12">
                            <h3>{{ "FLEET_TRACKING" | translate }}</h3>

                            <a href="https://www.general-track.com/gtfleet/index.php" target="_blank">
                                <div class="Banner container-fluid" style="
                                        background: url(/assets/who_we_are/t20.webp)
                                            center center no-repeat;
                                    ">
                                    <div class="row">
                                        <div class="col-12 col-md-6">
                                            <img style="
                                                    max-height: 100px;
                                                    padding: 15px 0;
                                                    box-sizing: border-box;
                                                " src="assets/who_we_are/T20_horizontal.svg" alt="T20 tracker Logo"/>
                                        </div>
                                        <div class="d-none d-md-flex col-12 col-md-6">
                                            <p style="color: #36388e">
                                                www.general-track.com/gtfleet
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div class="col-12">
                            <div class="space adaptive large x2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- section class="Pane Pane-joinUs">
            <div class="Pane-backgroundContainer">
                <div class="Pane-background"></div>
            </div>
            <div class="Pane-content flex-column">
                <div class="container-fluid gap-80">
                    <div class="row m-0">
                        <div class="col-12 col-lg-7">
                            <h2 class="text-capitalize">
                                join us on our journey
                            </h2>
                            <p>
                                Water, the essence of life, is an irreplaceable
                                resource. Every drop counts, and we aim to
                                ensure that not a single one goes to waste.
                                <br />
                                <br />
                                Every drop saved is a testament to our
                                commitment to sustainability and the planet's
                                well-being. At NaBi Meter, we envision a world
                                where water conservation is a norm, not an
                                exception. With your trust, we believe we can
                                make this vision a reality.
                                <br />
                                <br />
                                Discover more about our innovative solutions and
                                be a part of this transformational journey.
                                Let's work together to make small changes that
                                can make a big difference.
                            </p>
                        </div>
                        <div class="col-12 col-lg-5 p-0">
                            <img
                                class="d-block d-lg-none"
                                src="assets/who_we_are/world_droplet.webp"
                                alt="World droplet"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section -->
    </div>
</div>
