<div class="dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        <img id="currentLanguageFlag" [src]="languageIcons[selectedLanguage]">
    </button>
    <ul class="dropdown-menu">
        <li>
            <button (click)="selectLanguage(Language.ENGLISH)">
                <img [src]="languageIcons.en" alt="English"> English
            </button>
        </li>
        <li>
            <button (click)="selectLanguage(Language.HUNGARIAN)">
                <img [src]="languageIcons.hu" alt="Magyar"> Magyar
            </button>
        </li>
        <li>
            <button (click)="selectLanguage(Language.DEUTSCH)">
                <img [src]="languageIcons.de" alt="Deutsch"> Deutsch
            </button>
        </li>
    </ul>
</div>
