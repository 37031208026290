import {Component, Injectable, NgZone} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterLink} from "@angular/router";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import {BehaviorSubject, fromEvent, map, throttleTime} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Injectable({
    providedIn: 'root'
})

export class ScrollService {
    private elementPositionSubject = new BehaviorSubject<number>(0);
    elementPosition$ = this.elementPositionSubject.asObservable();

    constructor(private ngZone: NgZone) {}

    listenToElementPosition(elementId: string) {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll')
                .pipe(
                    throttleTime(10),
                    map(() => document.getElementById(elementId)?.getBoundingClientRect().top || 0)
                )
                .subscribe(pos => {
                    this.ngZone.run(() => {
                        this.elementPositionSubject.next(pos);
                    });
                });
        });
    }
}

@Component({
  selector: 'mobile-application',
  standalone: true,
    imports: [
        CommonModule,
        RouterLink,
        FaIconComponent,
        TranslateModule
    ],
  templateUrl: './general-mobile-app.component.html',
  styleUrl: './general-mobile-app.component.scss',
    animations: [trigger('fadeInOutAnimation', [
        state('in', style({opacity: 1, transform: 'translateY(0)', transition: 'all 0.4s ease'})),
        state('out', style({opacity: 0 , transform: 'translateY(10%)',transition: 'all 0.4s ease'})),
        transition(':enter', [
            style({opacity: 0}),
            animate('0.4s ease', style({opacity: 1, transform: 'translateY(0%)'}))
        ]),
        transition(':leave', [
            animate('0.4s ease', style({opacity: 0, transform: 'translateY(10%)'}))
        ])
    ])]
})
export class GeneralMobileAppComponent {
    protected readonly faAngleRight = faAngleRight;
    showAnimation: string = 'out';

    constructor(private scrollService : ScrollService) {
    }

    ngOnInit() {
        this.scrollService.listenToElementPosition('general-mobile-app');
        this.scrollService.elementPosition$.subscribe((pos: number) => {
            const isMobile = window.innerWidth <= 900;
            if ((isMobile && pos <= 900 && pos > 0) || (!isMobile && pos <= 900 && pos > 0)) {
                this.showAnimation = 'in';
            }
        });
    }
}
