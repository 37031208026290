import { animate, style, transition, trigger } from '@angular/animations';

export const fade = trigger(
    'fade', [
        transition(':enter', [
            style({ opacity: 0 }),
            animate('180ms ease-in-out', style({ opacity: 1 }))
        ], { params: { delay: 0 } }),
        transition(':leave', [
            style({ opacity: 1 }),
            animate('180ms ease-in-out', style({ opacity: 0 }))
        ], { params: { delay: 0 } })
    ]
);
