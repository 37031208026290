import { Routes } from '@angular/router';
import { MainPageComponent} from "../pages/main-page/main-page.component";
import { ConstructionPageComponent } from '../pages/construction-page/construction-page.component';
import { LogisticsPageComponent} from "../pages/logistics-page/logistics-page.component";
import { ConsumerPageComponent } from '../pages/consumer-page/consumer-page.component';
import { ContactPage} from "../pages/contact-page/contact.page";
import { WhowearePageComponent } from '../pages/whoweare-page/whoweare-page.component';
import { WaveEffectComponent } from '../components/test/wave-effect/wave-effect.component';
import {
    ConstructionDocumentationSegment
} from "../pages/construction-page/segments/documentation/documentation.segment";
import { ConsumerDocumentationSegment } from "../pages/consumer-page/segments/documentation/documentation.segment";
import { LogisticsDocumentationSegment } from "../pages/logistics-page/segments/documentation/documentation.segment";
import {AllDocumentationPageComponent} from "../pages/all-documentation-page/all-documentation-page.component";

export const routes: Routes = [
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'home', component: MainPageComponent },
    { path: 'contact', component: ContactPage },
    { path: 'construction', component: ConstructionPageComponent },
    { path: 'logistics', component: LogisticsPageComponent },
    { path: 'consumer', component: ConsumerPageComponent },
    { path: 'who-we-are', component: WhowearePageComponent },
    { path: 'test-wave-effect', component: WaveEffectComponent },

    { path: 'logistics/documentation', component: LogisticsDocumentationSegment },
    { path: 'logistics/documentation/api-docs', component: LogisticsDocumentationSegment },
    { path: 'logistics/documentation/:subpage', component: LogisticsDocumentationSegment },

    { path: 'construction/documentation', component: ConstructionDocumentationSegment },
    { path: 'construction/documentation/api-docs', component: ConstructionDocumentationSegment },
    { path: 'construction/documentation/:subpage', component: ConstructionDocumentationSegment },

    { path: 'consumer/documentation', component: ConsumerDocumentationSegment },
    { path: 'consumer/documentation/api-docs', component: ConsumerDocumentationSegment },
    { path: 'consumer/documentation/:subpage', component: ConsumerDocumentationSegment },

    { path: 'all-documentation', component: AllDocumentationPageComponent},

    { path: '**', redirectTo: '/home', pathMatch: 'full' }
];
