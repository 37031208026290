import {AfterViewInit, Component, ElementRef, HostListener, OnDestroy, ViewChild} from '@angular/core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@Component({
    selector: 'hero2',
    templateUrl: './hero_2.component.html',
    styleUrls: ['./hero_2.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule
    ],
    animations: [
        trigger(
            'slideVertically', [
                transition(':enter', [
                    style({ transform: 'translateY(15px)', opacity: 0 }),
                    animate('180ms', style({ transform: 'translateY(0)', opacity: 1 }))
                ], { params: { delay: 0 } }),
                transition(':leave', [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate('180ms', style({ transform: 'translateY(-15px)', opacity: 0 }))
                ], { params: { delay: 0 } })
            ]
        )
    ]
})
export class Hero2Component implements AfterViewInit, OnDestroy {
    @ViewChild('heroVideo', { read: ElementRef }) heroVideo !: ElementRef;

    public scrollY = 0;
    public menuHeight = window.innerWidth > 768 ? 95 : 50;

    public offset = ((window.innerHeight / 5) + 10);
    public bottom = window.innerHeight + ((window.innerHeight / 5) + 10);

    private isLoaded = false;

    constructor(private translateService: TranslateService, private viewportScroller: ViewportScroller) {
    }

    ngAfterViewInit() {
        console.log('Hero 2 initialized');
    }

    ngOnDestroy() {
    }

    scrollToBottom() {
        if (this.scrollY < this.offset) {
            this.viewportScroller.scrollToPosition([0, this.offset]);
        } else if (this.scrollY >= this.offset) {
            this.viewportScroller.scrollToAnchor('infographic');
        }
    }

    @HostListener('window:scroll', ['$event']) onScroll(e: any) {
        e.preventDefault();

        this.scrollY = window.scrollY;

        if (window.scrollY > 10) {
            (this.heroVideo.nativeElement as HTMLVideoElement).pause();
        } else {
            (this.heroVideo.nativeElement as HTMLVideoElement).play();
        }
    }

    @HostListener('window:load', ['$event']) onLoaded() {
        this.isLoaded = true;
    }

    get icons() {
        return {
            arrowDown: faArrowDown
        };
    }
}
