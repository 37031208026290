<section class="bg-white logistics-section">
    <div class="top-bg">
        <section class="position-absolute start-0 top-0"></section>
    </div>
    <logistics-hero></logistics-hero>
    <meet-our-devices-comp></meet-our-devices-comp>
    <applications-comp id="applications"></applications-comp>
    <nabi-video></nabi-video>
    <ourplatform-comp id="platform"></ourplatform-comp>
    <!-- products-comp id="products"></products-comp -->
    <logistics-products id="products"></logistics-products>
</section>
