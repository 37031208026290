import { CommonModule } from '@angular/common';
import {Component} from '@angular/core';
import { WindowWidthService } from "../../services/window-width.service";
import { VideojsModule } from "../videojs/videojs.module";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { LocalStorageService } from "../../services/storage.service";
import { Subscription } from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

// @ts-ignore
@Component({
    selector: 'nabi-different',
    standalone: true,
    imports: [ CommonModule, VideojsModule, TranslateModule ],
    templateUrl: './nabi-different.component.html',
    styleUrl: './nabi-different.component.scss',
})
export class NabiDifferentComponent {
    language: string = 'en';
    private languageSubscription: Subscription;
    public videojsOptions: any;
    public url = "";
    meetOurDevicesImg: string = '';
    meetOurDevicesMobileImg : string = '';
    howitworksImg: string = '';
    howitworksMobileImg: string = '';

    constructor(private router: Router, private storage: LocalStorageService, private windowWidthService: WindowWidthService, private translateService: TranslateService) {
        this.language = this.storage.get('language');


        this.router.events.subscribe((event : any) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        });

        this.videojsOptions = {
            fluid: true,
            techOrder: [
                'youtube'
            ],
            sources: [{
                type: 'video/youtube',
                src: 'https://www.youtube-nocookie.com/embed/eq9DqANAcN4'
            }],
            youtube: {
                hl: this.language,
                cc_load_policy: 1,
                customVars: {
                    cc_lang_pref: this.language
                }
            }
        }

        this.languageSubscription = translateService.onDefaultLangChange.subscribe(result => {
            this.language = result.lang;
            this.handleSwitchLang()
        })
    }

    ngOnInit() {
        this.handleSwitchLang()
    }

    ngOnDestroy() {
        this.languageSubscription.unsubscribe();
    }

    get windowWidth() {
        return this.windowWidthService.windowWidth;
    }

    handleSwitchLang(){
        switch (this.language) {
            case 'en':
                this.meetOurDevicesImg = 'assets/meet-our-devices/consum_wide_EN.svg';
                this.meetOurDevicesMobileImg = 'assets/meet-our-devices/consum_mobile_EN.svg';
                this.howitworksImg = 'assets/meet-our-devices/abra/consum_EN.svg';
                this.howitworksMobileImg = 'assets/meet-our-devices/abra/consum_mobil_EN.svg';
                break;
            case 'hu':
                this.meetOurDevicesImg = 'assets/meet-our-devices/consum_wide_HU.svg';
                this.meetOurDevicesMobileImg = 'assets/meet-our-devices/consum_mobile_HU.svg';
                this.howitworksImg = 'assets/meet-our-devices/abra/consum_HU.svg';
                this.howitworksMobileImg = 'assets/meet-our-devices/abra/consum_mobil_HU.svg';
                break;
            case 'de':
                this.meetOurDevicesImg = 'assets/meet-our-devices/consum_wide_GE.svg';
                this.meetOurDevicesMobileImg = 'assets/meet-our-devices/consum_mobile_GE.svg';
                this.howitworksImg = 'assets/meet-our-devices/abra/consum_GE.svg';
                this.howitworksMobileImg = 'assets/meet-our-devices/abra/consum_mobil_GE.svg';
                break;
            default:
                this.meetOurDevicesImg = 'assets/meet-our-devices/consum_wide_EN.svg';
                this.meetOurDevicesMobileImg = 'assets/meet-our-devices/consum_mobile_EN.svg';
                this.howitworksImg = 'assets/meet-our-devices/abra/consum_EN.svg';
                this.howitworksMobileImg = 'assets/meet-our-devices/abra/consum_mobil_EN.svg';
                break;
        }
    }
}
