import { CommonModule } from '@angular/common';
import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { WindowWidthService } from "../../services/window-width.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: 'meet-our-devices-comp',
    standalone: true,
    imports: [CommonModule, TranslateModule],
    templateUrl: './meet-our-devices-comp.component.html',
    styleUrl: './meet-our-devices-comp.component.scss',
})
export class MeetOurDevicesCompComponent implements OnDestroy, OnInit {
    language: string = 'en';
    meetOurDevicesImg: string = '';
    meetOurDevicesMobileImg : string = '';
    howitworksImg: string = '';
    howitworksMobileImg: string = '';

    private languageSubscription: Subscription;

    public url = "";

    constructor (private router: Router, private windowWidthService: WindowWidthService, private translateService: TranslateService) {
        this.language = translateService.defaultLang;
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        });

        this.languageSubscription = translateService.onDefaultLangChange.subscribe(result => {
            this.language = result.lang;
            this.handleSwitchLang();
        })
    }

    ngOnInit() {
       this.handleSwitchLang()
    }

    ngOnDestroy() {
        this.languageSubscription.unsubscribe();
    }

    get windowWidth() {
        return this.windowWidthService.windowWidth;
    }

    handleSwitchLang() {
        switch (this.language) {
            case 'en':
                if(this.url.startsWith('/logistics')){
                    this.meetOurDevicesImg = 'assets/meet-our-devices/logi_wide_EN.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/logi_mobil_EN.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/logi_EN.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/logi_mobil_EN.svg';
                }else{
                    this.meetOurDevicesImg = 'assets/meet-our-devices/const_wide_EN.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/const_mobil_EN.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/constr_EN.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/constr_mobil_EN.svg';
                }
                break;
            case 'hu':
                if(this.url.startsWith('/logistics')){
                    this.meetOurDevicesImg = 'assets/meet-our-devices/logi_wide_HU.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/logi_mobil_HU.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/logi_HU.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/logi_mobil_HU.svg';
                }else{
                    this.meetOurDevicesImg = 'assets/meet-our-devices/const_wide_HU.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/const_mobil_HU.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/constr_HU.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/constr_mobil_HU.svg';
                }
                break;
            case 'de':
                if(this.url.startsWith('/logistics')){
                    this.meetOurDevicesImg = 'assets/meet-our-devices/logi_wide_GE.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/logi_mobil_GE.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/logi_GE.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/logi_mobil_GE.svg';
                }else{
                    this.meetOurDevicesImg = 'assets/meet-our-devices/const_wide_GE.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/const_mobil_GE.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/constr_GE.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/constr_mobil_GE.svg';
                }
                break;
            default:
                if(this.url.startsWith('/logistics')){
                    this.meetOurDevicesImg = 'assets/meet-our-devices/logi_wide_EN.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/logi_mobil_EN.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/logi_EN.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/logi_mobil_EN.svg';
                }else{
                    this.meetOurDevicesImg = 'assets/meet-our-devices/const_wide_EN.svg';
                    this.meetOurDevicesMobileImg = 'assets/meet-our-devices/const_mobil_EN.svg';
                    this.howitworksImg = 'assets/meet-our-devices/abra/constr_EN.svg';
                    this.howitworksMobileImg = 'assets/meet-our-devices/abra/constr_mobil_EN.svg';
                }
                break;
        }
    }
}
