import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, Output } from '@angular/core';
import { ContactFormComponent } from '../../contact-form/contact-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faPhone,
    faEnvelope,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import {
    trigger,
    state,
    style,
    transition,
    animate,
} from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';

@Component({
    selector: 'wave-effect',
    standalone: true,
    imports: [CommonModule, ContactFormComponent, FontAwesomeModule],
    templateUrl: './wave-effect.component.html',
    styleUrl: './wave-effect.component.scss',
    animations: [
        trigger('waveAnimation', [
            state(
                'start',
                style({
                    backgroundPosition: '0% 50%',
                })
            ),
            state(
                'end',
                style({
                    backgroundPosition: '100% 50%',
                })
            ),
            transition('start => end', animate('1s ease')),
            transition('end => start', animate('0s')),
        ]),
    ],
})
export class WaveEffectComponent {
    waveState: 'start' | 'end' = 'start';
    contactClass: string = 'general-track';

    faPhone = faPhone;
    faEnvelope = faEnvelope;
    faLocationDot = faLocationDot;

    constructor(private router: Router) {
        // this.router.events.subscribe((event) => {
        //     if (event instanceof NavigationEnd) {
        //         switch (event.url) {
        //             case '/test-wave-effect':
        //                 this.contactClass = 'construction-nabi';
        //                 break;
        //             default:
        //                 break;
        //         }
        //     }
        // });
    }
    startAnimation(theme: string) {
        this.contactClass = theme;
        this.waveState = 'end';
        setTimeout(() => {
            this.waveState = 'start';
        }, 1000);
    }

    @HostBinding('class') get getClass() {
        return this.contactClass;
    }
}
