<div class="Page-container">
    <div class="Page-content">

        <section class="Pane Pane-title">
            <div class="Pane-content flex-column">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="space adaptive small"></div>
                            <h1 class="text-uppercase h1 text-start text-md-start">{{ "CONSUMER_DOCUMENTATION" | translate }}</h1>
                            <p class="px-2 py-3 p-md-0" style="font-family: 'Poppins', sans-serif !important;" [innerHTML]="'DESCRIPTION_DOCUMENTATION' | translate">
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="segments">
            @switch (currentSegment) {
                @case (DocumentationSegment.API_DOCS) {
                    <documentation-api-docs></documentation-api-docs>
                }
                @default {
                    <consumer-documentation-tiles></consumer-documentation-tiles>
                }
            }
        </section>

        <div class="space adaptive large x2"></div>
    </div>
</div>
