import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonModule, ViewportScroller } from "@angular/common";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { animate, style, transition, trigger } from "@angular/animations";
import { Icons } from "../../../../commons/Icons";
import { LocalStorageService } from "../../../../services/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ActivatedRoute, Router, RouterLink } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { RecaptchaErrorParameters } from "ng-recaptcha/lib/recaptcha.component";

@Component({
  standalone: true,
  selector: 'prices-segment',
    imports: [ CommonModule, FaIconComponent, TranslateModule, RouterLink ],
  templateUrl: './prices.component.html',
  styleUrl: './prices.component.scss',
    animations: [
        trigger(
            'slideInFromTop', [
                transition(':enter', [
                    style({ opacity: 0, transform: 'translateY(-100%)' }),
                    animate('300ms 300ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
                ]),
                transition(':leave', [
                    style({ opacity: 1, transform: 'translateY(0)' }),
                    animate('300ms 300ms ease-out', style({ opacity: 0, transform: 'translateY(-100%)' }))
                ])
            ]
        )
    ]
})
export class PricesComponent {
    protected readonly faAngleRight = faAngleRight;
    @ViewChild('section_hero') sectionHero: ElementRef | undefined;

    icons = Icons;
    isLoading = false;
    scrollValue = 0;
    windowWidth = window.innerWidth;
    gap = 0;

    language;

    isLoaded = false;

    packages = Packages;
    selectedId = 0;

    private url = 'https://4uuhw4pn6b.execute-api.eu-central-1.amazonaws.com/dev/email/contact';
    response: { success: boolean, message: string; } | null = null;
    formData = {
        page: 'NaBi Consumer',
        name: '',
        company_name: '',
        email: '',
        phone: '',
        message: '',
        'g-recaptcha-response': null
    };
    isFormLoading = false;


    constructor(private storage: LocalStorageService, private cdr: ChangeDetectorRef, private http: HttpClient, private route: ActivatedRoute,
                private router: Router, private translate: TranslateService, private viewportScroller: ViewportScroller) {
        this.language = this.storage.get('language');
        this.windowWidth = window.innerWidth;
    }

    ngAfterViewInit(): void {
        this.isLoaded = true;
    }

    @HostListener('window:scroll', ['$event'])
    onScroll() {
        this.scrollValue = window.scrollY;
        this.cdr.detectChanges();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.windowWidth = window.innerWidth;

        if (this.sectionHero?.nativeElement) {
            const tempGap = this.sectionHero.nativeElement.getBoundingClientRect().bottom - window.innerHeight;
            this.gap = tempGap > 0 ? tempGap : 0;
        }

        this.cdr.detectChanges();
    }

    sendFormData() {
        if (!this.isFormLoading) {
            this.isFormLoading = true;
            this.cdr.detectChanges();

            if (this.formData.name.length !== 0 && this.formData.phone.length !== 0) {
                const headers = new HttpHeaders({
                    'g-recaptcha-response' : this.formData['g-recaptcha-response']
                } as any);
                // @ts-ignore
                const request = this.http.post(this.url, this.formData, { headers, responseType: 'json' }).subscribe((response: { success: boolean, message: string; }) => {
                    this.resetFormData();
                    this.response = response;

                    this.isFormLoading = false;
                    request.unsubscribe();

                    this.cdr.detectChanges();
                }, (error => {
                    console.log(error);
                    this.response = { success: error.error.success, message: error.error.message };
                    this.isFormLoading = false;

                    this.cdr.detectChanges();
                }));
            } else {
                this.response = { success: false, message: this.translate.instant('ALL_FIELDS_ARE_REQUIRED') };
                this.isFormLoading = false;

                this.cdr.detectChanges();
            }
        }
    }

    recaptchaResolve(response: string) {
        // @ts-ignore
        this.formData['g-recaptcha-response'] = response;
    }

    recaptchaReject(errorDetails: RecaptchaErrorParameters) {
        this.formData['g-recaptcha-response'] = null;
        console.error(errorDetails);
    }

    order(): void {
        this.router.navigate(['/', 'order'], {
            state: {
                selectedId: this.selectedId
            }
        });
    }

    resetFormData() {
        this.formData = { page: 'NaBi Consumer', name: '', company_name: '', email: '', phone: '', message: '', 'g-recaptcha-response': null };
    }
}

export enum Packages {
    EGYOSSZEGU,
    HAVIDIJAS
}

enum SubPages {
    HERO = 'hero',
    TECHNOLOGY = 'technology',
    PRICES = 'prices',
    CONTACT = 'contact'
}

