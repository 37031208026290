<div class="ourpoducts-bg">
    @if(windowWidth > 768){
        <div class="space adaptive large"></div>
<!--        <div class="space adaptive large x2"></div>-->
    }

    <section id="ourproducts-section" [@fadeInOutAnimation]="showAnimation" class="ourproducts-section container pt-5 text-white">
        <div class="ourprod-header">
            <h2>{{"OUR_PRODUCTS" | translate}}</h2>
        </div>
        <div class="space adaptive small x2"></div>

        <div class="row">
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.solo" (click)="setState('solo')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/nabi_solo.png.webp" alt="Nabi Solo"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/nabi_solo_render.webp" alt="Nabi Solo Render"/>
                        </div>
                        <button type="button" class="btn btn-primary btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.signal"></fa-icon>
                                    <div>
                                        <b>{{ "CELLULAR_COMMUNICATION" | translate }}</b>
                                        <p>{{ "ULTRA_LOW_POWER_CONSUMPTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <b>{{ "GPS_POSITION" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/orientation.svg" alt="Oriantation icon">
                                    <div>
                                        <b>{{ "MOVEMENT_AND_OVERTURNING" | translate }}</b>
                                        <p>{{ "FREEFALL_DETECTION_TILT_DETECTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/vibration.svg" alt="Vibration icon">
                                    <div>
                                        <b>{{ "VIBRATION" | translate }}</b>
                                        <p>{{ "WORKHOUR_METERING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>4.3cm × 7.5cm</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_SOLO_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.connect" (click)="setState('connect')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/nabi_connect.png.webp" alt="Nabi Connect"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/nabi_connect_render.webp" alt="Nabi Connect Render"/>
                        </div>
                        <button type="button" class="btn btn-primary btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                   <fa-icon [icon]="icons.signal"></fa-icon>
                                    <div>
                                        <b>{{ "CELLULAR_COMMUNICATION" | translate }}</b>
                                        <p>{{ "ULTRA_LOW_POWER_CONSUMPTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <b>{{ "GPS_POSITION" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/orientation.svg" alt="Oriantation icon">
                                    <div>
                                        <b>{{ "MOVEMENT_AND_OVERTURNING" | translate }}</b>
                                        <p>{{ "FREEFALL_DETECTION_TILT_DETECTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/vibration.svg" alt="Vibration icon">
                                    <div>
                                        <b>{{ "VIBRATION" | translate }}</b>
                                        <p>{{ "WORKHOUR_METERING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>4.3cm × 7.5cm</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.indoor"></fa-icon>
                                    <div>
                                        <b [style.color]="'#00a5ff'">{{ "INDOOR_POSITIONING" | translate }}</b>
                                        <p>{{ "LOCALIZE_YOU_EQUIPMENT_WITHIN_THE_BUILDING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <b [style.color]="'#00a5ff'" [innerHTML]="'CONNECT_WITH_NABI_SENSE' | translate"></b>
                                        <p [innerHTML]="'SUPPORT_OF_SMALL_EXTERNAL_LOW_COST_BLUETOOTH_TAGS' | translate"></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_CONNECT_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div></div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.t20" (click)="setState('t20')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/t20.png.webp" alt="T20 tracker"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/T20_render.webp" alt="T20 Render"/>
                        </div>
                        <button type="button" class="btn btn-primary btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <p style="padding: 32px 0;">{{ "T20_DESC" | translate }}</p>

                            <b>{{ "SERVICES" | translate }}</b>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <p>{{ "CONTINUOUS_GPS_VEHICLE_TRACKING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/geofence.svg" alt="Geofence logo">
                                    <div>
                                        <p>{{ "GEOFENCE" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.alarm"></fa-icon>
                                    <div>
                                        <p>{{ "PERSONALIZED_ALARMS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.excel"></fa-icon>
                                    <div>
                                        <p>{{ "ISSUE_LETTERS_OF_TRANSIT" | translate }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a class="btn btn-primary btn-block" [href]="'T20_URL' | translate" target="_blank">{{ "MORE_DETAILS" | translate }}</a>
                    </div>
                </div></div>
            <div class="d-none col-0 col-md-0 d-xl-block col-xl-2"></div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.connectXL" (click)="setState('connectXL')">
                    <div class="card-side front">
                        <div class="card-side front">
                            <div class="image-container">
                                <img src="assets/logos/nabi_connectXL_horizontal_WHITE.svg" alt="Nabi Solo XL">
                            </div>
                            <div class="image-container render">
                                <img style="max-height: 26rem;" src="assets/logistics/abra/NaBi_ConnectXL.1.webp" alt="Nabi Solo XL Render">
                            </div>
                            <button type="button" class="btn btn-primary btn-block">{{ "SHOW_MORE" | translate }}</button>
                        </div>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.signal"></fa-icon>
                                    <div>
                                        <b>{{ "CELLULAR_COMMUNICATION" | translate }}</b>
                                        <p>{{ "ULTRA_LOW_POWER_CONSUMPTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <b>{{ "GPS_POSITION" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/orientation.svg" alt="Oriantation icon">
                                    <div>
                                        <b>{{ "MOVEMENT_AND_OVERTURNING" | translate }}</b>
                                        <p>{{ "FREEFALL_DETECTION_TILT_DETECTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/vibration.svg" alt="Vibration icon">
                                    <div>
                                        <b>{{ "VIBRATION" | translate }}</b>
                                        <p>{{ "WORKHOUR_METERING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>4.3cm × 14cm</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.indoor"></fa-icon>
                                    <div>
                                        <b [style.color]="'#00a5ff'">{{ "INDOOR_POSITIONING" | translate }}</b>
                                        <p>{{ "LOCALIZE_YOU_EQUIPMENT_WITHIN_THE_BUILDING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <b [style.color]="'#00a5ff'" [innerHTML]="'CONNECT_WITH_NABI_SENSE' | translate"></b>
                                        <p>{{ "SUPPORT_OF_SMALL_EXTERNAL_LOW_COST_BLUETOOTH_TAGS" | translate }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_CONNECT_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.sense" (click)="setState('sense')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/logos/nabi_sense_WHITE.svg" alt="Nabi Sense"/>
                        </div>
                        <div class="image-container render" style="padding: 20px 66px; display: flex; justify-content: center;">
                            <img src="assets/logistics/abra/NaBi-Sense_render.1.webp" style="padding: 25px 0; max-width: 180%; pointer-events: none;" alt="Nabi Sense Render"/>
                        </div>
                        <button type="button" class="btn btn-primary btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>

                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.earth"></fa-icon>
                                    <div>
                                        <b>{{ "EXTERNAL_ENVIRONMENTAL_SENSORS" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.humidity"></fa-icon>
                                    <div>
                                        <b>{{ "HUMIDITY_SENSOR" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.door"></fa-icon>
                                    <div>
                                        <b>{{ "DOOR_OPENING_SENSOR" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.light"></fa-icon>
                                    <div>
                                        <b>{{ "LIGHT_SENSOR" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>6cm × 3.3cm × 2cm</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_SENSE_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-0 col-md-0 col-lg-2"></div>
        </div>
        <div class="space adaptive large"></div>

        <!-- section class="row products-col">
            <div class="col-12 col-md-2"></div>
            <div class="col-12 col-md-4">
                <div class="Product">
                    <header>
                        <img src="assets/Tracking.webp" alt="lock" />
                        <h3 class="text-uppercase">{{ "TRACKING" | translate }}</h3>
                    </header>

                    <ul>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "TRACKING_DESC_1" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "TRACKING_DESC_2" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "TRACKING_DESC_3" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "TRACKING_DESC_4" | translate }}</p>
                        </li>
                    </ul>

                    <footer>
                        <button>
                            {{ "LEARN_MORE" | translate }} >
                        </button>
                    </footer>
                </div>
            </div>
            <div class="col-12 col-md-4">
                <div class="Product">
                    <header>
                        <img src="assets/nontransp.webp" alt="lock" />
                        <h3 class="text-uppercase"> {{ "CAR_SECURITY" | translate }} </h3>
                    </header>

                    <ul>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "CAR_SECURITY_DESC_1" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "CAR_SECURITY_DESC_2" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "CAR_SECURITY_DESC_3" | translate }}</p>
                        </li>
                        <li>
                            <img src="assets/pipa.png" alt="lock"/>
                            <p>{{ "CAR_SECURITY_DESC_4" | translate }}</p>
                        </li>
                    </ul>

                    <footer>
                        <button>
                            {{ "LEARN_MORE" | translate }} >
                        </button>
                    </footer>
                </div>
            </div>
            <div class="col-12 col-md-2"></div>
        </section -->
    </section>
</div>
