import {faAngleRight, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faBatteryFull } from '@fortawesome/free-solid-svg-icons/faBatteryFull';
import { faBroadcastTower } from '@fortawesome/free-solid-svg-icons/faBroadcastTower';
import { faBug } from '@fortawesome/free-solid-svg-icons/faBug';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faCity } from '@fortawesome/free-solid-svg-icons/faCity';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons/faCreditCard';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faEuroSign } from '@fortawesome/free-solid-svg-icons/faEuroSign';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faFile } from '@fortawesome/free-solid-svg-icons/faFile';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons/faLongArrowAltLeft';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons/faLongArrowAltRight';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import { faMoneyBillWave } from '@fortawesome/free-solid-svg-icons/faMoneyBillWave';
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons/faShieldAlt';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons/faShoppingCart';
import { faStoreAlt } from '@fortawesome/free-solid-svg-icons/faStoreAlt';
import { faTruck } from '@fortawesome/free-solid-svg-icons/faTruck';

export const Icons = {
    faArrowDown,
    mapMarkerAlt: faMapMarkerAlt,
    phone: faPhone,
    envelope: faEnvelope,
    info: faInfoCircle,
    warning: faExclamationTriangle,
    error: faExclamationCircle,
    success : faCheckCircle,
    bug: faBug,
    batteryFull: faBatteryFull,
    euroSign: faEuroSign,
    city: faCity,
    broadcastTower: faBroadcastTower,
    shield: faShieldAlt,
    angleLeft: faAngleLeft,
    angleRight: faAngleRight,
    external: faExternalLinkAlt,
    download: faDownload,
    shoppingCart: faShoppingCart,
    file: faFile,
    check: faCheck,
    mail: faEnvelope,
    leftLongArrow: faLongArrowAltLeft,
    rightLongArrow: faLongArrowAltRight,
    truck: faTruck,
    store: faStoreAlt,
    cash: faMoneyBillWave,
    card: faCreditCard
};
