import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { PageFooterComponent} from "../../components/footer-comp/page-footer.component";
import { slideVertically } from '../../animations/slide-vertically.animation';
import { slideInVertically } from '../../animations/slide-in-vertically.animation';
import { slideInHorizontallyDelay } from '../../animations/slide-in-horizontally-delay.animation';
import { CirclesComponent } from './segments/circles/circles.component';
import { OurproductsComponent } from './segments/ourproducts/ourproducts.component';
import { ApplicationsComponent } from './segments/applications/applications.component';
import { ConsumerComponent } from './segments/consumer/consumer.component';
import { GeneralMobileAppComponent } from "../../components/general-mobile-app/general-mobile-app.component";
import { OurplatformPageComponent } from "../../components/ourplatform-comp/ourplatform-comp.component";
import { VideoSegment } from "./segments/video-segment/video.segment";

@Component({
    standalone: true,
    selector: 'main-page',
    templateUrl: 'main-page.component.html',
    styleUrls: ['main-page.component.scss'],
    imports: [
        CommonModule,
        PageFooterComponent,
        CirclesComponent,
        OurproductsComponent,
        ApplicationsComponent,
        ConsumerComponent,
        GeneralMobileAppComponent,
        OurplatformPageComponent,
        VideoSegment,
    ],
    animations: [slideVertically, slideInVertically, slideInHorizontallyDelay],
})
export class MainPageComponent {
    constructor() {
    }
}
