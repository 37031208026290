import { ApplicationConfig } from '@angular/core';
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    provideRouter,
    withInMemoryScrolling,
} from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { routes } from './app.routes';
import { HttpClient, provideHttpClient, withFetch } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'top',
    anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/lang/', '.json');
}

const availableLanguages = ['en', 'hu', 'de'];

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes, inMemoryScrollingFeature),
        provideAnimations(),
        provideHttpClient(withFetch()),
        TranslateModule.forRoot({
            defaultLanguage: (() => {
                const storageLanguage = (() => {
                    const language = window.localStorage.getItem('language');
                    if (language) {
                        return JSON.parse(language);
                    } else {
                        return null;
                    }
                })();

                console.log(storageLanguage);

                if (storageLanguage && availableLanguages.includes(storageLanguage)) {
                    window.localStorage.setItem('language', JSON.stringify(storageLanguage));
                    return storageLanguage;
                }

                const browserLanguage = navigator.language.slice(0, 2);
                if (availableLanguages.includes(browserLanguage)) {
                    window.localStorage.setItem('language', JSON.stringify(browserLanguage));
                    return browserLanguage;
                } else {
                    window.localStorage.setItem('language', JSON.stringify(browserLanguage));
                    return 'en';
                }
            })(),
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [
                    HttpClient
                ]
            }
        }).providers!
    ],
};
