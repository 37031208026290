<div class="container">
    <div class="space adaptive large"></div>
    <div class="row">
    <h2>{{"VIDEO_HEADER" | translate}}</h2>
<!--        <div class="col-12 d-flex">-->
<!--            <h2 [innerHTML]="'WE_CONFIGURE_NABI_ACCORDING_TO_YOUR_NEEDS' | translate"></h2>-->
<!--        </div>-->
            <div class="space adaptive small"></div>
        <div class="col-12 video-div">
            <div>
                <app-videojs
                    [id]="'promo-video-2'"
                    [language]="language"
                    [options]='videojsNabiPromoOptions'>
                </app-videojs>
            </div>
        </div>
    </div>
    <div class="space adaptive large"></div>
</div>
