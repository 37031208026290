<section>
    <div class="space adaptive large"></div>

    <div class="container" [style.margin-bottom.px]="0" [style.padding.px]="0" [@fadeInOutAnimation]="showAnimation">
        <div class="row">
            <div class="col-12">
                <h2>
                    <img src="assets/GT_icon.png" alt="GT_icon"/>
                    <span>
                        {{ "GENERAL_TRACK_MOBILE_APP"  | translate }}
                    </span>
                </h2>
            </div>
        </div>
    </div>
    <div class="container" [style.background]="'var(--panel-background)'" id="general-mobile-app" [@fadeInOutAnimation]="showAnimation">
        <div class="row flex-column-reverse flex-xl-row">
            <div class="col-12 col-xl-7">
                <div class="row flex-column-reverse flex-xl-row">
                    <div class="col-12 col-xl-8">
                        <div class="row">
                            <div class="col-6">
                                <div class="FeatureIcon">
                                    <img src="assets/icons/Ikon%20Map.svg" alt="map_icon"/>
                                    {{ "APP_FEATURE_1" | translate }}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="FeatureIcon">
                                    <img src="assets/icons/Ikon%20Ora.svg" alt="clock_icon"/>
                                    {{ "APP_FEATURE_2"  | translate }}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="FeatureIcon">
                                    <img src="assets/icons/Ikon%20Karbantart.svg" alt="karbantart_icon"/>
                                    {{ "APP_FEATURE_3"  | translate }}
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="FeatureIcon">
                                    <img src="assets/icons/Ikon%20grapfh.svg" alt="graph_icon"/>
                                    {{ "APP_FEATURE_4"  | translate }}
                                </div>
                            </div>

                            <div class="col-12 d-flex justify-content-end">
                                <a class="button" href="https://www.general-track.com/fleet/gtapp/" target="_blank">
                                    {{ "LEARN_MORE_ABOUT_THE_APP" | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-4">
                        <p [innerHTML]="'APP_DESCRIPTION' | translate"></p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-xl-5">
                <div class="row">
                    <div class="col-6 d-flex justify-content-center">
                        <div class="MobileImageContainer">
                            <video autoplay="" loop="" muted="" playsinline="" id="promoVideo" width="340" height="612">
                                <source src="assets/screen.mp4" type="video/mp4">
                                <span data-translate-text="">Az Ön böngészőjében nincs engedélyezve a videók lejátszása.</span>
                            </video>
                            <img class="MobileImage" src="assets/android_phone.png" alt="phone"/>
                        </div>
                    </div>
                    <div class="col-6 store-buttons">
                        <a href="https://apps.apple.com/us/app/general-track/id1353012691?ls=1" target="_blank">
                            <img src="assets/construction/apple.svg" alt="apple"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=hu.generalmechatronics.trackerapp" target="_blank">
                            <img src="assets/construction/google.svg" alt="google"/>
                        </a>
                        <a href="https://appgallery.huawei.com/app/C105271775?sharePrepath=ag&channelId=Website&id=23a4923f7430471abf42e52fa25fa3b0&s=9402AC99DEC89DBCBBBCA0FD22AE34293AD7801F4CDFCC740FBB2201C4727EA2&detailType=0&v=&callType=AGDLINK&installType=0000" target="_blank">
                            <img src="assets/construction/huawei_appgallery_en.svg" alt="huawei"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="space adaptive large"></div>
</section>
