import { Component, EventEmitter, Inject, NgZone, OnInit, Output } from '@angular/core';
import { Router } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { RecaptchaLoaderService } from "ng-recaptcha";
// import { RecaptchaDynamicLanguageLoaderService } from "../../services/recaptcha-dynamic-language-loader.service";
import { LocalStorageService } from "../../services/storage.service";
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-language-changer',
    templateUrl: './language-changer.component.html',
    styleUrls: ['./language-changer.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,

    ]
})
export class LanguageChangerComponent implements OnInit {
    @Output() changeLanguage = new EventEmitter<string>();

    languageIcons = {
        en: 'assets/flag_en.png',
        hu: 'assets/flag_hu.png',
        de: 'assets/flag_de.png',
    };
    selectedLanguage: Language = Language.ENGLISH;
    loaderReady = false;

    protected readonly Language = Language;

    constructor(public routes: Router, public translator: TranslateService, private zone: NgZone,
                // @Inject(RecaptchaLoaderService) private loader: RecaptchaDynamicLanguageLoaderService,
                private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        /*
        this.loader.ready.subscribe(v => {
            setTimeout(() => this.zone.run(() => this.loaderReady = !!v), 0);
        });
        */

        const language = this.localStorageService.get('language') || 'en';

        if (!language || !['en', 'hu', 'de'].includes(language)) {
            this.selectedLanguage = this.translator.getDefaultLang() as Language;
            this.localStorageService.set('language', this.translator.getDefaultLang());
            this.recaptchaLang = this.translator.getDefaultLang();
        } else {
            this.selectedLanguage = language;
            this.recaptchaLang = language;
        }

        this.translator.onDefaultLangChange.subscribe(selectedLanguage => {
            this.changeLanguage.emit(selectedLanguage.lang);
        });
    }

    selectLanguage(language: Language) {
        this.selectedLanguage = language;
        this.translator.setDefaultLang(language);
        this.localStorageService.set('language', language);

        console.log(language);

        this.recaptchaLang = language;
    }

    public get recaptchaLang() {
        const language = this.localStorageService.get('language');
        if (!language) {
            return this.translator.getDefaultLang();
        } else {
            return language;
        }
    }

    public set recaptchaLang(value: string) {
        // this.loader.updateLanguage(value);
    }
}

export enum Language {
    HUNGARIAN = 'hu',
    ENGLISH = 'en',
    DEUTSCH = 'de'
}
