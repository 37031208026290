import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {ApiDocsSegment} from "../documentation-page/segments/api_docs/api_docs.segment";
import {TilesSegment} from "../documentation-page/segments/tiles/tiles.segment";
import {RouterLink} from "@angular/router";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'all-documentation-page',
  standalone: true,
    imports: [CommonModule, ApiDocsSegment, TilesSegment, RouterLink, TranslateModule],
  templateUrl: './all-documentation-page.component.html',
  styleUrl: './all-documentation-page.component.scss'
})
export class AllDocumentationPageComponent {
    constructor() {
    }
}
