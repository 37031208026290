import { animate, style, transition, trigger } from '@angular/animations';

export const slideVertically = trigger('slideVertically', [
    transition(
        ':enter',
        [
            style({ transform: 'translateY(-15px)', opacity: 0 }),
            animate(
                '{{delay}}' ? '180ms {{delay}}ms' : '180ms',
                style({ transform: 'translateY(0)', opacity: 1 })
            ),
        ],
        { params: { delay: 0 } }
    ),
    transition(
        ':leave',
        [
            style({ transform: 'translateY(0)', opacity: 1 }),
            animate(
                '{{delay}}' ? '180ms {{delay}}ms' : '180ms',
                style({ transform: 'translateY(-15px)', opacity: 0 })
            ),
        ],
        { params: { delay: 0 } }
    ),
]);
