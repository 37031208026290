import {AfterViewInit, Component, Input} from '@angular/core';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';
import {LocalStorageService} from '../../../../services/storage.service';
import {faTemperatureHigh} from '@fortawesome/free-solid-svg-icons/faTemperatureHigh';
import {faRuler} from '@fortawesome/free-solid-svg-icons/faRuler';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import {faBell} from '@fortawesome/free-solid-svg-icons/faBell';
import {faFileExcel} from '@fortawesome/free-solid-svg-icons/faFileExcel';
import {CommonModule} from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faCircleXmark, faHouseChimneyUser, faSignal} from "@fortawesome/free-solid-svg-icons";

@Component({
    standalone: true,
    selector: 'new-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
    imports: [ CommonModule, TranslateModule, FontAwesomeModule ]
})
export class ProductsComponent implements AfterViewInit {
    @Input() breakpoint: number | undefined;

    language;

    public cardState = {
        solo: false,
        connect: false,
        t20: false
    };

    constructor(private storage: LocalStorageService, private translate: TranslateService) {
        this.language = this.storage.get('language');
    }

    ngAfterViewInit() {
        // this.onResize();
        console.log('Products initialized');
    }

    setState(product: string) {
        // @ts-ignore
        this.cardState[product] = !this.cardState[product];
    }

    get icons() {
        return {
            dummy: faDotCircle,
            marker: faMapMarkerAlt,
            temperature: faTemperatureHigh,
            size: faRuler,
            alarm: faBell,
            excel: faFileExcel,
            signal: faSignal,
            indoor: faHouseChimneyUser
        };
    }

    openPage($event: MouseEvent, url: string) {
        $event.preventDefault();
        const translatedUrl = this.translate.instant(url);
        window.open( translatedUrl, '_blank');
    }

    download($event: MouseEvent, key: string) {
        $event.preventDefault();

        const url = this.translate.instant(key);

        const a: HTMLAnchorElement = document.createElement('a')
        a.href = this.translate.instant(url);
        // @ts-ignore
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    protected readonly faClose = faCircleXmark;
}
