<section class="prices-section">
    <div class="space adaptive small"></div>
    <div class="container">
        <h2 class="d-flex justify-content-center text-center">
            {{ "ORDER" | translate }}
        </h2>
        <div class="row m-0 mb-2 p-0">
            <div class="space adaptive small"></div>
            <div class="row d-flex flex-column-reverse flex-lg-row justify-content-center gap-4 gap-lg-0">

                <div class="col-12 col-lg-6 reddot-imgs">
                    <div class="d-flex flex-row">
                        <img src="assets/construction/NaBi_Solo_render.1_trimmed.webp">
                        <img src="assets/consumer/NaBi_Reddot.webp">
                    </div>
                </div>

                <div class="col-12 col-lg-6 desc-div">
                    <div class="d-flex flex-column">
                        <p class="nabi-bold" [innerHTML]="'NABI_PRICE_DESC_1' | translate"></p>
                        <p [innerHTML]="'NABI_PRICE_DESC_2' | translate"></p>
                    </div>
                    <div class="d-flex justify-content-end align-items-center">
                        <a class="button" href="https://www.red-dot.org/project/nabi-solo-51914" target="_blank">
                            {{ "LEARN_MORE_REDDOT" | translate }}
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center" style="margin-top: 10px;">
                <div style="width: 90%;">
                    <a class="btn btn-order" href="https://www.nabitrack.com/order">
                        {{ "ORDER" | translate }}
                    </a>
                </div>
            </div>
            <div class="space adaptive small"></div>
        </div>
    </div>
    <div class="space adaptive large"></div>
</section>
