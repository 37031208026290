import { AfterViewInit, Component } from "@angular/core";
import SwaggerUI from 'swagger-ui'
import { CommonModule, ViewportScroller } from "@angular/common";
import { HighlightModule } from "ngx-highlightjs";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

@Component({
    selector: 'documentation-api-docs',
    templateUrl: 'api_docs.segment.html',
    styleUrls: [ 'api_docs.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule,
        HighlightModule
    ]
})
export class ApiDocsSegment implements AfterViewInit {
    private fragment: string | null = null;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private viewPortScroller: ViewportScroller) {
        this.activatedRoute.fragment.subscribe(fragment => {
            this.fragment = fragment;
        });
    }

    ngAfterViewInit() {
        SwaggerUI({
            spec: {
                openapi: '3.0.0',
                servers: [
                    { url:  'https://api.general-track.com/v2' }
                ],
                info: {
                    description: 'Explore our APIs. Read our guides to learn how to integrate and get started accessing your data.<br><b>Host</b>: note that General Track API request paths follow this format: <code>api.general-track.com/{API version}/{API path}</code>.<br>The current api version is <code>v2</code>',
                    version: '1.0.0',
                    title: 'General Track API'
                },
                tags: [
                    {
                        name: 'Device API'
                    },
                    {
                        name: 'Synchronization API'
                    }
                ],
                paths: {
                    '/synchronization/sensor': {
                        get: {
                            tags: [
                                'Synchronization API'
                            ],
                            operationId: 'SyncSensor',
                            parameters: [
                                {
                                    name: 'x-signature',
                                    in: 'header',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'x-apikey',
                                    in: 'header',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'from',
                                    in: 'query',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'to',
                                    in: 'query',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'type',
                                    in: 'query',
                                    required: false,
                                    schema: {
                                        type: 'string'
                                    },
                                    description: 'One or more of the following values, separated with comma if multiple given: accelerometer,motion_percent,light,humidity,temperature,energy,volume,impulse'
                                }

                            ],
                            responses: {
                                200: {
                                    description: '200 response',
                                    content: {
                                        'application/json': {
                                            example: [
                                                {
                                                    imei: '350485268556122',
                                                    subdevice: null,
                                                    server_date: '2021-08-11 09:17:34',
                                                    type: 'volume',
                                                    date: '2021-08-11 09:17:04',
                                                    channel: 1,
                                                    channel_session_id: 'CHB00B5007',
                                                    interval: 10,
                                                    dimension: 'm³',
                                                    volume: 1.1
                                                },
                                                {
                                                    imei: '105117939098915',
                                                    subdevice: null,
                                                    server_date: '2021-08-11 09:17:31',
                                                    type: 'impulse',
                                                    date: '2021-08-11 09:19:31',
                                                    channel: 3,
                                                    channel_session_id: 'CH20567007',
                                                    interval: 60,
                                                    dimension: '1',
                                                    impulse: 150
                                                },
                                                {
                                                    imei: '350485268556122',
                                                    subdevice: null,
                                                    server_date: '2021-08-11 09:17:34',
                                                    type: 'energy',
                                                    date: '2021-08-11 09:17:24',
                                                    channel: 2,
                                                    channel_session_id: 'CHB0065402',
                                                    interval: 100,
                                                    dimension: 'kWh',
                                                    energy: 1.2
                                                },
                                                {
                                                    imei: '105117939098915',
                                                    subdevice: '00277A75',
                                                    server_date: '2021-08-11 09:17:31',
                                                    type: 'temperature',
                                                    date: '2021-08-11 09:17:31',
                                                    dimension: '°C',
                                                    temperature: 26.2
                                                }
                                            ]
                                        }
                                    }
                                },
                                400: {
                                    description: '400 response',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: '401 response',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [
                                {
                                    gt_auth_verifySignature: []
                                }
                            ],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_syncSensor:${stageVariables.ApiStage}/invocations',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                },
                                passthroughBehavior: 'when_no_match',
                                httpMethod: 'POST',
                                contentHandling: 'CONVERT_TO_TEXT',
                                type: 'aws_proxy'
                            }
                        }
                    },
                    '/synchronization/events': {
                        get: {
                            tags: [
                                'Synchronization API'
                            ],
                            operationId: 'SyncEvents',
                            parameters: [
                                {
                                    name: 'x-signature',
                                    in: 'header',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'x-apikey',
                                    in: 'header',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'from',
                                    in: 'query',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                },
                                {
                                    name: 'to',
                                    in: 'query',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    }
                                }
                            ],
                            responses: {
                                200: {
                                    description: '200 response',
                                    content: {
                                        'application/json': {
                                            example: [
                                                {imei: '105117939098915', subdeviceId: null, type: 'overconsumption', date: '2021-06-21 06:59:18', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:18', threshold: {measurement_time_window_seconds: 600, unit_limit: 2}, data: {unit_measured: 3.02, channel: 1}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'underconsumption', date: '2021-06-21 06:59:18', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:18', threshold: {measurement_time_window_seconds: 600, unit_limit: 1}, data: {unit_measured: 0.01, channel: 1}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'leak_detection', date: '2021-06-21 06:59:18', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:18', threshold: {testing_time_base_seconds: 10, positive_detection_interval_hours: 1}, data: {channel: 1}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'sabotage', date: '2021-06-21 06:59:18', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:18', data: {channel: 3}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'water_detection', date: '2021-06-21 06:59:18', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:18', data: {channel: 4}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'config_change', date: '2021-06-21 06:59:19', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:19', data: {config_name: 'Logistics tracking', config_version_date: '2021-06-21 05:57:10'}},
                                                {imei: '105117939098915', subdeviceId: null, type: 'reset', date: '2021-06-21 06:59:09', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:19'},
                                                {imei: '105117939098915', subdeviceId: null, type: 'button_push', date: '2021-06-21 06:59:25', server_date: '2021-06-21 06:59:19', device_date: '2021-06-21 06:59:19', data: {signal: '-103dBm'}}]
                                        }
                                    }
                                },
                                400: {
                                    description: '400 response',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: '401 response',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [
                                {
                                    gt_auth_verifySignature: []
                                }
                            ],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_syncEvents:${stageVariables.ApiStage}/invocations',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                },
                                passthroughBehavior: 'when_no_match',
                                httpMethod: 'POST',
                                contentHandling: 'CONVERT_TO_TEXT',
                                type: 'aws_proxy'
                            }
                        }
                    },
                    '/device/list': {
                        get:  {
                            tags: [
                                'Device API'
                            ],
                            operationId: 'GetDevices',
                            description: 'Get all the available devices states for the given API key',
                            parameters: [
                                {
                                    in: 'header',
                                    name: 'x-apikey',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Since the GT Server is multiuser tool with access rights to its data entities a request must be sent with authentication key in HTTP header.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-signature',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'You need to provide a signature to your request which is unique and prove that the request is yours. <p>For more detailes scroll down to the <a href=\'#gettingstarted\'>Getting Started</a> section.</p>'
                                }
                            ],
                            responses: {
                                200: {
                                    description: 'OK',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/DeviceListObject'
                                            }
                                        }
                                    }
                                },
                                400: {
                                    description: 'Bad input parameters or required parameters have not been filled',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: 'Authentication failed, access_token not provided or not valid',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                403: {
                                    description: 'Not authorized to access this resource with an explicit deny',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [{
                                gt_auth_tokenValidator: []
                            }],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                type: 'aws',
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_deviceList/invocations',
                                httpMethod: 'POST',
                                passthroughBehavior: 'when_no_match',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    },
                                    403: {
                                        responseType: 'ACCESS_DENIED',
                                        statusCode: '403',
                                        responseTemplates: {
                                            'application/json': '#set($inputRoot = $input.path(\'$\'))\n{\n    "success" : false,\n    "type":  $context.error.responseType,\n    "resourcePath":  $context.resourcePath,\n    "message" : $context.error.messageString\n}'
                                        }
                                    }
                                }
                            }
                        }
                    },
                    '/device/status/{imei}': {
                        get:  {
                            tags: [
                                'Device API'
                            ],
                            operationId: 'GetStatus',
                            description: 'Get single device information for the given IMEI, or for all devices by using \'all\' instead of an IMEI',
                            parameters: [
                                {
                                    in: 'path',
                                    name: 'imei',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'The IMEI of your device or the \'all\' keyword. You get a single entity if you specify an IMEI or an array of your entities with the \'all\' keyword.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-apikey',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Since the GT Server is multiuser tool with access rights to its data entities a request must be sent with authentication key in HTTP header.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-signature',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'You need to provide a signature to your request which is unique and prove that the request is yours. <p>For more detailes scroll down to the <a href=\'#gettingstarted\'>Getting Started</a> section.</p>'
                                }
                            ],
                            responses: {

                                200: {
                                    description: 'OK',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/DeviceStatusObject',
                                            },
                                            example: [
                                                {
                                                    imei: '863071017921468',
                                                    short_name: 'Example name',
                                                    hw_ver: '1.2',
                                                    sw_ver: '2.0',
                                                    type: 'NaBi',
                                                    last_communication: '2019-05-30 15:00:00',
                                                    gps_date: '2019-05-30 15:10:00',
                                                    gps_lat: 47.460088,
                                                    gps_lng: 19.0449069,
                                                    gps_sat: 9,
                                                    gps_speed: 64,
                                                    battery: 78,
                                                    last_sensor: [
                                                        {
                                                            temperature: {
                                                                date: '2020-02-10 16:10:18',
                                                                dimension: '°C',
                                                                temperature: 25.3
                                                            }
                                                        },
                                                        {
                                                            humidity: {
                                                                date: '2020-02-10 16:10:18',
                                                                dimension: '%',
                                                                humidity: 52
                                                            }
                                                        },
                                                        {
                                                            accelerometer: {
                                                                date: '2020-02-10 16:11:13',
                                                                dimension: 'm/s²',
                                                                x: -0.2,
                                                                y: 0.1,
                                                                z: 9.9
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    }
                                },
                                400: {
                                    description: 'Bad input parameters or required parameters have not been filled',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: 'Authentication failed, access_token not provided or not valid',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [{
                                gt_auth_tokenValidator: []
                            }],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                type: 'aws',
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_deviceStatus/invocations',
                                httpMethod: 'POST',
                                passthroughBehavior: 'when_no_match',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                }
                            }
                        }
                    },
                    /*
                    '/device/track/{imei}': {
                        get:  {
                            tags: [
                                'Device API'
                            ],
                            operationId: 'GetTracks',
                            description: 'Get tracks for the given IMEI and period of time',
                            parameters: [
                                {
                                    name: 'imei',
                                    in: 'path',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    },
                                    description: 'IMEI number of your device.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-apikey',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Since the GT Server is multiuser tool with access rights to its data entities a request must be sent with authentication key in HTTP header.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-signature',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'You need to provide a signature to your request which is unique and prove that the request is yours. <p>For more detailes scroll down to the <a href=\'#gettingstarted\'>Getting Started</a> section.</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'from',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested start date and time in UTC timezone. <p>Example: 2019-05-30 00:00:00</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'to',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested end date and time in UTC timezone. <p>Example: 2019-05-31 00:00:00</p>'
                                }
                            ],
                            responses: {
                                200: {
                                    description: 'OK',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/TrackObjectResponse'
                                            }
                                        }
                                    }
                                },
                                400: {
                                    description: 'Bad input parameters or required parameters have not been filled',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: 'Authentication failed, access_token not provided or not valid',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [{
                                gt_auth_tokenValidator: []
                            }],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                type: 'aws',
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_deviceTrack/invocations',
                                httpMethod: 'POST',
                                passthroughBehavior: 'when_no_match',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                }
                            }
                        }
                    },
                    */

                    '/device/sensor/{imei}': {
                        get:  {
                            tags: [
                                'Device API'
                            ],
                            operationId: 'GetSensor',
                            description: 'Get sensor data for the given IMEI and period of time',
                            parameters: [
                                {
                                    name: 'imei',
                                    in: 'path',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    },
                                    description: 'IMEI number of your device, or subdevice ID or input channel session ID.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-apikey',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Since the GT Server is multiuser tool with access rights to its data entities a request must be sent with authentication key in HTTP header.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-signature',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'You need to provide a signature to your request which is unique and prove that the request is yours. <p>For more detailes scroll down to the <a href=\'#gettingstarted\'>Getting Started</a> section.</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'from',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested start date and time in UTC timezone. <p>Example: 2019-05-30 00:00:00</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'to',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested end date and time in UTC timezone. <p>Example: 2019-05-31 00:00:00</p>'
                                },
                                {
                                    name: 'type',
                                    in: 'query',
                                    required: false,
                                    schema: {
                                        type: 'string'
                                    },
                                    description: 'One or more of the following values, separated with comma if multiple given: accelerometer,motion_percent,light,humidity,temperature,energy,volume,impulse'
                                }
                            ],
                            responses: {
                                200: {
                                    description: 'OK',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SensorDataObject',
                                            },
                                            example: [
                                                {
                                                    "devId":"867997030437528",
                                                    "devType":"NaBi Water",
                                                    "type":"temperature",
                                                    "dimension":"°C",
                                                    "data":[
                                                        {
                                                            "date": "2024-01-23 10:23:58",
                                                            "temperature":15
                                                        },
                                                        {
                                                            "date":"2024-01-23 10:53:58",
                                                            "temperature":13
                                                        }
                                                    ]
                                                },{
                                                    "devId":"5DDD8A80B",
                                                    "devType":"channel",
                                                    "type":"volume",
                                                    "dimension":"m³",
                                                    "data":[
                                                        {
                                                            "date":"2024-01-23 11:00:00",
                                                            "volume_cumulated":56.44
                                                        }
                                                    ]
                                                },{
                                                    "devId":"5DDD8A80D",
                                                    "devType":"channel",
                                                    "type":"volume",
                                                    "dimension":"m³",
                                                    "data":[
                                                        {
                                                            "date":"2024-01-23 11:00:24",
                                                            "volume_cumulated":417.13
                                                        }
                                                    ]
                                                },{
                                                    "devId":"5DDD8A80C",
                                                    "devType":"channel",
                                                    "type":"volume",
                                                    "dimension":"m³",
                                                    "data":[
                                                        {
                                                            "date":"2024-01-23 11:00:38",
                                                            "volume_cumulated":114.84
                                                        }
                                                    ]
                                                }
                                            ]
                                        }
                                    }
                                },
                                400: {
                                    description: 'Bad input parameters or required parameters have not been filled',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: 'Authentication failed, access_token not provided or not valid',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [{
                                gt_auth_tokenValidator: []
                            }],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                type: 'aws',
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_deviceTemperature/invocations',
                                httpMethod: 'POST',
                                passthroughBehavior: 'when_no_match',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                }
                            }
                        }
                    },
                    '/device/events/{imei}': {
                        get:  {
                            tags: [
                                'Device API'
                            ],
                            operationId: 'GetEvents',
                            description: 'Get events for the given IMEI and period of time',
                            parameters: [
                                {
                                    name: 'imei',
                                    in: 'path',
                                    required: true,
                                    schema: {
                                        type: 'string'
                                    },
                                    description: 'IMEI number of your device.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-apikey',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Since the GT Server is multiuser tool with access rights to its data entities a request must be sent with authentication key in HTTP header.'
                                },
                                {
                                    in: 'header',
                                    name: 'x-signature',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'You need to provide a signature to your request which is unique and prove that the request is yours. <p>For more detailes scroll down to the <a href=\'#gettingstarted\'>Getting Started</a> section.</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'from',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested start date and time in UTC timezone. <p>Example: 2019-05-30 00:00:00</p>'
                                },
                                {
                                    in: 'query',
                                    name: 'to',
                                    schema: {
                                        type: 'string'
                                    },
                                    required: true,
                                    description: 'Requested end date and time in UTC timezone. <p>Example: 2019-05-31 00:00:00</p>'
                                }
                            ],
                            responses: {
                                200: {
                                    description: 'OK',
                                    content: {
                                        'application/json': {
                                            example: [
                                                {
                                                    type: 'overconsumption',
                                                    date: '2021-08-11 09:17:32',
                                                    threshold: {
                                                        measurement_time_window_seconds: 600,
                                                        unit_limit: 2.56
                                                    },
                                                    data: {
                                                        unit_measured: 0.02,
                                                        channel: 1
                                                    }
                                                },
                                                {
                                                    type: 'underconsumption',
                                                    date: '2021-08-11 09:17:32',
                                                    threshold: {
                                                        measurement_time_window_seconds: 600,
                                                        unit_limit: 5.12
                                                    },
                                                    data: {
                                                        unit_measured: 0.01,
                                                        channel: 1
                                                    }
                                                },
                                                {
                                                    type: 'leak_detection',
                                                    date: '2021-08-11 09:17:32',
                                                    threshold: {
                                                        testing_time_base_seconds: 10,
                                                        positive_detection_interval_hours: 1
                                                    },
                                                    data: {
                                                        channel: 1
                                                    }
                                                },
                                                {
                                                    type: 'sabotage',
                                                    date: '2021-08-11 09:17:32',
                                                    data: {
                                                        channel: 3
                                                    }
                                                },
                                                {
                                                    type: 'water_detection',
                                                    date: '2021-08-11 09:17:32',
                                                    data: {
                                                        channel: 4
                                                    }
                                                }
                                            ]
                                        }
                                    }
                                },
                                400: {
                                    description: 'Bad input parameters or required parameters have not been filled',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                },
                                401: {
                                    description: 'Authentication failed, access_token not provided or not valid',
                                    content: {
                                        'application/json': {
                                            schema: {
                                                $ref: '#/components/schemas/SimpleErrorResponse'
                                            }
                                        }
                                    }
                                }
                            },
                            security: [{
                                gt_auth_tokenValidator: []
                            }],
                            'x-amazon-apigateway-request-validator': 'all',
                            'x-amazon-apigateway-integration': {
                                type: 'aws',
                                uri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:gt_api_deviceEvents/invocations',
                                httpMethod: 'POST',
                                passthroughBehavior: 'when_no_match',
                                responses: {
                                    default: {
                                        statusCode: '200'
                                    }
                                }
                            }
                        }
                    }
                },

                components: {
                    schemas: {
                        DeviceListObject: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    imei: {
                                        type: 'string',
                                        example: '863071017921468'
                                    },
                                    type: {
                                        type: 'string',
                                        example: 'NaBi'
                                    },
                                    install_periods: {
                                        type: 'object',
                                        properties: {
                                            period_start: {
                                                type: 'string',
                                                example: '2018-04-22 13:23:38'
                                            },
                                            period_end: {
                                                type: 'string',
                                                example: '2019-05-14 17:21:30'
                                            },
                                            install_reference: {
                                                type: 'string',
                                                example: 'null'
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        DeviceObject: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    imei: {
                                        type: 'string',
                                        example: '863071017921468'
                                    },
                                    short_name: {
                                        type: 'string',
                                        example: 'Example name'
                                    },
                                    hw_ver: {
                                        type: 'string',
                                        example: '1.2'
                                    },
                                    sw_ver: {
                                        type: 'string',
                                        example: '2.0'
                                    },
                                    type: {
                                        type: 'string',
                                        example: 'NaBi'
                                    },
                                    last_communication: {
                                        type: 'string',
                                        example: '2019-05-30T15:00:00.000Z'
                                    },
                                    gps_date: {
                                        type: 'string',
                                        example: '2019-05-30T15:10:00.000Z'
                                    },
                                    gps_lat: {
                                        type: 'number',
                                        example: 47.460088
                                    },
                                    gps_lng: {
                                        type: 'number',
                                        example: 19.0449069
                                    },
                                    gps_sat: {
                                        type: 'number',
                                        example: 9
                                    },
                                    gps_speed: {
                                        type: 'number',
                                        example: 64
                                    }
                                }
                            }
                        },
                        TemperatureSensorDataObject: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    type: {
                                        type: 'string',
                                        example: 'Temperature'
                                    },
                                    date: {
                                        type: 'string',
                                        example: '2019-05-30T15:00:00.000Z'
                                    },
                                    value: {
                                        type: 'number',
                                        example: 21
                                    },
                                }
                            }
                        },
                        SensorDataObject: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    devId: {
                                        type: 'string',
                                        example: '867997030437528'
                                    },
                                    devType: {
                                        type: 'string',
                                        example: 'NaBi Solo'
                                    },
                                    devName: {
                                        type: 'string',
                                        example: ''
                                    },
                                    type: {
                                        type: 'string',
                                        example: 'temperature'
                                    },
                                    dimension: {
                                        type: 'string',
                                        example: '°C'
                                    },
                                    data: {
                                        type: 'array',
                                        items: {
                                            type: 'object',
                                            properties: {
                                                date: {
                                                    type: 'string',
                                                    example: '2019-05-30T15:00:00.000Z'
                                                },
                                                temperature: {
                                                    type: 'number',
                                                    example: 21
                                                },
                                            }
                                        }
                                    }
                                }
                            }
                        },
                        EventDataObject: {
                            type: 'array',
                            items: {
                                type: 'object',
                                properties: {
                                    type: {
                                        type: 'string',
                                        example: 'temp_low'
                                    },
                                    date: {
                                        type: 'string',
                                        example: '2019-05-30T15:00:00.000Z'
                                    },
                                    threshold: {
                                        type: 'number',
                                        example: 15
                                    },
                                    value: {
                                        type: 'string',
                                        example: '12'
                                    }
                                }
                            }
                        },
                        DeviceStatusObject: {
                            type: 'object',
                            properties: {
                                imei: {
                                    type: 'string',
                                    example: '863071017921468'
                                },
                                short_name: {
                                    type: 'string',
                                    example: 'Example name'
                                },
                                hw_ver: {
                                    type: 'string',
                                    example: '1.2'
                                },
                                sw_ver: {
                                    type: 'string',
                                    example: '2.0'
                                },
                                type: {
                                    type: 'string',
                                    example: 'NaBi'
                                },
                                last_communication: {
                                    type: 'string',
                                    example: '2019-05-30T15:00:00.000Z'
                                },
                                gps_date: {
                                    type: 'string',
                                    example: '2019-05-30T15:10:00.000Z'
                                },
                                gps_lat: {
                                    type: 'number',
                                    example: 47.460088
                                },
                                gps_lng: {
                                    type: 'number',
                                    example: 19.0449069
                                },
                                gps_sat: {
                                    type: 'number',
                                    example: 9
                                },
                                gps_speed: {
                                    type: 'number',
                                    example: 64
                                },
                                battery: {
                                    type: 'number',
                                    example: 78
                                },
                                last_sensor: {
                                    type: 'array',
                                    items: {
                                        type: 'object',
                                        properties: {
                                            date: {
                                                type: 'string',
                                                example: '2019-05-30 15:00:00'
                                            },
                                            dimension: {
                                                type: 'string',
                                                example: '°C'
                                            },
                                            temperature: {
                                                type: 'number',
                                                example: 21
                                            },
                                        }
                                    }
                                }
                            }
                        },
                        SimpleErrorResponse: {
                            type: 'object',
                            properties: {
                                success: {
                                    type: 'boolean',
                                    example: false
                                },
                                type: {
                                    type: 'string'
                                },
                                resourcePath: {
                                    type: 'string'
                                },
                                message: {
                                    type: 'string'
                                }
                            }
                        }
                    },
                    securitySchemes: {
                        gt_auth_tokenValidator: {
                            type: 'apiKey',
                            name: 'Authorization',
                            in: 'header',
                            'x-amazon-apigateway-authtype': 'oauth2',
                            'x-amazon-apigateway-authorizer': {
                                type: 'token',
                                authorizerUri: 'arn:aws:apigateway:eu-central-1:lambda:path/2015-03-31/functions/arn:aws:lambda:eu-central-1:677423536377:function:ValidateJWTToken/invocations',
                                authorizerResultTtlInSeconds: 60
                            }
                        }
                    }
                },
                'x-amazon-apigateway-request-validators': {
                    all: {
                        validateRequestBody: true,
                        validateRequestParameters: true
                    },
                    'params-only': {
                        validateRequestBody: false,
                        validateRequestParameters: true
                    }
                },
                'x-amazon-apigateway-gateway-responses': {
                    DEFAULT_4XX: {
                        statusCode: 400,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    DEFAULT_5XX: {
                        statusCode: 500,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    ACCESS_DENIED: {
                        statusCode: 403,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    BAD_REQUEST_BODY: {
                        statusCode: 400,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    EXPIRED_TOKEN: {
                        statusCode: 403,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    INVALID_API_KEY: {
                        statusCode: 403,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    INVALID_SIGNATURE: {
                        statusCode: 403,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    MISSING_AUTHENTICATION_TOKEN: {
                        statusCode: 403,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    RESOURCE_NOT_FOUND: {
                        statusCode: 404,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    UNAUTHORIZED: {
                        statusCode: 401,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    },
                    UNSUPPORTED_MEDIA_TYPE: {
                        statusCode: 415,
                        responseTemplates: {
                            'application/json': '{"success" : false,\n"type":  "$context.error.responseType",\n"resourcePath":  "$context.resourcePath",\n"message" : $context.error.messageString}'
                        }
                    }
                }
            },
            domNode: document.getElementById('swagger-ui'),
            onComplete: () => {
                if (this.fragment) {
                    setTimeout(() => this.viewPortScroller.scrollToAnchor(this.fragment as string), 200);
                }
            }
        });
    }

    public signatureCode = {
        php: `if ($signature = OpenSSL::Instance()->privateKey($keyPath)->sign($data)) {
    echo $signature;
}`,
        nodejs: `OpenSSL.privateKey(keyPath).sign(data).then((signature) => {
    console.log(signature);
});`,
        java: `try {
    String sign = OpenSSL.INSTANCE.privateKey(keyPath).sign(data);
    System.out.println(sign);
} catch (Exception e) {
    e.printStackTrace();
}`,
    }

    public apiRequest = `require_once "./OpenSSL.php";

$baseURL = "https://api.general-track.com";
$keyPath = "file://private.pem";
$endPoint = str_replace(' ', '%20', '/v2/device/list');
$apiKey = '-apikey-';

if($signature = OpenSSL::Instance()->privateKey($keyPath)->sign($endPoint)) {
$ch = curl_init();

$headers = [
    'x-apikey: '.$apiKey,
    'x-signature: '.$signature
];

curl_setopt($ch, CURLOPT_URL, $baseURL.$endPoint);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$response = json_decode(curl_exec($ch));
curl_close($ch);

var_dump($response);
}
`
}
