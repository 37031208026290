<section class="nabi-different" >
    <div class="space adaptive small"></div>

    <div class="top">
        <div class="container">
            <h2 class="nabi-bold" [innerHTML]="'CONSUMER_HOW_IS_NABI_DIFFERENT_HEADER' | translate"></h2>
            <p [innerHTML]="'CONSUMER_HOW_IS_NABI_DIFFERENT_DESC' | translate"></p>
        </div>
        <div class="image-container">
            @if (windowWidth >= 768) {
                <img src="{{meetOurDevicesImg}}" alt="nabi_infos"/>
            } @else {
                <img src="{{meetOurDevicesMobileImg}}" alt="nabi_infos"/>
            }
        </div>
    </div>
    <div class="space adaptive large"></div>
    <div class="bottom" id="technology">
        <div class="space adaptive small"></div>

        <div class="container">
            <h2>{{'CONSUMER_HOW_IS_NABI_YEAR_HEADER' | translate}}</h2>
            <p [innerHTML]="'CONSUMER_HOW_IS_NABI_YEAR_DESC' | translate"> </p>
            <p [innerHTML]="'CONSUMER_HOW_IS_NABI_YEAR_DESC_2' | translate"> </p>

            @if (windowWidth > 768) {
                <img class="p-5" src="{{howitworksImg}}"/>
            } @else {
                <img class="p-5" src="{{howitworksMobileImg}}"/>
            }
        </div>

        <div class="space adaptive large"></div>
    </div>

    <div class="video">
        <div class="container">
            <div>
                <app-videojs
                    [id]="'promo-video'"
                    [language]="language"
                    [options]='videojsOptions'>
                </app-videojs>
            </div>
        </div>
    </div>
</section>
