import {CommonModule, ViewportScroller} from '@angular/common';
import { Component, HostListener } from '@angular/core';
import {NavigationEnd, Router, RouterModule} from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import {TranslateModule} from "@ngx-translate/core";
@Component({
    standalone: true,
    selector: 'page-footer',
    templateUrl: 'page-footer.component.html',
    styleUrls: ['page-footer.component.scss'],
    imports: [CommonModule, RouterModule, FontAwesomeModule, TranslateModule],
})
export class PageFooterComponent {
    isContactActive: boolean = false;
    faAngleRight = faAngleRight;
    windowWidth = Math.min(window.outerWidth, window.innerWidth);

    constructor(private router: Router, private viewportScroller: ViewportScroller) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if(event.url === '/contact') {
                    this.isContactActive = true;
                }else{
                    this.isContactActive = false;
                }
            }
        })
    }

    @HostListener('window:resize', []) onResize() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }

    @HostListener('window:orientationchange', []) onOrientationChange() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }

    scrollToProducts() {
        this.router.navigate(['/home'], { fragment: 'ourproducts' });
    }
}
