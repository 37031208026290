<div
    (click)="onClick()"
    class="d-flex justify-content-between align-items-center"
    [class.in-selection]="isSelectionActive"
>
    @if (control?.value != null) {
    <p>{{ control?.value.name }}</p>
    <fa-icon [icon]="icons.angelDown" [class.active]="isSelectionActive">
    </fa-icon>
    } @else {
    <p>{{ defaultText }}</p>
    <fa-icon [icon]="icons.angelDown" [class.active]="isSelectionActive">
    </fa-icon>
    }
</div>
@if(itemList && isSelectionActive) {
<div [@slideVertically] class="items">
    @for(item of itemList; track item) {
    <div (click)="select(item)">
        <p>{{ item.name }}</p>
    </div>
    }
</div>
}
