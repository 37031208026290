<section class="Platform">
    <div class="Pane-backgroundContainer">
        <div class="Pane-background"></div>
    </div>

    <div class="Pane-content">
        <div class="space adaptive large"></div>

        <div class="sticky-section">
            <div class="left-block">
                <h1 [style.--image-height]="mockUpImageElement.offsetHeight + 'px'" [innerHTML]="'OUR_BEST_PLATFORM' | translate"></h1>
                <div class="vertical-blocks">
                    <div class="item-block" sticky [scrolled]="scrollY" (onStateChanged)="setCurrentMockup($event, 1)" [offset]="50">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                            <path fill="#F49801" d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/>
                        </svg>
                        <div>{{ "ONE_PLATFORM_FOR_ALL_YOUR_EQUIPMENTS" | translate }}</div>
                        <img src="assets/construction/platform/1_platform_devices.webp" alt="Platform devices"/>
                    </div>
                    <div class="item-block" sticky [scrolled]="scrollY" (onStateChanged)="setCurrentMockup($event, 2)" [offset]="50">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.19 40.78">
                            <path fill="#F49801" d="M39.47,1.69c-.47,.42-2.33,1.95-3.98,3.56-.38,.34-.85,.85-1.02,1.61-.08,.47-.04,.98,0,1.4,.04,.21,.08,.47,.25,1.27,.3,1.19,.42,1.78,.64,1.99s.34,.17,1.65,.51c1.57,.42,1.57,.47,2.03,.51,.34,.04,1.14,.08,2.03-.25,.72-.3,1.14-.72,2.03-1.78,.68-.81,1.48-1.78,2.37-2.93,1.99,1.95,1.99,3.94,1.23,6.02-1.87,4.96-7.42,8.1-12.51,5.89-.89-.38-1.61-.21-2.25,.47-6.02,6.36-12.04,12.72-18.02,19.12-1.87,1.99-4.07,2.2-5.98,.47-1.82-1.7-1.78-4.28,.08-6.1,6.27-5.98,12.55-12,18.86-17.89,.98-.89,1.06-1.7,.64-2.88-1.78-5.17,1.14-10.68,6.36-12.25,1.95-.68,3.82-.47,5.55,1.27ZM10.98,37.51c.98-.04,1.57-.51,1.57-1.44-.04-.89-.59-1.44-1.57-1.44-.89,0-1.4,.47-1.44,1.36-.04,.98,.47,1.44,1.44,1.53Z"/>
                            <path fill="#F49801" d="M23.7,16.06c-1.53,1.44-3.09,2.93-4.66,4.41-.72-.76-1.87-1.19-1.06-2.5,.08-.13-.17-.55-.34-.72-1.19-1.06-2.33-2.16-3.65-3.09-1.14-.85-2.5-.93-3.82-.34-1.53,.68-1.78,2.03-.59,3.14,.85,.81,.98,1.44,.04,2.25-.72,.59-1.31,1.27-1.91,1.99-.59,.68-1.02,.81-1.74,.08-1.91-1.99-3.82-3.94-5.98-6.06,1.27-1.23,2.37-2.33,3.56-3.35,.21-.21,.76-.08,1.14,0,1.4,.3,2.2-.21,2.2-1.61-.04-1.4,.64-2.25,1.53-3.18C11.4,3.94,14.84,1.52,19.03,.42c1.78-.51,3.65-.64,5.43,.08,.68,.3,1.27,.72,2.16,1.23-1.57,.21-2.8,.25-3.98,.59-2.59,.68-6.19,4.62-4.92,8.14,.55,1.57,1.91,2.88,2.93,4.24,.08,.13,.38,.21,.55,.21q1.36-.13,2.5,1.14Z"/>
                            <path fill="#F49801" d="M38.15,40.78c-3.82-4.28-7.59-8.48-11.45-12.76,1.44-1.57,2.93-3.09,4.45-4.71,1.14,1.02,2.2,2.03,3.31,3.01,2.84,2.5,5.64,5,8.48,7.46,1.27,1.1,1.31,1.1,.17,2.25-1.31,1.31-2.59,2.63-3.9,3.9-.38,.3-.68,.55-1.06,.85Z"/>
                        </svg>
                        <div>{{ "AVOIDING_ACCIDENT_WITH_REGULAR_MAINTENANCE" | translate }}</div>
                        <img src="assets/construction/platform/2_platform_maintenance.webp" alt="Platform devices"/>
                    </div>
                    <div class="item-block" sticky [scrolled]="scrollY" (onStateChanged)="setCurrentMockup($event, 3)" [offset]="50">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.9 64.2">
                            <line stroke="#F49801" stroke-miterlimit="10" stroke-width="3" x1="5.97" y1="7.64" x2="42.83" y2="7.64"/>
                            <g>
                                <path fill="#F49801" d="M43.58,4.5c.45,0,.82,.37,.82,.83s-.37,.83-.82,.83h-3v4.31c0,4.24-1.72,8.4-4.73,11.4l-8.1,8.12-2.12,2.12,2.12,2.12,8.11,8.12c3,3,4.73,7.16,4.73,11.4v4.31h3c.46,0,.82,.36,.82,.82s-.36,.83-.82,.83H5.33c-.45,0-.82-.37-.82-.83s.37-.82,.82-.82h3v-4.31c0-4.24,1.72-8.4,4.72-11.4l8.11-8.12,2.12-2.12-2.12-2.12-8.11-8.12c-3.05-3.05-4.72-7.09-4.72-11.4V6.15h-3c-.45,0-.82-.37-.82-.83s.37-.83,.82-.83H43.58m0-3H5.33c-2.11,0-3.82,1.71-3.82,3.83s1.71,3.83,3.82,3.83v1.31c0,5.07,2.02,9.94,5.6,13.52l8.11,8.12-8.11,8.12c-3.59,3.59-5.6,8.45-5.6,13.52v1.31c-2.11,0-3.82,1.71-3.82,3.82s1.71,3.83,3.82,3.83H43.58c2.12,0,3.82-1.71,3.82-3.83s-1.71-3.82-3.82-3.82v-1.31c0-5.07-2.02-9.93-5.61-13.52l-8.1-8.12,8.1-8.12c3.59-3.59,5.61-8.45,5.61-13.52v-1.31c2.12,0,3.82-1.71,3.82-3.83s-1.71-3.83-3.82-3.83h0Z"/>
                                <path fill="#F49801" d="M9.82,53.74c0-2.86,.87-5.68,2.43-8.06l24.35-.05c1.59,2.39,2.47,5.23,2.47,8.11m-3-34.41c-.39,.52-.83,1.01-1.29,1.48l-10.34,10.35-11.67-11.83"/>
                                <line stroke="#F49801" stroke-miterlimit="10" stroke-width="3" x1="5.89" y1="56.55" x2="42.75" y2="56.55"/>
                            </g>
                        </svg>
                        <div>{{ "REDUCING_EFFICIENCY_LOSS_WITH_DUTY_HOUR_MONITORING" | translate }}</div>
                        <img src="assets/construction/platform/3_platform_hourmeter.webp" alt="Platform devices"/>
                    </div>
                    <div class="item-block" sticky [scrolled]="scrollY" (onStateChanged)="setCurrentMockup($event, 4)" [offset]="50 + (windowHeight * 0.13)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130 130" xml:space="preserve">
                            <rect fill="#FFFFFF" x="54.4" y="50.3" class="st0" width="24.2" height="27.6"/>
                            <path fill="#F49801" d="M14,102.5L0.3,81.7L10.9,65c-1-1.7-2-3.3-3-5c-2.1-3.4-4.2-7-6.4-10.4l-1-1.7l14.1-20.3l4.9,3.4L7.6,48.1C9.4,51,11.2,54,13,56.9c1.3,2.2,2.6,4.4,4,6.5l1,1.6L7.5,81.7L19,99.1L14,102.5z"/>
                            <path fill="#F49801" d="M31.8,89.3l-9.9-13.4l6.4-11.6c-1-1.5-2-3-3.1-4.5c-1.2-1.8-2.5-3.6-3.7-5.5l-0.7-1.1l8.7-14.1l3.4,2.1L25.6,53c1,1.5,2,2.9,3,4.4c1.3,1.8,2.5,3.7,3.8,5.5l0.7,1l-6.4,11.6L35,86.9L31.8,89.3z"/>
                            <path fill="#F49801" d="M115.1,101.4l-4.9-3.4l11.8-17c-1.4-2.3-2.7-4.5-4.1-6.8c-1.7-2.8-3.5-5.7-5.2-8.6l-0.9-1.6l10.4-16.6L110.6,30l5-3.3l13.7,20.7l-10.5,16.8c1.4,2.3,2.8,4.6,4.2,6.9c1.7,2.8,3.4,5.6,5.1,8.5l1,1.7L115.1,101.4z"/>
                            <path fill="#F49801" d="M100.1,90.1l-3.4-2.1l7.3-11.9c-1-1.5-2-2.9-3-4.4c-1.3-1.8-2.5-3.7-3.8-5.5l-0.7-1l6.4-11.6l-8.3-11.4l3.2-2.4l9.9,13.4L101.2,65c1,1.5,2,3,3.1,4.5c1.2,1.8,2.5,3.6,3.7,5.5l0.7,1.1L100.1,90.1z"/>
                            <path fill="#F49801" d="M64.8,37.3C50,37.3,38,49.3,38,64.1s12,26.8,26.8,26.8s26.8-12,26.8-26.8S79.6,37.3,64.8,37.3z M77.3,66.7l-19,10.9c-1.7,0.9-3.9-0.3-3.9-2.3V52.8c0-2,2.1-3.2,3.9-2.3l19,11.5C79,63.1,79,65.7,77.3,66.7z"/>
                        </svg>
                        <div>{{ "MONITOR_LOCATION_OF_ALL_EQUIPMENT_AND_PREVENT_THEFT" | translate }}</div>
                        <img src="assets/construction/platform/4_platform_motionevents.webp" alt="Platform devices"/>
                    </div>
                </div>
            </div>

            <div class="right-block">
                <img [src]="mockupImage" alt="Platform devices" #mockUpImageElement>
            </div>
        </div>

        <div class="space adaptive large"></div>
    </div>
</section>
