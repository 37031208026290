import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {TrackAnythingComponent} from "./segments/track-anything/track-anything.component";
import {NabiDifferentSegmentComponent} from "./segments/nabi-different-segment/nabi-different-segment.component";
import {Router} from "@angular/router";
import {GeneralMobileAppComponent} from "../../components/general-mobile-app/general-mobile-app.component";
import {UsageOfNabiComponent} from "./segments/usage-of-nabi/usage-of-nabi.component";
import {PricesComponent} from "./segments/prices/prices.component";
import {ProfessionalCooperationComponent} from "./segments/professional-cooperation/professional-cooperation.component";

@Component({
  selector: 'app-consumer-page',
  standalone: true,
    imports: [CommonModule, TrackAnythingComponent, NabiDifferentSegmentComponent, GeneralMobileAppComponent, UsageOfNabiComponent, PricesComponent, ProfessionalCooperationComponent],
  templateUrl: './consumer-page.component.html',
  styleUrl: './consumer-page.component.scss'
})
export class ConsumerPageComponent {
    constructor(private router: Router) {
    }
}
