import {Component, HostBinding, HostListener, OnInit} from '@angular/core';
import {NavigationEnd, Router, RouterOutlet} from '@angular/router';
import { PageHeaderComponent} from "../components/header-comp/page-header.component";
import { PageFooterComponent} from "../components/footer-comp/page-footer.component";
import { ViewportScroller } from "@angular/common";
import {SharedService} from "../services/shared.service";

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, PageHeaderComponent, PageFooterComponent],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
    contactClass: string = 'general-track';

    constructor(private router: Router, viewport: ViewportScroller, private sharedService: SharedService) {
        viewport.setOffset([0, 115]);
    }

    ngOnInit() {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.sharedService.onDocumentationPage = event.url.includes('documentation');
                this.sharedService.activeAnchor = event.url.split('#')[1];
                if (event.url.startsWith('/home')) {
                    this.contactClass = 'general-track';
                } else if (event.url.startsWith('/logistics')) {
                    this.contactClass = 'logistics-nabi';
                } else if (event.url.startsWith('/construction')) {
                    this.contactClass = 'construction-nabi';
                } else if (event.url.startsWith('/smart-metering')) {
                    this.contactClass = 'smart-metering-nabi';
                } else if (event.url.startsWith('/consumer')) {
                    this.contactClass = 'consumer-nabi';
                } else {
                    this.contactClass = 'general-track';
                }
            }
        });

        window.addEventListener('scroll', this.onScroll.bind(this));
    }

    @HostBinding('class') get getClass() {
        return this.contactClass
    }

    @HostListener('window:scroll', []) onScroll(): void {
        const segments = document.querySelectorAll('section > *[id]');
        let activeAnchor = '';

        // segments.forEach(segment => {
        //     const rect = segment.getBoundingClientRect();
        //     const viewportHeight = window.innerHeight;
        //     const segmentMiddle = rect.top <= window.scrollY + viewportHeight / 2;
        //
        //     console.log({top: rect.top})
        //     console.log({viewportHeight})
        //     console.log({segmentMiddle})
        //
        //     if (segmentMiddle) {
        //         activeAnchor = segment.id || '';
        //     }
        // });

        // @ts-ignore
        for (const segment of segments) {
            const rect = segment.getBoundingClientRect();
            const isVisible = rect.top <= window.innerHeight / 2 && rect.bottom > 500;

            if (isVisible) {
                activeAnchor = segment.id || '';
                break; // Stop after finding the first visible segment
            }
        }

        if (activeAnchor !== this.sharedService.activeAnchor) {
            this.sharedService.activeAnchor = activeAnchor || '';
            console.log('Active anchor updated:', activeAnchor);
        } else {
            console.log('No change in active anchor:', activeAnchor);
        }
    }
}
