import { CommonModule } from '@angular/common';
import {Component, HostListener, Injectable, NgZone, OnInit} from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faLayerGroup,
    faScrewdriverWrench,
    faClock,
    faLocationDot,
    faCircleInfo, IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import {TranslateModule} from "@ngx-translate/core";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {BehaviorSubject, fromEvent, map, throttleTime} from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ScrollService {
    private elementPositionSubject = new BehaviorSubject<number>(0);
    elementPosition$ = this.elementPositionSubject.asObservable();

    constructor(private ngZone: NgZone) {}

    listenToElementPosition(elementId: string) {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll')
                .pipe(
                    throttleTime(10),
                    map(() => document.getElementById(elementId)?.getBoundingClientRect().top || 0)
                )
                .subscribe(pos => {
                    this.ngZone.run(() => {
                        this.elementPositionSubject.next(pos);
                    });
                });
        });
    }
}


@Component({
    selector: 'ourplatform-comp',
    standalone: true,
    imports: [CommonModule, FontAwesomeModule, TranslateModule],
    templateUrl: './ourplatform-comp.component.html',
    styleUrl: './ourplatform-comp.component.scss',
    animations: [trigger('fadeInOutAnimation', [
        state('in', style({opacity: 1, transform: 'translateY(0)', transition: 'all 0.4s ease'})),
        state('out', style({opacity: 0 , transform: 'translateY(10%)',transition: 'all 0.4s ease'})),
        transition(':enter', [
            style({opacity: 0}),
            animate('0.4s ease', style({opacity: 1, transform: 'translateY(0)'}))
        ]),
        transition(':leave', [
            animate('0.4s ease', style({opacity: 0, transform: 'translateY(10%)'}))
        ])
    ])]
})
export class OurplatformPageComponent implements OnInit {
    showAnimation: string = 'out';
    openInformation: boolean = false;
    faClock: IconDefinition = faClock;
    faLocationDot: IconDefinition = faLocationDot;
    faLayerGroup: IconDefinition = faLayerGroup;
    faCircleInfo: IconDefinition = faCircleInfo;
    faScrewdriverWrench: IconDefinition = faScrewdriverWrench;

    constructor(private ngZone: NgZone, private scrollService: ScrollService) {
    }

    ngOnInit() {
        this.scrollService.listenToElementPosition('ourplatform-anim');
        this.scrollService.elementPosition$.subscribe((pos: number) => {
            const isMobile = window.innerWidth <= 900;
            if ((isMobile && pos <= 900 && pos > 0) || (!isMobile && pos <= 900 && pos > 0)) {
                this.showAnimation = 'in';
            }
        });
    }

     changeOpenInformation() {
        this.openInformation = !this.openInformation;
    }

    @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
        const informationElement = document.getElementById('information');
        const langButton = document.getElementById('lang-button');
        if (this.openInformation && informationElement && !informationElement.contains(event.target as Node) && !langButton?.contains(event.target as Node)) {
            this.openInformation = false;
        }
    }
}
