<div class="Page-container animated-background" [@waveAnimation]="waveState">
    <div class="space adaptive small"></div>
    <div class="Page-content container">
        <section class="Pane Pane-title">
            <div class="Pane-content flex-column">
                <div class="row justify-content-center">
                    <div
                        class="col-12 col-md-8 d-flex flex-row gap-5 align-items-center justify-content-center"
                    >
                        <h1
                            class="text-nowrap"
                            (click)="startAnimation('construction-nabi')"
                        >
                            construction
                        </h1>
                        <h1
                            class="text-nowrap"
                            (click)="startAnimation('logistics-nabi')"
                        >
                            logistics
                        </h1>
                        <h1
                            class="text-nowrap"
                            (click)="startAnimation('smart-metering-nabi')"
                        >
                            smart
                        </h1>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="space adaptive small"></div>
</div>
