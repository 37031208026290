<div class="usagenabi-div">
    <section class="container">
        <div class="space adaptive small"></div>

        <h2 class="nabi-bold text-center" [innerHTML]="'USAGE_OF_NABI_HEADER' | translate"></h2>

        <div class="space adaptive small"></div>

        <div class="d-flex flex-column gap-5 gap-md-4">
            <div class="row ">
                <div class="col-12 col-xl-5 m-0 p-0 d-flex justify-content-center align-items-center">
                    <img src="assets/consumer/Pics.webp" alt="motors" class="img-fluid" />
                </div>
                <div class="col-12 col-xl-7">
                    <h5>{{ "USAGE_1_HEADER" | translate }}</h5>
                   <div class="card-text">
                        <p [innerHTML]='"USAGE_1_DESCRIPTION_1" | translate'></p>
                        <p [innerHTML]='"USAGE_1_DESCRIPTION_2" | translate'></p>
                    </div>
                </div>
            </div>

            <div class="row flex-md-row-reverse">
                <div class="col-12 col-xl-5 m-0 p-0 d-flex justify-content-center align-items-center">
                    <img src="assets/consumer/car.webp" alt="car" class="img-fluid" />
                </div>
                <div class="col-12 col-xl-7">
                    <h5>{{ "USAGE_2_HEADER" | translate }}</h5>
                    <div class="card-text">
                        <p [innerHTML]='"USAGE_2_DESCRIPTION_1" | translate'></p>
                        <p [innerHTML]='"USAGE_2_DESCRIPTION_2" | translate'></p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-5 m-0 p-0 d-flex justify-content-center align-items-center">
                    <img src="assets/consumer/kido.webp" alt="kido" class="img-fluid" />
                </div>
                <div class="col-12 col-xl-7">
                    <h5>{{ "USAGE_3_HEADER" | translate }}</h5>
                    <div class="card-text">
                        <p [innerHTML]='"USAGE_3_DESCRIPTION_1" | translate'></p>
                        <p [innerHTML]='"USAGE_3_DESCRIPTION_2" | translate'></p>
                    </div>
                </div>
            </div>

            <div class="row flex-md-row-reverse">
                <div class="col-12 col-xl-5 m-0 p-0 d-flex justify-content-center align-items-center">
                    <img src="assets/consumer/boat.webp" alt="boat" class="img-fluid" />
                </div>
                <div class="col-12 col-xl-7">
                    <h5>{{ "USAGE_4_HEADER" | translate }}</h5>
                    <div class="card-text">
                        <p [innerHTML]='"USAGE_4_DESCRIPTION_1" | translate'></p>
                        <p [innerHTML]='"USAGE_4_DESCRIPTION_2" | translate'></p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-xl-5 m-0 p-0 d-flex justify-content-center align-items-center">
                    <img src="assets/consumer/package.webp" alt="package" class="img-fluid" />
                </div>
                <div class="col-12 col-xl-7">
                    <h5>{{ "USAGE_5_HEADER" | translate }}</h5>
                    <div class="card-text">
                        <p [innerHTML]='"USAGE_5_DESCRIPTION_1" | translate'></p>
                        <p [innerHTML]='"USAGE_5_DESCRIPTION_2" | translate'></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="space adaptive large"></div>
    </section>
</div>
