import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

interface Categories {
    name: string,
    icon: string,
}

@Component({
  selector: 'track-anything-segment',
  standalone: true,
    imports: [ CommonModule, FaIconComponent, TranslateModule ],
  templateUrl: './track-anything.component.html',
  styleUrl: './track-anything.component.scss'
})
export class TrackAnythingComponent {
    categories: Categories[];

    constructor(private translate: TranslateService) {
        this.categories = [
            { name: "CONSUMER_CATEGORY_1", icon: "assets/icons/Group 439.svg" },
            { name: "CONSUMER_CATEGORY_2", icon: "assets/icons/Group 440.svg" },
            { name: "CONSUMER_CATEGORY_3", icon: "assets/icons/Path 1287.svg" },
            { name: "CONSUMER_CATEGORY_4", icon: "assets/icons/Group 441.svg" },
            { name: "CONSUMER_CATEGORY_5", icon: "assets/icons/bag.svg" }
        ]
    }
}
