<section class="top-section">
    <div class="top-bg"></div>

    <div class="container z-2">
        <div class="flex-container">
            <div>
                <div class="space adaptive medium x2"></div>

                <div class="row justify-content-center">
                    <div class="col-12 reddot-imgs">
                        <img src="assets/construction/NaBi_Solo_render.1_trimmed.webp">
                        <img src="assets/consumer/NaBi_Reddot.webp">
                    </div>
                </div>

                <div class="space adaptive small"></div>

                <div class="row">
                    <div class="col-12 consumer-header-h1">
                    <span>
                        <img src="assets/consumer/nabi_horizontal_WHITE.png">
                        - {{ "CONSUMER_HEADER_1" | translate }}
                    </span>
                    <span>
                        {{ "CONSUMER_HEADER_DESC_1" | translate }} <br/> {{ "CONSUMER_HEADER_DESC_2" | translate }}!
                    </span>
                    </div>
                </div>

                <div class="row desc-div">
                    <div class="col-10">
                        <p>{{ "CONSUMER_HEADER_DESC_3" | translate }}</p>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center gap-md-5 gap-4 categories-div">
                @for (category of categories; let i =  $index; track category) {
                    <div class="col-3 col-md-1 category-div">
                        <img class="category-icon" src="{{ category.icon }}"/>
                        <span class="category-name">{{ category.name  | translate }}</span>
                    </div>
                }
            </div>
        </div>
    </div>

</section>

