import {ChangeDetectorRef, Directive, ElementRef, HostListener, Input, OnChanges} from '@angular/core';

@Directive({
    selector: '[loading]',
    standalone: true
})
export class LoadingDirective implements OnChanges {
    @Input('loading') isActive: boolean = true;
    @Input('originalText') originalText: string = '';
    @Input('loadingText') loadingText?: string;
    @Input('isDark') isDark?: boolean;

    private spinnerHTML = `
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
            <div class="spinner-blade"></div>
        </div>`;
    private loader = '';

    constructor(private el: ElementRef, private cdr: ChangeDetectorRef) {}

    @HostListener('change') ngOnChanges() {
        if (this.isActive) {
            if (this.isDark) {
                this.loader = `<div class="spinner center">` + this.spinnerHTML;
            } else {
                this.loader = `<div class="spinner white center">` + this.spinnerHTML;
            }

            this.el.nativeElement.style.opacity = '0.6';
            this.el.nativeElement.innerHTML = (this.loadingText ? this.loader + this.loadingText : this.loader);
        } else {
            this.el.nativeElement.style.opacity = '1';
            this.el.nativeElement.innerHTML = this.originalText;
        }

        this.cdr.detectChanges();
    }
}
