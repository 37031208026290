<div class="Page-container">
    <div class="Page-content">

        <section class="Pane Pane-title">
            <div class="Pane-content flex-column">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <h1>Documentation</h1>
                            <p>
                                Explore the detailed technical specifications to learn how to integrate <b class="nabitext">NaBi</b> into
                                your application
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="segments">
            <section class="Pane Pane-tiles">
                <div class="Pane-content flex-column">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <a routerLink="/construction/documentation">
                                    <div class="d-flex justify-content-between h-100 construction-doc">
                                        <h2>{{"CONSTRUCTION" | translate}} <br/> {{ "DOCUMENTATIONS" | translate }}</h2>
                                        <img src="assets/documentation/c-1.svg" alt="Device Datasheet">
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 col-md-6">
                                <a routerLink="/logistics/documentation">
                                    <div class="d-flex justify-content-between h-100 logistics-doc">
                                        <h2>{{"LOGISTICS" | translate}}  <br/> {{ "DOCUMENTATIONS" | translate }}</h2>
                                        <img src="assets/documentation/c-1.svg" alt="Device Datasheet">
                                    </div>
                                </a>
                            </div>
                            <div class="col-12 col-md-6">
                                <a routerLink="/consumer/documentation">
                                    <div class="d-flex justify-content-between h-100 consumer-doc">
                                        <h2>{{"CONSUMER" | translate}}  <br/> {{ "DOCUMENTATIONS" | translate }}</h2>
                                        <img src="assets/documentation/c-1.svg" alt="Device Datasheet">
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>

        <div class="space adaptive large x2"></div>
    </div>
</div>
