import { Injectable } from "@angular/core";
import { fromEvent } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class WindowWidthService {
    private _windowWidth = Math.min(window.outerWidth, window.innerWidth);

    constructor() {
        fromEvent(window, 'resize').subscribe(() => {
            this._windowWidth = Math.min(window.outerWidth, window.innerWidth)
        });
        fromEvent(window, 'orientationchange').subscribe(() => {
            this._windowWidth = Math.min(window.outerWidth, window.innerWidth)
        });
    }

    get windowWidth() {
        return this._windowWidth;
    }
}
