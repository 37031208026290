import { Component, Host } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { CommonModule, ViewportScroller } from "@angular/common";
import { ConstructionDocumentationSegment, DocumentationSegment } from "../../documentation.segment";
import {FontAwesomeModule} from "@fortawesome/angular-fontawesome";
import {faAngleDown, faAngleRight, faAngleUp} from "@fortawesome/free-solid-svg-icons";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'construction-documentation-tiles',
    templateUrl: 'tiles.segment.html',
    styleUrls: [ 'tiles.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule, FontAwesomeModule, TranslateModule, RouterLink
    ]
})
export class TilesSegment {
    generalTrackApiDocs : boolean = true;
    deviceDatasheetsDocs : boolean = true;
    applicationExampleDocs : boolean = true;
    faAngleRight = faAngleRight;

    constructor(@Host() private host: ConstructionDocumentationSegment, private router: Router,
                private viewport: ViewportScroller) {
    }

    async setSegment(segment: DocumentationSegment) {
        switch (segment) {
            case DocumentationSegment.API_DOCS:
                return await this.router.navigateByUrl("/construction/documentation/api-docs");
            default:
                return await this.router.navigateByUrl("/construction/documentation");
        }
    }

    get DocumentationSegment() {
        return this.host.DocumentationSegment;
    }

    ToogleSegment(segment: string) {
          // @ts-ignore
        this[segment] = !this[segment];
    }

    protected readonly faAngleDown = faAngleDown;
    protected readonly faAngleUp = faAngleUp;

    openGettingStarted() {
        // this.viewport.setOffset([0, 130]);
        this.router.navigate(['/construction/documentation/api-docs'], { fragment: 'getting-started' }).then(() => {
            // this.viewport.setOffset([0, -100]);
        });
    }
}
