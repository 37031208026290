<div class="container">
    <div class="space adaptive large"></div>
    <div class="row">
        <div class="col-12 d-flex">
            <h2 [innerHTML]="'WE_CONFIGURE_NABI_ACCORDING_TO_YOUR_NEEDS' | translate"></h2>
        </div>
        <div class="col-12">
            <app-videojs
                [id]="'promo-video-2'"
                [language]="language"
                [options]='videojsNabiPromoOptions'>
            </app-videojs>
            <div class="space adaptive large"></div>
        </div>
        <div class="col-12 d-flex flex-column flex-md-row gap-3 gap-md-4">
            <button class="btn btn-primary btn-block" (click)="openPage($event, 'https://www.nabitrack.com/configuration')" [innerHTML]="'LOGISTICS_BUTTON_1' | translate"> </button>
            <button class="btn btn-primary btn-block" (click)="openPage($event, 'https://www.nabitrack.com/construction/documentation#mounting_solution')" [innerHTML]="'LOGISTICS_BUTTON_2' | translate"></button>
        </div>
    </div>
</div>

<div class="space adaptive large"></div>
