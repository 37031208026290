export enum AnimationDirection {
    BACKWARD = -1,
    FORWARD = 1
}
export const forwardAnimation = {
    value: 'changeDirection',
    params: {
        direction: AnimationDirection.FORWARD
    }
}
export const backwardAnimation = {
    value: 'changeDirection',
    params: {
        direction: AnimationDirection.BACKWARD
    }
}
