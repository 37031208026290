<div class="Page-container">
<!--    <div class="page-bg"></div>-->
<!--    <div class="space adaptive small"></div>-->
    <div class="Page-content container">
        <section class="Pane Pane-title">
            <div class="Pane-content flex-column">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-8 d-flex justify-content-center">
                        <h1> {{ "CONTACT_HEADER" | translate }} </h1>
                    </div>
                </div>
            </div>
        </section>

        <!-- Contact section -->
        <section class="Pane Pane-contact">
            <div class="Pane-backgroundContainer">
                <div class="Pane-background"></div>
            </div>
            <div class="Pane-content flex-column">
                <div class="container-fluid p-0">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <contact-form></contact-form>
                        </div>
                        <div
                            class="col-12 col-md-6 d-flex justify-content-center align-items-center align-items-md-start">
                            <div>
                                <h2> {{ "CONTACT_ASSIST_HEADER" | translate }} </h2>
                                <p>
                                    {{ "CONTACT_ASSIST_DESC" | translate }}
                                </p>
                                <ul style="font-style: italic !important;" [innerHTML]="'CONTACT_ASSIST_LIST' | translate"></ul>

                                <div class="Badges">
                                    <a class="Badge" href="tel:+36204442211">
                                        <fa-icon class="fa" [icon]="faPhone"></fa-icon>
                                        +36 20 444 2211
                                    </a>
                                    <a class="Badge" href="mailto:support@general-track.com">
                                        <fa-icon class="fa" [icon]="faEnvelope"></fa-icon>
                                        {{'support@general-track.com'}}
                                    </a>
                                    <a class="Badge" href="https://maps.app.goo.gl/iibzz6eKKoo8HoWC7" target="_blank">
                                        <fa-icon class="fa" [icon]="faLocationDot"></fa-icon>
                                        Nándorfejérvári út 33., Budapest, 1118, Hungary
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div class="space adaptive large"></div>
</div>
