<section class="meetourdevices">
    <div class="header mb-4">
       <h1 [innerHTML]="'MEET_OUR_DEVICES' | translate"></h1>
    </div>
    <div class="row position-relative justify-content-center">
        <div class="row">
            <div class="image-container">
                @if (windowWidth >= 768) {
                    <img src="{{meetOurDevicesImg}}" alt="nabi_infos"/>
                } @else {
                    <img src="{{meetOurDevicesMobileImg}}" alt="nabi_infos"/>
                }
            </div>
            <div class="space adaptive large"></div>
        </div>
    </div>
    <div class="how-div"  id="technology">
        <div class="container-fluid">
            <div class="container">
                <div>
                    <div class="d-flex justify-content-center">
                        @if (windowWidth >= 768) {
                            <img class="p-5" src="{{howitworksImg}}"/>
                        } @else {
                            <img class="p-5" src="{{howitworksMobileImg}}"/>
                        }
                    </div>
                    <div>
                        <span>{{"HOW_DOES_IT_WORK" | translate}}</span>
                        <p [innerHTML]="'HOW_DOES_IT_WORK_DESC' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
