import { CommonModule } from '@angular/common';
import {ChangeDetectorRef, Component, Input} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import {
    faInfoCircle as info,
    faTimes as close,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from "@ngx-translate/core";
import { animate, style, transition, trigger } from "@angular/animations";

@Component({
    selector: 'infographic',
    standalone: true,
    imports: [
        CommonModule,
        FontAwesomeModule,
        TranslateModule
    ],
    templateUrl: './infographic.component.html',
    styleUrl: './infographic.component.scss',
    animations: [
        trigger(
            'fade', [
                transition(':enter', [
                    style({ opacity: 0 }),
                    animate('20ms', style({ opacity: 1 }))
                ], { params: { delay: 0 } }),
                transition(':leave', [
                    style({ opacity: 1 }),
                    animate('150ms', style({ opacity: 0 }))
                ], { params: { delay: 0 } })
            ]
        )
    ]
})
export class InfographicComponent{
    @Input() breakpoint!: number;

    public currentSection = Section.NONE;
    public activeModal = Section.NONE;

    protected readonly icons = {
        close,
        info,
    };

    protected readonly Section = Section;

    language: string | undefined;

    constructor(private cdr: ChangeDetectorRef, private viewportScoller: ViewportScroller) {}

    setSection(section: Section) {
        this.currentSection = section;
        this.cdr.detectChanges();
    }

    setActiveModal(section: Section) {
        this.activeModal = section;
        this.cdr.detectChanges();
    }

    scrollTo(anchor: string) {
        this.viewportScoller.scrollToAnchor(anchor);
    }
}

enum Section {
    NONE,
    POSITION,
    DUTY_HOURS,
    LIVE_TRACKING,
    INDOOR,
    PLATFORM,
}
