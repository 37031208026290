<section class="Hero">
    <div class="Pane-backgroundContainer">
        <div class="Pane-background">
            <video #heroVideo autoplay [muted]="true" loop playsinline class="BackgroundVideo" [class.stopped]="scrollY > 10">
                <source src="/assets/videos/construction.webm" type="video/webm">
                <img src="/assets/construction/mobile_fallback.webp" alt="Your browser does not support the <video> tag"/>
            </video>
            <img src="/assets/construction/mobile_fallback.webp" alt="Your browser does not support the <video> tag"/>
        </div>
        <div class="Pane-backgroundOverlay" [class.stopped]="scrollY > 10"></div>
    </div>

    <div class="Pane-content">
        <div class="left-block">
            <div class="Hero-Heading">
                <h1>{{ "WE_GIVE_YOU" | translate }}</h1>
                <h1>{{ "FULL_CONTROL" | translate }}</h1>
            </div>
            <div class="Hero-Subheading">
                <h2>{{ "OVER_YOUR_ASSETS_AND_PROCESSES" | translate }}</h2>
            </div>
            <div class="grid-container">
                <div class="grid-row" *ngIf="scrollY <= 10" [@slideVertically]>
                    <div class="Hero-caption">
                        <p><span>{{ "BE_PART_OF_THE_FUTURE_OF_CONSTRUCTION_INDUSTRY" | translate }}</span></p>
                    </div>
                </div>
                <div class="grid-row" *ngIf="scrollY > 10" [@slideVertically]>
                    <div class="Hero-description">
                        <p [innerHTML]="'HERO_DESCRIPTION' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-block">
            <!-- img src="/assets/nabi_product_front.webp" alt="NaBi Product Front" -->
        </div>
    </div>
</section>
