import {
    AfterViewInit,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import videojs from 'video.js';
import 'videojs-youtube';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-videojs',
    templateUrl: './videojs.module.html',
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        CommonModule
    ]
})
export class VideojsModule implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @ViewChild('target', { static: true }) target!: ElementRef;
    @ViewChild('wrapper', { static: true }) wrapper!: ElementRef;

    @Input() poster!: string;
    @Input() options !: {
        fluid: boolean,
        techOrder?: string[],
        aspectRatio?: string,
        autoplay?: boolean,
        sources: {
            src: string,
            type: string,
        }[],
        youtube?: {
            hl: string,
            cc_load_policy: number,
            customVars: {
                cc_lang_pref: string
            }
        }
    };
    @Input() language: any;
    @Input() id: any;

    player!: videojs.Player;

    constructor() {}

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.createPlayer();
    }

    createPlayer() {
        try {
            this.player = videojs(this.target.nativeElement as HTMLVideoElement, this.options, function onPlayerReady() {
                // console.log('onPlayerReady', this);
            });
        } catch (e) {
            console.error('VIDEOJS createPlayer error');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['language']?.currentValue && this.language) {
            // console.log(changes.language.currentValue);
            this.changeLanguage(changes['language'].currentValue);
        }
    }

    ngOnDestroy(): void {
        if (this.player) {
            this.player.dispose();
        }
    }

    changeLanguage(language: string) {
        try {
            if (this.options?.youtube) {
                this.options.youtube.hl = language;
                this.options.youtube.customVars.cc_lang_pref = language;
            }

            if (this.player) {
                this.player.dispose();
            }

            const video = document.createElement('video');
            video.setAttribute('id', this.id);
            video.setAttribute('class', 'video-js');
            video.setAttribute('controls', '1');
            video.setAttribute('playsinline', '1');
            video.setAttribute('preload', 'none');
            video.setAttribute('width', '640');
            video.setAttribute('height', '264');

            if (this.wrapper?.nativeElement) {
                this.wrapper.nativeElement.appendChild(video);
            }

            if (this.target?.nativeElement) {
                this.target.nativeElement = video;
            }
        } catch (e) {
            console.error('VIDEOJS changeLanguage error');
        }

        this.createPlayer();
    }
}
