import { ChangeDetectorRef, Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NavigationEnd, Router } from "@angular/router";
import { ApiDocsSegment } from "../../../documentation-page/segments/api_docs/api_docs.segment";
import { TilesSegment } from "./segments/tiles/tiles.segment";
import {TranslateModule} from "@ngx-translate/core";

@Component({
    selector: 'construction-documentation',
    templateUrl: 'documentation.segment.html',
    styleUrls: [ 'documentation.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule,
        ApiDocsSegment,
        ApiDocsSegment,
        TilesSegment,
        TilesSegment,
        TranslateModule
    ]
})
export class LogisticsDocumentationSegment {
    public currentSegment = DocumentationSegment.TILES;

    constructor(private router: Router, private cdr: ChangeDetectorRef) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.router.url.startsWith(`/logistics/documentation/api-docs`)) {
                    this.setSegment(DocumentationSegment.API_DOCS);
                }
            }
        });
    }

    public setSegment(segment: DocumentationSegment) {
        this.currentSegment = segment;
        this.cdr.detectChanges();
    }

    get DocumentationSegment() {
        return DocumentationSegment;
    }
}

export enum DocumentationSegment {
    TILES,
    API_DOCS,
}
