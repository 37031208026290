import {BehaviorSubject, fromEvent, map, throttleTime} from "rxjs";
import {Injectable, NgZone} from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class ScrollService {
    private elementPositionSubject = new BehaviorSubject<number>(0);
    elementPosition$ = this.elementPositionSubject.asObservable();

    constructor(private ngZone: NgZone) {}

    listenToElementPosition(elementId: string) {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll')
                .pipe(
                    throttleTime(10),
                    map(() => document.getElementById(elementId)?.getBoundingClientRect().top || 0)
                )
                .subscribe(pos => {
                    this.ngZone.run(() => {
                        this.elementPositionSubject.next(pos);
                    });
                });
        });
    }
}
