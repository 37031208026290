import { CommonModule } from '@angular/common';
import {ChangeDetectorRef, Component, Injectable, NgZone, OnInit} from '@angular/core';
import {DraggableSliderComponent} from "../../../../components/draggable-slider/draggable-slider.component";
import {TranslateModule} from "@ngx-translate/core";
import {Icons} from "../../../../commons/Icons";
import {FaIconComponent} from "@fortawesome/angular-fontawesome";
import {BehaviorSubject, fromEvent, map, throttleTime} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

interface Device {
    name: string;
    value: string;
}

@Injectable({
    providedIn: 'root'
})

export class ScrollService {
    private elementPositionSubject = new BehaviorSubject<number>(0);
    elementPosition$ = this.elementPositionSubject.asObservable();

    constructor(private ngZone: NgZone) {}

    listenToElementPosition(elementId: string) {
        this.ngZone.runOutsideAngular(() => {
            fromEvent(window, 'scroll')
                .pipe(
                    throttleTime(10),
                    map(() => document.getElementById(elementId)?.getBoundingClientRect().top || 0)
                )
                .subscribe(pos => {
                    this.ngZone.run(() => {
                        this.elementPositionSubject.next(pos);
                    });
                });
        });
    }
}


@Component({
    selector: 'consumer-segment',
    standalone: true,
    imports: [
        CommonModule,
        DraggableSliderComponent,
        TranslateModule,
        FaIconComponent
    ],
    templateUrl: './consumer.component.html',
    styleUrl: './consumer.component.scss',
    animations: [
        trigger('fadeInOutAnimation', [
            state('in', style({opacity: 1, transform: 'translateY(0)', transition: 'all 0.4s ease'})),
            state('out', style({opacity: 0 , transform: 'translateY(10%)',transition: 'all 0.4s ease'})),
            transition(':enter', [
                style({opacity: 0}),
                animate('0.4s ease', style({opacity: 1, transform: 'translateY(0%)'}))
            ]),
            transition(':leave', [
                animate('0.4s ease', style({opacity: 0, transform: 'translateY(10%)'}))
            ])
        ]),
        trigger('iconAnimation', [
            state('default', style({ transform: 'scale(1)', opacity: 1 })),
            state('scaled', style({ transform: 'scale(1.1)', opacity: 0.8 })),
            transition('default <=> scaled', animate('1s ease-in-out'))
        ])]
})
export class ConsumerComponent implements OnInit {
    protected readonly Icons = Icons;
    showAnimation: string = 'out';
    devices: Device[] = [
        { name: 'on the market since', value: '2012',},
        { name: 'tracking devices made', value: '30000',},
        { name: 'availability', value: '99.99%'},
        { name: 'development and production', value: 'Domestic',}
    ];

    constructor(private scrollService : ScrollService) {
    }

    ngOnInit() {
        this.scrollService.listenToElementPosition('consumer-section');
        this.scrollService.elementPosition$.subscribe((pos: number) => {
            const isMobile = window.innerWidth <= 900;
            if ((isMobile && pos <= 900 && pos > 0) || (!isMobile && pos <= 900 && pos > 0)) {
                this.showAnimation = 'in';
            }
        });
    }

    delay(i: number) {
        return {
            value: '',
            params: { delay: i * 100 },
        };
    }
}
