<div class="Pane-content flex-column">
    <div class="space adaptive large"></div>
    <div class="container first-section">
        <div class="row">
            <div class="col-12 d-flex justify-content-center">
                <h2>{{ "APPLICATIONS_HEADER" | translate }}</h2>
            </div>
            <div class="col-12 col-md-3">
                <article class="ZoomerWindow" [class.active]="activeComment === TargetCustomer.CONTAINER_TRACKING" #self [style.height]="self.offsetWidth + 'px'" (mouseenter)="toggleComment(TargetCustomer.CONTAINER_TRACKING)">
                    <img src="assets/logistics/container_tracking.webp" alt="Container Tracking"/>
                    <span>{{ "APPLICATION_1" | translate }}</span>
                </article>

                @if(windowWidth <= 768){
                    <p [@slideVertically]>
<!--                        <b>{{ "APPLICATION_1" | translate }}</b> <br/>-->
                        <span [innerHTML]="'APPLICATION_1_DESCRIPTION' | translate "></span>
                    </p>
                }

            </div>
            <div class="col-12 col-md-3">
                <article class="ZoomerWindow" [class.active]="activeComment === TargetCustomer.PACKAGE_TRACKING" #self [style.height]="self.offsetWidth + 'px'" (mouseenter)="toggleComment(TargetCustomer.PACKAGE_TRACKING)">
                    <img src="assets/logistics/package_tracking.webp" alt="Package Tracking" />
                    <span>{{ "APPLICATION_2" | translate }}</span>
                </article>

                @if(windowWidth <= 768){
                    <p [@slideVertically]>
<!--                        <b>{{ "APPLICATION_2" | translate }}</b><br/>-->
                        <span [innerHTML]="'APPLICATION_2_DESCRIPTION' | translate "></span>
                    </p>
                }
            </div>
            <div class="col-12 col-md-3">
                <article class="ZoomerWindow" [class.active]="activeComment === TargetCustomer.SMART_PALLET" #self [style.height]="self.offsetWidth + 'px'" (mouseenter)="toggleComment(TargetCustomer.SMART_PALLET)">
                    <img src="assets/logistics/smart_pallet.webp" alt="Smart Pallet"/>
                    <span>{{ "APPLICATION_3" | translate }}</span>
                </article>

                @if(windowWidth <= 768){
                    <p [@slideVertically]>
<!--                        <b>{{ "APPLICATION_3" | translate }}</b><br/>-->
                        <span [innerHTML]="'APPLICATION_3_DESCRIPTION' | translate "></span>
                    </p>
                }
            </div>
            <div class="col-12 col-md-3">
                <article class="ZoomerWindow" [class.active]="activeComment === TargetCustomer.CONDITIONAL_MONITORING" #self [style.height]="self.offsetWidth + 'px'" (mouseenter)="toggleComment(TargetCustomer.CONDITIONAL_MONITORING)">
                    <img src="assets/logistics/condition_monitoring.webp" alt="Conditional Monitoring"/>
                    <span>{{ "APPLICATION_4" | translate }}</span>
                </article>

                @if(windowWidth <= 768){
                    <p [@slideVertically]>
<!--                        <b>{{ "APPLICATION_4" | translate }}</b><br/>-->
                        <span [innerHTML]="'APPLICATION_4_DESCRIPTION' | translate "></span>
                    </p>
                }
            </div>

            @if (windowWidth > 768) {
                <div class="col-12">
                    @if (activeComment === TargetCustomer.CONTAINER_TRACKING) {
                        <p [@slideVertically]>
                            <b>{{ "APPLICATION_1" | translate }}</b>
                            <span [innerHTML]="'APPLICATION_1_DESCRIPTION' | translate "></span>
                        </p>
                    } @else if (activeComment === TargetCustomer.PACKAGE_TRACKING) {
                        <p [@slideVertically]>
                            <b>{{ "APPLICATION_2" | translate }}</b>
                            <span [innerHTML]="'APPLICATION_2_DESCRIPTION' | translate "></span>
                        </p>
                    } @else if (activeComment === TargetCustomer.SMART_PALLET) {
                        <p [@slideVertically]>
                            <b>{{ "APPLICATION_3" | translate }}</b>
                            <span [innerHTML]="'APPLICATION_3_DESCRIPTION' | translate "></span>
                        </p>
                    } @else if (activeComment === TargetCustomer.CONDITIONAL_MONITORING) {
                        <p [@slideVertically]>
                            <b>{{ "APPLICATION_4" | translate }}</b>
                            <span [innerHTML]="'APPLICATION_4_DESCRIPTION' | translate "></span>
                        </p>
                    }
                </div>
            }
        </div>
    </div>
    <div class="space adaptive large"></div>
</div>
