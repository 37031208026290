import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { VideojsModule } from "../../../../components/videojs/videojs.module";
import { LocalStorageService } from "../../../../services/storage.service";
import {NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'video-segment',
    templateUrl: 'video.segment.html',
    styleUrls: [ 'video.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        VideojsModule
    ]
})
export class VideoSegment {
    public language: any;
    public videojsNabiPromoOptions;
    private url: string = "";

    constructor (private storage: LocalStorageService, private router: Router) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.url = event.url;
            }
        })

        this.language = this.storage.get('language');

        this.videojsNabiPromoOptions = {
            fluid: true,
            techOrder: [
                'youtube'
            ],
            sources: [{
                type: 'video/youtube',
                src: this.url !== "" ? 'https://www.youtube-nocookie.com/embed/7V9j1Xl1MPk' : 'https://youtu.be/akOjCvrieZo'
            }],
            youtube: {
                hl: this.language,
                cc_load_policy: 1,
                customVars: {
                    cc_lang_pref: this.language
                }
            }
        }
    }

    openPage($event: MouseEvent, url: string) {
        $event.preventDefault();
        window.open(url, '_blank');
    }

    protected readonly open = open;
}
