import {AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import { State } from '../platform.component';

@Directive({
    standalone: true,
    selector: '[sticky]'
})
export class StickyItemDirective implements OnChanges, AfterViewInit {
    @Input() scrolled = 0;
    @Input() offset = 0;
    @Output() onStateChanged: EventEmitter<State> = new EventEmitter<State>();

    public currentState: State = State.BEFORE_VIEWPORT;

    private element: HTMLDivElement | undefined;
    private offsetTop: number | undefined;

    constructor(private el: ElementRef) {
    }

    ngAfterViewInit() {
        this.element = (this.el.nativeElement as HTMLDivElement);
        this.offsetTop = ((window.innerHeight - this.element.getBoundingClientRect().height) / 2) + this.offset;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['scrolled']?.currentValue != null) {
            if (this.element != null) {
                // @ts-ignore
                if (this.element.getBoundingClientRect().top > this.offsetTop && this.currentState !== State.BEFORE_VIEWPORT) {
                    this.currentState = State.BEFORE_VIEWPORT;
                    this.onStateChanged.emit(State.BEFORE_VIEWPORT);
                } else { // @ts-ignore
                    if (this.element.getBoundingClientRect().top <= this.offsetTop && this.currentState !== State.IN_VIEWPORT) {
                                        this.currentState = State.IN_VIEWPORT;
                                        this.onStateChanged.emit(State.IN_VIEWPORT);
                                    }
                }
            }
        }
    }
}
