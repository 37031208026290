import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { CommonModule } from "@angular/common";

@Component({
    selector: 'app-hamburger-menu',
    templateUrl: './hamburger-menu.component.html',
    styleUrls: ['./hamburger-menu.component.scss'],
    standalone: true,
    imports: [
        CommonModule
    ]
})
export class HamburgerMenuComponent implements OnChanges {
    @Input() isOpened: boolean = false;
    @Output() isOpenedChange = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['isOpened']?.currentValue) {
            this.isOpenedChange.emit(changes['isOpened'].currentValue);
        }
    }

    toggleMenu() {
        this.isOpened = !this.isOpened;
        this.isOpenedChange.emit(this.isOpened);
    }
}
