import {AfterViewInit, Component, Input} from '@angular/core';
import { LocalStorageService } from '../../../../services/storage.service';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {CommonModule} from "@angular/common";
import { VideojsModule } from "../../../../components/videojs/videojs.module";

@Component({
    standalone: true,
    selector: 'new-use-case',
    templateUrl: './use-case.component.html',
    styleUrls: ['./use-case.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        VideojsModule
    ]
})
export class UseCaseComponent implements AfterViewInit {
    @Input() breakpoint: number | undefined;

    public language;

    public telenorVideo: any;
    public metalHungaryVideo: any;

    constructor(private storage: LocalStorageService, private translate: TranslateService) {
        this.language = this.storage.get('language');

        try {
            this.telenorVideo = this.initializeVideo('https://www.youtube-nocookie.com/embed/Aw5xPAyKX4U');
        } catch (e) {}
        try {
            this.metalHungaryVideo = this.initializeVideo('https://www.youtube-nocookie.com/embed/awZolOgoH5Q');
        } catch (e) {}

        this.translate.onDefaultLangChange.subscribe(change => {
            this.language = change.lang;

            switch (change.lang) {
                case 'hu':
                    this.metalHungaryVideo = this.initializeVideo('https://www.youtube-nocookie.com/embed/awZolOgoH5Q');
                    break;
                default:
                    this.telenorVideo = this.initializeVideo('https://www.youtube-nocookie.com/embed/Aw5xPAyKX4U');
                    break;
            }
        });
    }

    ngAfterViewInit() {
        // this.onResize();
        console.log('UseCase initialized');
    }

    public initializeVideo(src: string) {
        return {
            fluid: true,
            techOrder: ['youtube'],
            sources: [
                {
                    type: 'video/youtube',
                    src,
                },
            ],
            youtube: {
                hl: this.language,
                cc_load_policy: 1,
                customVars: {
                    cc_lang_pref: this.language,
                },
            },
        };
    }
}
