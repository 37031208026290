<form class="needs-validation" novalidate>
    <div class="row">
        <h2 class="row-header">{{ "CONTACT_INDUSTRY_INTEREST" | translate }}</h2>
        <div class="row text-white justify-content-center align-items-center pb-2">
            <div class="col-6 col-lg-6 col-xxl-3 d-flex flex-row p-0 m-0 justify-content-center align-items-center">
<!--                <input-->
<!--                    type="checkbox"-->
<!--                    id="construction"-->
<!--                    name="construction"-->
<!--                    value="construction"-->
<!--                    [formControl]="interestConstruction"/>-->
<!--                <label class="text-uppercase" for="construction">Construction</label>-->
                <div class="position-relative"  style="width: 98%; height: 60px;">
                    <div class="switch" style="z-index: 4;">
                        <input required type="checkbox" class="checkbox" id="construction"
                               name="checkbox"
                               value="construction"
                                [formControl]="interestConstruction"/>
                        <label class="label" for="construction">
                            <span class="switch-bg-construction"></span>
                            <span class="switch-on">
                                <span class="switch-inner"></span>
                            </span>
                            <span class="switch-off"></span>
                            <span class="switch-text">{{ "CONTACT_CONSTRUCTION" | translate }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-6 col-xxl-3 d-flex flex-row p-0 m-0 justify-content-center align-items-center">
<!--                <input-->
<!--                    type="checkbox"-->
<!--                    id="logistics"-->
<!--                    name="logistics"-->
<!--                    value="logistics"-->
<!--                    [formControl]="interestLogistics"/>-->
<!--                <label class="text-uppercase" for="logistics">Logistics</label>-->
                <div class=" position-relative" style="width: 98%; height: 60px;">
                    <div class="switch">
                        <input type="checkbox" class="checkbox" id="logistics"
                               name="checkbox"
                               value="logistics"
                               [formControl]="interestLogistics"/>
                        <label class="label" for="logistics">
                            <span class="switch-bg-logistics"></span>
                            <span class="switch-on">
                            <span class="switch-inner"></span>
                        </span>
                            <span class="switch-off"></span>
                            <span class="switch-text"> {{ "CONTACT_LOGISTICS" | translate }} </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-6 col-xxl-3 d-flex flex-row p-0 m-0 justify-content-center align-items-center">
<!--                <input-->
<!--                    type="checkbox"-->
<!--                    id="consumer"-->
<!--                    name="consumer"-->
<!--                    value="consumer"-->
<!--                    [formControl]="interestConsumer"/>-->
<!--                <label class="text-uppercase" for="consumer">Consumer</label>-->
                <div class="position-relative d-flex justify-content-center" style="width: 98%; height: 60px;">
                    <div class="switch">
                        <input type="checkbox" class="checkbox" id="consumer"
                               name="consumer"
                               value="consumer"
                               [formControl]="interestConsumer"/>
                        <label class="label" for="consumer">
                            <span class="switch-bg-consumer"></span>
                            <span class="switch-on">
                                <span class="switch-inner"></span>
                            </span>
                            <span class="switch-off"></span>
                            <span class="switch-text"> {{ "CONTACT_CONSUMER" | translate }} </span>
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-6 col-lg-6 col-xxl-3 d-flex flex-row p-0 m-0 justify-content-center align-items-center">
<!--                <input-->
<!--                    type="checkbox"-->
<!--                    id="consumer"-->
<!--                    name="consumer"-->
<!--                    value="consumer"-->
<!--                    [formControl]="interestConsumer"/>-->
<!--                <label class="text-uppercase" for="consumer">Consumer</label>-->
                <div class=" position-relative" style="width: 98%; height: 60px;">
                    <div class="switch">
                        <input type="checkbox" class="checkbox" id="other"
                               name="other"
                               value="other"
                               [formControl]="interestOther"/>
                        <label class="label" for="other">
                            <span class="switch-bg-other"></span>
                            <span class="switch-on">
                                <span class="switch-inner"></span>
                            </span>
                            <span class="switch-off"></span>
                            <span class="switch-text"> {{ "CONTACT_OTHER" | translate }} </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <input
            type="text"
            class="form-control"
            [class.is-invalid]="
                name.errors != null && (name.touched)
            "
            [attr.placeholder]="'CONTACT_FULL_NAME' | translate"
            [formControl]="name"
            required/>
    </div>
    <div class="form-group">
        <input
            type="text"
            class="form-control"
            [class.is-invalid]="
                company.errors != null && (company.touched)
            "
            [attr.placeholder]="'CONTACT_COMPANY_NAME' | translate"
            [formControl]="company"
            required/>
    </div>

    <div class="contact-type">
        <div>
            <div class="control-contact-group">
                <b (click)="selectedContactType = ContactType.MESSAGE"
                    [class.selected]="
                        selectedContactType === ContactType.MESSAGE
                    ">
                    <span class="selection-indicator"></span>
                    <i class="fa fa-envelope"></i>
                    <span>{{ "CONTACT_WRITE_US_DIRECTLY" | translate }}</span>
                </b>
                <b
                    (click)="selectedContactType = ContactType.RECALL"
                    [class.selected]="
                        selectedContactType === ContactType.RECALL
                    "
                >
                    <i class="fa fa-phone"></i> <span>{{ "CONTACT_REQUEST_CALLBACK" | translate }}</span>
                </b>
            </div>
            <div class="form-contact-group">
                <div
                    *ngIf="selectedContactType === ContactType.RECALL"
                    [@slideVertically]
                >
                    <div class="form-group">
                        <input
                            type="tel"
                            class="form-control"
                            [attr.placeholder]="'CONTACT_PHONE_NUMBER' | translate"
                            [class.is-invalid]="
                                phone.errors != null &&
                                (phone.touched)
                            "
                            [formControl]="phone"
                        />
                    </div>
                    <div class="form-group" style="min-height: 200px;">
                        <!-- (onSelect)="callbackTime = $event.value" -->
                        <dropdown
                            defaultText="Select callback time"
                            [itemList]="timeRanges"
                            [control]="callbackTime"
                            [class.is-invalid]="
                                callbackTime.errors != null &&
                                (callbackTime.touched)
                            "
                        >
                        </dropdown>
                    </div>
                </div>
                <div
                    *ngIf="selectedContactType === ContactType.MESSAGE"
                    [@slideVertically]
                >
                    <div class="form-group">
                        <input
                            type="email"
                            class="form-control"
                            [class.is-invalid]="
                                email.errors != null &&
                                (email.touched)
                            "
                            [attr.placeholder]="'CONTACT_BUSINESS_EMAIL' | translate"
                            [formControl]="email"
                        />
                    </div>
                    <div class="form-group">
                        <textarea
                            [class.is-invalid]="
                                message.errors != null &&
                                (message.touched)
                            "
                            [attr.placeholder]="'CONTACT_MESSAGE' | translate"
                            [formControl]="message"
                        ></textarea>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="form-group d-flex justify-content-center align-items-center m-3"
    >
        <re-captcha [formControl]="recaptcha" [siteKey]="siteKey"></re-captcha>
    </div>

    <div
        class="form-group d-flex justify-content-center align-items-center m-3"
    >
        <button
            class="Button Button-orange text-nowrap text-center"
            [class.loading]="isLoading && !response"
            [class.success]="!isLoading && response && response.success"
            [class.error]="!isLoading && response && !response.success"
            type="button"
            (click)="send()">

            @if (!isLoading && response == null) {
                {{ selectedContactType === ContactType.MESSAGE ? ("CONTACT_SEND_MESSAGE" | translate) : ("CONTACT_SUBMIT" | translate)  }}
            } @else if(isLoading && response == null) {
                <div [loading]="true" [isDark]="false" style="display: flex; justify-content: center; align-items: center"></div>
            } @else if(!isLoading && response?.success) {
                Success!
            } @else {
                {{ response?.message }}
            }
        </button>
    </div>
</form>
