import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ProductsComponent} from "../../components/products-comp/products.component";
import { OurplatformPageComponent} from "../../components/ourplatform-comp/ourplatform-comp.component";
import { MeetOurDevicesCompComponent} from "../../components/meet-our-devices-comp/meet-our-devices-comp.component";
import { ApplicationsCompComponent} from "../../components/applications-comp/applications-comp.component";
import { LogisticsHeroSegment } from "./segments/hero/hero.segment";
import { NabiVideoSegment } from "./segments/nabi-video/nabi-video.segment";
import { LogisticsProductsSegment } from "./segments/products/products.segment";

@Component({
    selector: 'app-logistics-page',
    standalone: true,
    imports: [
        CommonModule,
        ProductsComponent,
        OurplatformPageComponent,
        MeetOurDevicesCompComponent,
        ApplicationsCompComponent,
        LogisticsHeroSegment,
        NabiVideoSegment,
        LogisticsProductsSegment,
    ],
    templateUrl: './logistics-page.component.html',
    styleUrl: './logistics-page.component.scss',
})
export class LogisticsPageComponent {}
