import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { slideInHorizontally } from '../../animations/slide-in-horizontally.animation';
import { slideInVertically } from '../../animations/slide-in-vertically.animation';
import { AnimationDirection as _AnimationDirection } from '../../animations/animation.direction';
import { TranslateModule } from "@ngx-translate/core";

@Component({
    selector: 'applications-comp',
    standalone: true,
    imports: [ CommonModule, TranslateModule ],
    templateUrl: './applications-comp.component.html',
    styleUrl: './applications-comp.component.scss',
    animations: [
        trigger('slideVertically', [
            transition(
                ':enter',
                [
                    style({ transform: 'translateY(-15px)', opacity: 0 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(0)', opacity: 1 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
            transition(
                ':leave',
                [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(-15px)', opacity: 0 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
        ]),
        slideInHorizontally,
        slideInVertically,
    ],
})
export class ApplicationsCompComponent implements OnInit {
    activeComment: TargetCustomer | any = null;
    windowWidth = Math.min(window.outerWidth, window.innerWidth);
    scrollY = 0;

    constructor() {}

    ngOnInit() {
        this.activeComment = TargetCustomer.CONTAINER_TRACKING;
    }

    @HostListener('window:resize', []) onResize() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }
    @HostListener('window:orientationchange', []) onOrientationChange() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }

    @HostListener('window:scroll', []) onScroll() {
        this.scrollY = window.scrollY;
    }

    toggleComment(targetCustomer: TargetCustomer) {
        if (this.windowWidth > 768) {
            this.activeComment = targetCustomer;
        }
    }

    animationDirectionFactory = {
        get(direction: _AnimationDirection) {
            return {
                value: 'changeDirection',
                params: {
                    direction: direction,
                },
            };
        },
    };

    get TargetCustomer() {
        return TargetCustomer;
    }

    get AnimationDirection() {
        return _AnimationDirection;
    }
}

enum TargetCustomer {
    CONTAINER_TRACKING,
    PACKAGE_TRACKING,
    SMART_PALLET,
    CONDITIONAL_MONITORING,
}
