import { Component, Input } from "@angular/core";
import { LocalStorageService } from "../../../../services/storage.service";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons/faDotCircle";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faTemperatureHigh } from "@fortawesome/free-solid-svg-icons/faTemperatureHigh";
import { faRuler } from "@fortawesome/free-solid-svg-icons/faRuler";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import {
    faCircleXmark,
    faDoorOpen,
    faEarthEurope,
    faHouseChimneyUser,
    faPercent,
    faSignal,
    faSun
} from "@fortawesome/free-solid-svg-icons";
import { CommonModule } from "@angular/common";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

@Component({
    selector: 'logistics-products',
    templateUrl: 'products.segment.html',
    styleUrls: [ 'products.segment.scss' ],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        FontAwesomeModule
    ]
})
export class LogisticsProductsSegment {
    @Input() breakpoint: number | undefined;

    language;

    public cardState = {
        solo: false,
        connect: false,
        connectXL: false,
        t20: false,
        sense: false
    };

    constructor(private storage: LocalStorageService, private translate: TranslateService) {
        this.language = this.storage.get('language');
    }

    ngAfterViewInit() {
        // this.onResize();
        console.log('Products initialized');
    }

    setState(product: string) {
        // @ts-ignore
        this.cardState[product] = !this.cardState[product];
    }

    openPage($event: MouseEvent, url: string) {
        $event.preventDefault();
        const translatedUrl = this.translate.instant(url);
        window.open( translatedUrl, '_blank');
    }

    download($event: MouseEvent, key: string) {
        $event.preventDefault();

        const url = this.translate.instant(key);

        const a: HTMLAnchorElement = document.createElement('a')
        a.href = this.translate.instant(url);
        // @ts-ignore
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    get icons() {
        return {
            dummy: faDotCircle,
            marker: faMapMarkerAlt,
            temperature: faTemperatureHigh,
            size: faRuler,
            alarm: faBell,
            excel: faFileExcel,
            signal: faSignal,
            earth: faEarthEurope,
            door: faDoorOpen,
            humidity: faPercent,
            light: faSun,
            indoor: faHouseChimneyUser
        };
    }

    protected readonly faClose = faCircleXmark;
}
