import { animate, style, transition, trigger } from '@angular/animations';
import { AnimationDirection } from './animation.direction';

export const slideInHorizontally = trigger('slideInHorizontally', [
    transition(
        ':enter',
        [
            style({
                transform: 'translateX(calc(100% * {{direction}}))',
                opacity: 0,
            }),
            animate(
                '200ms',
                style({
                    transform: 'translateX(0)',
                    opacity: 1,
                })
            ),
        ],
        {
            params: {
                direction: AnimationDirection.FORWARD,
            },
        }
    ),
    transition(
        ':leave',
        [
            style({
                transform: 'translateX(0)',
                opacity: 1,
            }),
            animate(
                '200ms',
                style({
                    transform: 'translateX(calc(-100% * {{direction}}))',
                    opacity: 0,
                })
            ),
        ],
        {
            params: {
                direction: AnimationDirection.FORWARD,
            },
        }
    ),
]);
