import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { slideInHorizontallyDelay } from '../../../../animations/slide-in-horizontally-delay.animation';
import { slideVertically } from '../../../../animations/slide-vertically.animation';
import {TranslateModule, TranslatePipe} from "@ngx-translate/core";
import {LocalStorageService} from "../../../../services/storage.service";
import { RouterLink } from "@angular/router";
import {
    AnimationDirection as _AnimationDirection,
    AnimationDirection
} from "../../../../animations/animation.direction";

interface Circle {
    name: string;
    class: string;
}

@Component({
    standalone: true,
    selector: 'circles-segment',
    templateUrl: './circles.component.html',
    styleUrls: ['./circles.component.scss'],
    imports: [
        CommonModule,
        TranslateModule,
        RouterLink
    ],
    animations: [
        slideVertically,
        slideInHorizontallyDelay
    ],
})
export class CirclesComponent {
    windowWidth: number = window.innerWidth;
    language : string = '';
    circles: Circle[] = [
        { name: 'Construction', class: 'construction_img' },
        { name: 'Logistics', class: 'logistics_img' },
        { name: 'Smart Metering', class: 'pipe_img' },
        { name: 'Consumer', class: 'consumer_img' },
    ];

    hu: Circle[] = [
        { name: 'Építőipar', class: 'construction_img' },
        { name: 'Logisztika', class: 'logistics_img' },
        { name: 'Okos Mérés', class: 'pipe_img' },
        { name: 'Fogyasztó', class: 'consumer_img' },
    ]

    en: Circle[] = [
        { name: 'Construction', class: 'construction_img' },
        { name: 'Logistics', class: 'logistics_img' },
        { name: 'Smart Metering', class: 'pipe_img' },
        { name: 'Consumer', class: 'consumer_img' },
    ]

    de: Circle[] = [
        { name: 'Bauwesen', class: 'construction_img' },
        { name: 'Logistik', class: 'logistics_img' },
        { name: 'Intelligente Messung', class: 'pipe_img' },
        { name: 'Verbraucher', class: 'consumer_img' },
    ]

    constructor( private localStorageService: LocalStorageService) {
    }

    ngOnInit(): void {
        this.language = this.localStorageService.get('language') || 'en';
        this.circles = this.language === 'hu' ? this.hu : this.language === 'de' ? this.de : this.en;
    }

    delay(i: number) {
        return {
            value: '',
            params: { delay: i * 100 },
        };
    }


    protected readonly AnimationDirection = AnimationDirection;

    animationDirectionFactory = {
        get(direction: _AnimationDirection) {
            return {
                value: 'changeDirection',
                params: {
                    direction: direction,
                },
            };
        },
    };
    protected readonly window = window;
}
