<section>
    <div class="segment-bg"></div>

    <circles-segment id="circles"></circles-segment>
    <div class="space adaptive large"></div>
    <video-segment id="video"></video-segment>

    <ourproducts-segment id="ourproducts"></ourproducts-segment>

    <ourplatform-comp id="platform"></ourplatform-comp>

    <!-- applications-segment id="applications"></applications-segment -->

    <mobile-application id="applications"></mobile-application>

    <consumer-segment id="consumer"></consumer-segment>
</section>
