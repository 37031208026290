import { CommonModule } from '@angular/common';
import {
    Component,
    QueryList,
    ViewChildren,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";

interface Consumer {
    pic: string;
    name: string;
    logo: string;
    desc: string;
}

@Component({
    standalone: true,
    selector: 'draggable-slider',
    templateUrl: 'draggable-slider.component.html',
    styleUrls: ['draggable-slider.component.scss'],
    imports: [ CommonModule, TranslateModule ],
})
export class DraggableSliderComponent {
    @ViewChildren('item') items!: QueryList<HTMLLIElement>;
    @ViewChild('items') slider!: ElementRef<HTMLUListElement>;

    isDown: boolean = false;
    startX: number = 0;
    scrollLeft: number = 0;

    constructor(private elementRef: ElementRef) {}

    start(e: MouseEvent | TouchEvent) {
        this.isDown = true;
        this.slider.nativeElement.classList.add('active');
        this.startX =
            e instanceof MouseEvent
                ? e.pageX
                : e.touches[0].pageX - this.slider.nativeElement.offsetLeft;
        this.scrollLeft = this.slider.nativeElement.scrollLeft;
    }

    move(e: MouseEvent | TouchEvent) {
        if (!this.isDown) return;
        e.preventDefault();
        const x =
            e instanceof MouseEvent
                ? e.pageX
                : e.touches[0].pageX - this.slider.nativeElement.offsetLeft;
        const dist = x - this.startX;
        this.slider.nativeElement.scrollLeft = this.scrollLeft - dist;
    }

    end(e: MouseEvent | TouchEvent) {
        this.isDown = false;
        this.slider.nativeElement.classList.remove('active');
    }

    consumers: Consumer[] = Array.from({ length: 7 }, (_, index) => {
        return {
            pic: 'assets/pic' + index,
            name:
                index % 2 === 1
                    ? 'Cathrin Jones'
                    : index % 3 === 1
                    ? 'Phil Valis'
                    : 'Jazmin Kayne',
            logo:
                index === 2 || index === 5
                    ? 'assets/magyartelekom_share'
                    : index === 3
                    ? 'assets/DHL-Emblem'
                    : index % 3 === 1
                    ? index % 2 === 1
                        ? 'assets/DHL-Emblem'
                        : index % 4 === 1
                        ? 'assets/magyartelekom_share'
                        : 'assets/Vodafone_2017_logo'
                    : 'assets/Samsung_Logo.svg',
            desc:
                index % 2 === 1
                    ? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.' +
                      index
                    : 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        };
    });
}
