<section>
    <div
        class="consumer-slider d-flex align-items-start"
        #items
        (mousedown)="start($event)"
        (touchstart)="start($event)"
        (mousemove)="move($event)"
        (touchmove)="move($event)"
        (mouseleave)="end($event)"
        (mouseup)="end($event)"
        (touchend)="end($event)">




        <div class="consumer-div" #item>
            <div class="position-relative">
                <img class="row facepic user-select-none z-2" src="assets/company_logos/metal_hungaria.png" alt="facepic"/>
                <span class="row consumer-name text-uppercase">
                    Metal Hungária
                </span>
                <span class="row consumer-desc">
                    {{ "METAL_HUNGARIA_REVIEW" | translate }}
                </span>
            </div>
        </div>

        <div class="consumer-div" #item>
            <div>
                <img class="row facepic user-select-none" src="assets/company_logos/recycolor.png" alt="facepic"/>
                <span class="row consumer-name text-uppercase">
                    Recycolor
                </span>
                <span class="row consumer-desc">
                    {{ "RECYCOLOR_REVIEW" | translate }}
                </span>
            </div>
        </div>

        <div class="consumer-div" #item>
            <div>
                <img class="row facepic user-select-none" src="assets/company_logos/vodafone.png" alt="facepic"/>
                <span class="row consumer-name text-uppercase">
                    Vodafone
                </span>
                <span class="row consumer-desc">
                    {{ "VODAFONE_REVIEW" | translate }}
                </span>
            </div>
        </div>

        <div class="consumer-div" #item>
            <div>
                <img class="row facepic user-select-none" src="assets/company_logos/telenor.png" alt="facepic"/>
                <span class="row consumer-name text-uppercase">
                    Telenor Denmark
                </span>
                <span class="row consumer-desc">
                    {{ "TELENOR_DENMARK_REVIEW" | translate }}
                </span>
            </div>
        </div>

        <div class="consumer-div" #item>
            <div>
                <img class="row facepic user-select-none" src="assets/company_logos/arkessa.png" alt="facepic"/>
                <span class="row consumer-name text-uppercase">
                    Arkessa
                </span>
                <span class="row consumer-desc">
                    {{ "ARKESSA_REVIEW" | translate }}
                </span>
            </div>
        </div>
    </div>
</section>
