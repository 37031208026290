<section class="UseCase" #useCase>
    <div class="Pane-backgroundContainer">
        <div class="Pane-background"></div>
    </div>

    <div class="Pane-content">
        <div class="space adaptive large"></div>
        <h1>{{ "USE_CASES" | translate }}</h1>
        <div class="space adaptive medium"></div>
        <div class="row">
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card">
                    <div class="card-side">
                        <h3>Telenor Denmark</h3>
                        <figure>
                            <blockquote>
                                <p>{{ "FREDERIK_BRAAD_QUOTE" | translate }}</p>
                            </blockquote>
                            <figcaption>— Frederik Braad, <cite>{{ "BUSINESS_DEVELOPER" | translate }}, IoT - <i>Telenor Denmark</i></cite></figcaption>
                        </figure>
                        <app-videojs
                            [id]="'telenor-video'"
                            [language]="language"
                            [options]='telenorVideo'>
                        </app-videojs>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card">
                    <div class="card-side">
                        <h3>Metal Hungária</h3>
                        <figure>
                            <blockquote>
                                <p>{{ "HORVATH_ADAM_QUOTE" | translate }}</p>
                            </blockquote>
                            <figcaption>— Horváth Ádám, <cite>{{ "CHIEF_FINANCIAL_OFFICER" | translate }} - <i>Metál Hungária</i></cite></figcaption>
                        </figure>

                        @if (language === 'hu') {
                            <app-videojs
                                [id]="'metal-hungary-video'"
                                [language]="language"
                                [options]='metalHungaryVideo'>
                            </app-videojs>
                        } @else {
                            <div class="image-container">
                                <img src="assets/metal_hungary_thumbnail.webp" alt="Metál Hungária use case"/>
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card">
                    <div class="card-side">
                        <h3>Arkessa</h3>
                        <p>
                            {{ "ARKESSA_DESCRIPTION" | translate }}
                            <a class="btn btn-orange" href="https://www.arkessa.com/media/y2cll1kc/arkessa-and-general-mechatronics-kft_nabi_case-study-27-may-48.pdf" target="_blank">{{ "READ_MORE" | translate }}</a>
                        </p>
                        <div class="image-container">
                            <img src="assets/arkessa_thumbnail.webp" alt="Arkessa use case"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="space adaptive large"></div>
    </div>
</section>
