import { Component, HostListener, ViewChild } from '@angular/core';
import {
    ContactFormComponent,
    ContactType,
} from '../../components/contact-form/contact-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
    faPhone,
    faEnvelope,
    faLocationDot,
} from '@fortawesome/free-solid-svg-icons';
import {TranslateModule} from "@ngx-translate/core";

@Component({
    standalone: true,
    selector: 'contact',
    templateUrl: 'contact.page.html',
    styleUrls: ['contact.page.scss'],
    imports: [ContactFormComponent, FontAwesomeModule, TranslateModule],
})
export class ContactPage {
    faPhone = faPhone;
    faEnvelope = faEnvelope;
    faLocationDot = faLocationDot;
    @ViewChild(ContactFormComponent)
    private _contactFormComponent!: ContactFormComponent;
    windowWidth = Math.min(window.outerWidth, window.innerWidth);

    constructor() {}

    send() {
        this.contactFormComponent.send();
    }

    get contactFormComponent() {
        return this._contactFormComponent;
    }

    get ContactType() {
        return ContactType;
    }

    @HostListener('window:resize', []) onResize() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }
    @HostListener('window:orientationchange', []) onOrientationChange() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }
}
