import { CommonModule, ViewportScroller } from '@angular/common';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { OurplatformPageComponent } from '../../components/ourplatform-comp/ourplatform-comp.component';
import { InfographicComponent } from './segments/infographic/infographic.component';
// import { UseCaseComponent } from './segments/use-case/use-case.component';
import { MeetOurDevicesCompComponent } from '../../components/meet-our-devices-comp/meet-our-devices-comp.component';
// import { ProductsComponent } from '../../components/products-comp/products.component';
import { TopDivComponent } from './segments/topdiv/top-div.component';
import { LocalStorageService } from '../../services/storage.service';
import {PlatformComponent} from "./new_segments/platform/platform.component";
import {ProductsComponent} from "./new_segments/products/products.component";
import {UseCaseComponent} from "./new_segments/use-case/use-case.component";
import { Hero2Component } from "./segments/hero/hero_2.component";

@Component({
    selector: 'app-construction-page',
    standalone: true,
    imports: [
        CommonModule,
        OurplatformPageComponent,
        InfographicComponent,
        UseCaseComponent,
        MeetOurDevicesCompComponent,
        ProductsComponent,
        TopDivComponent,
        PlatformComponent,
        ProductsComponent,
        UseCaseComponent,
        Hero2Component,
    ],
    templateUrl: './construction-page.component.html',
    styleUrl: './construction-page.component.scss',
})
export class ConstructionPageComponent {
    // language: any;
    // navigationSubscription: any;
    // route: any;
    //
    // constructor(
    //     private storage: LocalStorageService,
    //     private viewportScroller: ViewportScroller
    // ) {
    //     this.language = this.storage.get('language');
    // }
    //
    // ngAfterViewInit() {
    //     // this.onResize();
    //     console.log('Construction page initialized');
    //
    //     this.navigationSubscription = this.route.fragment.subscribe(
    //         (fragment: string) => {
    //             if (fragment && fragment.length > 0) {
    //                 try {
    //                     setTimeout(
    //                         () =>
    //                             this.viewportScroller.scrollToAnchor(fragment),
    //                         100
    //                     );
    //                 } catch (e) {}
    //             }
    //         }
    //     );
    // }
    //
    // ngOnDestroy(): void {
    //     if (this.navigationSubscription) {
    //         this.navigationSubscription.unsubscribe();
    //     }
    // }

    scrollTo(id: string): void {
        console.log(id);
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({behavior: "smooth"});
        }
    }
}
