<section class="Infographic">
    <div class="space adaptive medium"></div>
    <div class="Pane-backgroundContainer">
        <div class="Pane-background"></div>
    </div>

    <div class="Pane-content">
        <h1>
            <span>{{ "YOUR_CHALLENGES" | translate }}</span>, {{ "OUR_SOLUTION" | translate }}
        </h1>
        <div class="space adaptive large"></div>
        <h3 class="text-center" [style.color]="'#808080'">
            <fa-icon [icon]="icons.info"></fa-icon>
            {{ "CLICK_ON_AN_AREA" | translate }}
        </h3>

        <div class="Infographic-container">
            <div>
                <img src="../../../../assets/construction/Infographic_web_COLOR.webp" alt="InfoGraphics"/>
                <img *ngIf="currentSection === Section.INDOOR" [@fade] src="../../../../assets/construction/Infographic_web_INDOOR.webp" alt="Indoor"/>
                <img *ngIf="currentSection === Section.DUTY_HOURS" [@fade] src="../../../../assets/construction/Infographic_web_DUTYHOURS.webp" alt="Duty hours"/>
                <img *ngIf="currentSection === Section.LIVE_TRACKING" [@fade] src="../../../../assets/construction/Infographic_web_LIVETRACKING.webp" alt="Live tracking"/>
                <img *ngIf="currentSection === Section.POSITION" [@fade] src="../../../../assets/construction/Infographic_web_POSITION.webp" alt="Position"/>
                <img *ngIf="currentSection === Section.PLATFORM" [@fade] src="../../../../assets/construction/Infographic_web_BW.webp" alt="Platform"/>
                <img [class.grayed]="currentSection !== Section.NONE" class="platform" src="../../../../assets/construction/infographic_platform.svg" alt="Platform" (mouseenter)="setSection(Section.PLATFORM)" (mouseleave)="setSection(Section.NONE)" (click)="scrollTo('platform')"/>

                <div class="click-area">
                    <div style="display: grid; margin-top: -8%">
                        <div class="clickable" style="grid-area: 1 / 1 / 1 / 3; margin-left: -10%"
                            (mouseenter)="setSection(Section.INDOOR)"
                            (mouseleave)="setSection(Section.NONE)"
                            (click)="setActiveModal(Section.INDOOR)">
                        </div>
                        <div class="clickable" style="grid-area: 1 / 3 / 1 / 6"
                            (mouseenter)="setSection(Section.DUTY_HOURS)"
                            (mouseleave)="setSection(Section.NONE)"
                             (click)="setActiveModal(Section.DUTY_HOURS)">
                        </div>
                    </div>
                    <div style="display: grid; margin: 0 -8%">
                        <div class="clickable" style="grid-area: 1 / 1 / 1 / 2"
                            (mouseenter)="setSection(Section.POSITION)"
                            (mouseleave)="setSection(Section.NONE)"
                             (click)="setActiveModal(Section.POSITION)">
                        </div>
                        <div class="clickable" style="grid-area: 1 / 2 / 1 / 3"
                            (mouseenter)="setSection(Section.LIVE_TRACKING)"
                            (mouseleave)="setSection(Section.NONE)"
                             (click)="setActiveModal(Section.LIVE_TRACKING)">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="space adaptive large"></div>
    </div>
    <div class="modal modal-left" *ngIf="activeModal === Section.DUTY_HOURS" [@fade] (click)="setActiveModal(Section.NONE)">
        <div class="modal-content">
            <header>
                <h3>{{ "DUTY_HOURS" | translate }}</h3>
                <button class="btn" (click)="setActiveModal(Section.NONE)">
                    <fa-icon [icon]="icons.close"></fa-icon>
                </button>
            </header>
            <div class="row">
                <div class="col">
                    <main>
                        <p [innerHTML]="'DUTY_HOURS_DESCRIPTION' | translate"></p>
                    </main>
                    <footer>
                        <img
                            src="assets/construction/cards/nabi_solo_horizontal.png.webp"
                            alt="NaBi Solo"
                        />
                    </footer>
                </div>
                <div class="col" >
                    <img
                        src="assets/construction/cards/NaBi_Solo_render.1.png.webp"
                        alt="NaBi Solo"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="modal modal-right" *ngIf="activeModal === Section.POSITION" [@fade] (click)="setActiveModal(Section.NONE)">
        <div class="modal-content">
            <header>
                <h3>{{ "POSITION" | translate }}</h3>
                <button class="btn" (click)="setActiveModal(Section.NONE)">
                    <fa-icon [icon]="icons.close"></fa-icon>
                </button>
            </header>
            <div class="row">
                <div class="col">
                    <main>
                        <p [innerHTML]="'POSITION_DESCRIPTION' | translate"></p>
                    </main>
                    <footer>
                        <img
                            src="assets/construction/cards/nabi_solo_horizontal.png.webp"
                            alt="NaBi Solo"
                        />
                    </footer>
                </div>
                <div class="col" >
                    <img
                        src="assets/construction/cards/NaBi_Solo_render.1.png.webp"
                        alt="NaBi Solo"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-left" *ngIf="activeModal === Section.LIVE_TRACKING" [@fade] (click)="setActiveModal(Section.NONE)">
        <div class="modal-content">
            <header>
                <h3>{{ "LIVE_TRACKING" | translate }}</h3>
                <button class="btn" (click)="setActiveModal(Section.NONE)">
                    <fa-icon [icon]="icons.close"></fa-icon>
                </button>
            </header>
            <div class="row">
                <div class="col">
                    <main>
                        <p [innerHTML]="'LIVE_TRACKING_DESCRIPTION' | translate"></p>
                    </main>
                    <footer>
                        <img
                            src="assets/construction/cards/T20_horizontal.png.webp"
                            alt="NaBi Solo"
                        />
                    </footer>
                </div>
                <div class="col" >
                    <img
                        src="assets/construction/cards/T20_render.webp"
                        alt="T20"
                    />
                </div>
            </div>
        </div>
    </div>

    <div class="modal modal-right" *ngIf="activeModal === Section.INDOOR" [@fade] (click)="setActiveModal(Section.NONE)">
        <div class="modal-content">
            <header>
                <h3>{{ "INDOOR_LOCALIZATION" | translate }}</h3>
                <button class="btn" (click)="setActiveModal(Section.NONE)">
                    <fa-icon [icon]="icons.close"></fa-icon>
                </button>
            </header>
            <div class="row">
                <div class="col">
                    <main>
                        <p [innerHTML]="'INDOOR_LOCALIZATION_DESCRIPTION' | translate"></p>
                    </main>
                    <footer>
                        <img
                            src="assets/construction/cards/nabi_connect_horizontal.png.webp"
                            alt="NaBi Connect"
                        />
                    </footer>
                </div>
                <div class="col" >
                    <img
                        src="assets/construction/cards/nabi_connect_render.png.webp"
                        alt="NaBi Connect render"
                    />
                </div>
            </div>
        </div>
    </div>
</section>
