import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { RouterLink } from "@angular/router";

@Component({
    selector: 'whoweare-page',
    standalone: true,
    imports: [ CommonModule, TranslateModule, RouterLink ],
    templateUrl: './whoweare-page.component.html',
    styleUrl: './whoweare-page.component.scss',
})
export class WhowearePageComponent {
    windowWidth = Math.min(window.outerWidth, window.innerWidth);

    constructor() {}

    @HostListener('window:resize', []) onResize() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }
    @HostListener('window:orientationchange', []) onOrientationChange() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
    }
}
