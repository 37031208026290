<section class="consumer-section">
    <div class="space adaptive large"></div>
    <div class="first container" id="consumer-section" [@fadeInOutAnimation]="showAnimation">
        <h1>{{ "YOU_CAN_TRUST_OUR_DEVICES" | translate }}</h1>
        <div class="d-flex flex-column justify-content-center">
            <div class="device row p-5 gap-3 gap-md-0">
            <!-- <div class="device-values row"> -->
                <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
                    <h2 class="dvalue">{{ "ON_THE_MARKET_VALUE" | translate }}</h2>
                    <h6 class="dname text-uppercase text-wrap">{{ "ON_THE_MARKET_NAME" | translate }}</h6>
                </div>
                <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
                    <h2 class="dvalue">{{ "SOLD_DEVICES_VALUE" | translate }}</h2>
                    <h6 class="dname text-uppercase text-wrap">{{ "SOLD_DEVICES_NAME" | translate }}</h6>
                </div>
                <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
                    <h2 class="dvalue">{{ "B2B_CUSTOMERS_VALUE" | translate }}</h2>
                    <h6 class="dname text-uppercase text-wrap">{{ "B2B_CUSTOMERS_NAME" | translate }}</h6>
                </div>
                <div class="col-12 col-md-3 d-flex flex-column justify-content-center align-items-center">
                    <h2 class="dvalue">{{ "EMPLOYEES_VALUE" | translate }}</h2>
                    <h6 class="dname text-uppercase text-wrap">{{ "EMPLOYEES_NAME" | translate }}</h6>
                </div>
            </div>
        </div>
    </div>
    <div class="second container-fluid">

        <h3 class="second-header">{{ "OUR_COSTUMERS_SAID" | translate }}</h3>

        <draggable-slider></draggable-slider>

        <div class="w-100 container m-auto color-white" style="padding: 75px 0">
            <h5 class="fw-semibold fst-italic text-white w-100 text-center">{{ "CONSUMER_SWIPE_FOR_MORE" | translate }}</h5>
            <div class="row justify-content-center position-relative">
                <img class="col-8 col-md-4" src="assets/SVG/drag-arrow.svg" alt="drag">
            </div>
        </div>
    </div>
</section>
