<section class="Pane Pane-tiles">
    <div class="Pane-content flex-column">
        <div class="container-fluid">
            <div class="row">
                <h2 class="text-capitalize text-start text-md-start mb-0" (click)="ToogleSegment('generalTrackApiDocs')">
                    General Track API
                    @if(generalTrackApiDocs){
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    }@else{
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    }
                </h2>
                @if(generalTrackApiDocs){
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a routerLink="/consumer/documentation/api-docs">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2>API</h2>-->
<!--                                <img style="max-height: 100px;" src="assets/documentation/api-docs.svg" alt="API Documentation">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
                    <div class="col-12 col-md-6">
                        <a (click)="openGettingStarted()">
                            <div class="d-flex justify-content-between h-100">
                                <h2>{{'DOC_GETTING_STARTED' | translate}}</h2>
                                <img style="max-height: 100px;" src="assets/documentation/api-docs.svg" alt="API Documentation">
                            </div>
                        </a>
                    </div>
                }
                <h2 class="text-capitalize text-start text-md-start mb-0" (click)="ToogleSegment('deviceDatasheetsDocs')">
                    {{'DOC_DEVICE_DATASHEETS' | translate}}
                    @if(deviceDatasheetsDocs){
                        <fa-icon [icon]="faAngleUp"></fa-icon>
                    }@else{
                        <fa-icon [icon]="faAngleDown"></fa-icon>
                    }
                </h2>
                @if(deviceDatasheetsDocs){
                    <div class="col-12 col-md-6">
                        <a [href]="'NABI_SOLO_DOCS' | translate" target="_blank">
                            <div class="d-flex justify-content-between h-100">
                                <h2 class="text-white text-capitalize fw-semibold"><b class="nabitext text-white text-capitalize">NaBi</b> Solo</h2>
                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">
                            </div>
                        </a>
                    </div>
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a [href]="'NABI_CONNECT_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <div class="d-flex flex-column">-->
<!--                                    <h2 class="text-white text-capitalize fw-semibold"><b class="nabitext text-white text-capitalize">NaBi</b> Connect</h2>-->
<!--                                    <h2 class="text-white text-capitalize fw-semibold"><b class="nabitext text-white text-capitalize">NaBi</b> Connect XL</h2>-->
<!--                                </div>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="col-12 col-md-4">-->
<!--                        <a [href]="'NABI_SENSE_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2 class="text-white text-capitalize fw-semibold"> NaBi Sense </h2>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
                }
<!--                <h2 class="text-capitalize text-start text-md-start mb-0" (click)="ToogleSegment('applicationExampleDocs')">-->
<!--                    {{'DOC_APPLICATION_EXAMPLES' | translate}}-->
<!--                    @if(applicationExampleDocs){-->
<!--                        <fa-icon [icon]="faAngleUp"></fa-icon>-->
<!--                    }@else{-->
<!--                        <fa-icon [icon]="faAngleDown"></fa-icon>-->
<!--                    }-->
<!--                </h2>-->
<!--                @if(applicationExampleDocs){-->
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a [href]="'GPS_TRACKING_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2 class="text-white text-capitalize fw-semibold">{{ 'DOC_GPS_TRACKING' | translate }}</h2>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a [href]="'COLD_CHAIN_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2 class="text-white text-capitalize fw-semibold">{{'DOC_COLD_CHAIN' | translate}}</h2>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a [href]="'HOURMETER_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2 class="text-white text-capitalize fw-semibold">{{'DOC_HOURMETER' | translate}}</h2>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                    <div class="col-12 col-md-6">-->
<!--                        <a [href]="'SMART_PALLET_DOCS' | translate" target="_blank">-->
<!--                            <div class="d-flex justify-content-between h-100">-->
<!--                                <h2 class="text-white text-capitalize fw-semibold">{{'DOC_SMART_PALLET' | translate}}</h2>-->
<!--                                <img src="assets/documentation/c-1.svg" alt="Device Datasheet">-->
<!--                            </div>-->
<!--                        </a>-->
<!--                    </div>-->
<!--                }-->
            </div>
        </div>
    </div>
</section>
