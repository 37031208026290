import { Component, EventEmitter, Input, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

@Component({
    standalone: true,
    selector: 'dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['dropdown.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
    ],
    animations: [
        trigger('slideVertically', [
            transition(
                ':enter',
                [
                    style({ transform: 'translateY(-15px)', opacity: 0 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(0)', opacity: 1 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
            transition(
                ':leave',
                [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate(
                        '180ms',
                        style({ transform: 'translateY(-15px)', opacity: 0 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
        ]),
    ],
})
export class DropdownComponent<T extends { name: string; value: any }> {
    @Input() defaultText: string | undefined;
    @Input() itemList: T[] = [];
    @Input() control: FormControl | undefined;

    @Output() onSelect = new EventEmitter<T>();

    public isSelectionActive = false;
    public isTouched = false;
    protected readonly icons = {
        angelDown: faAngleDown,
    };

    constructor() {}

    select(item: T) {
        this.isSelectionActive = false;
        this.control?.setValue(item);
    }

    onClick() {
        this.isSelectionActive = !this.isSelectionActive;
        this.isTouched = true;
    }
}
