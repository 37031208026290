<div class="ourplatform-div">
    <div class="space adaptive large"></div>
    <div id="ourplatform-anim" [@fadeInOutAnimation]="showAnimation" class="container">
        <div class="row">

            <div class="col-12 col-lg-6 laptop-div">
                <img src="assets/macbook.webp" alt="Platform"/>
            </div>

            <div class="col-12 col-lg-6 d-flex flex-column list-div">
                <div class="list-div-header">
                    <h1 class="text-uppercase h1" [innerHTML]="'PLATFORM_OUR_PLATFORM' | translate"></h1> <fa-icon id="information" class="fa" style="font-size: 1em !important;" [icon]="faCircleInfo" (click)="changeOpenInformation()"></fa-icon>
                </div>
                <div class="switch-div">
                        <div class="info-div" [class.showDiv]="openInformation">
                            <p>
                                {{ "PLATFORM_INFO_1" | translate }}
                                <br/><br/>
                                {{ "PLATFORM_INFO_2" | translate }}
                            </p>
                        </div>
                    <div class="row">
                        <fa-icon
                            class="fa col-2"
                            [icon]="faLayerGroup">
                        </fa-icon>
                        <span class="col-10" [innerHTML]="'PLATFORM_ONE_PLATFORM' | translate">
                    </span>
                    </div>
                    <div class="row">
                        <fa-icon
                            class="fa col-2"
                            [icon]="faScrewdriverWrench"
                        ></fa-icon>
                        <span class="col-10" [innerHTML]="'PLATFORM_AVOID_ACCIDENTS' | translate">
                    </span>
                    </div>
                    <div class="row">
                        <fa-icon class="fa col-2" [icon]="faClock"></fa-icon>
                        <span class="col-10" [innerHTML]="'PLATFORM_REDUCE_EFFICIENCY' | translate">
                    </span>
                    </div>
                    <div class="row">
                        <fa-icon
                            class="fa col-2"
                            [icon]="faLocationDot"
                        ></fa-icon>
                        <span class="col-10" [innerHTML]="'PLATFORM_MONITOR_LOCATION' | translate">
                    </span>
                    </div>
                </div>

                <div class="space adaptive medium"></div>

<!--                <div class="d-flex flex-column flex-md-row justify-content-center align-items-center">-->
<!--                    <div class="col-3 p-0 m-0 p-md-1 d-flex justify-content-center">-->
<!--                        <img class="apps-pic" src="assets/Google%20play.svg"/>-->
<!--                    </div>-->
<!--                    <div class="col-3 p-0 m-0 p-md-1 d-flex justify-content-center">-->
<!--                        <img class="apps-pic" src="assets/Apple.svg"/>-->
<!--                    </div>-->
<!--                    <div class="col-3 p-0 m-0 p-md-1 d-flex justify-content-center">-->
<!--                        <img class="apps-pic" src="assets/huawei_appgallery_en.svg"/>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="row">-->
<!--                    <span class="d-flex justify-content-center align-items-center or">-->
<!--                        - OR - -->
<!--                    </span>-->
<!--                </div>-->
                <div class="row justify-content-center align-items-center">
                    <!-- div class="login-pic">
                        <div>
                            <img width="25px" height="25px" src="assets/GT_icon.png"/>
                        </div>
                        <div class="d-flex flex-column align-content-center">
                            <span class="text-uppercase">Log in</span>
                            <span class="text-uppercase">on our website</span>
                        </div>
                    </div -->

<!--                    <div class="login-pic">-->
<!--                        <div>-->
<!--                            <img src="assets/GT_icon.png" alt="gt_icon"/>-->
<!--                        </div>-->
<!--                        <div class="d-flex flex-column align-content-center">-->
<!--                            <span class="text-uppercase">Log in</span>-->
<!--                            <span class="text-uppercase">on our website</span>-->
<!--                        </div>-->
<!--                    </div>-->

                </div>
            </div>
        </div>
        <!--div class="row">
            <div class="col-12 col-lg-4"></div>
            <div class="col-12 col-lg-8 d-flex flex-column pics-div">

            </div>
        </div -->
    </div>
    <div class="space adaptive large"></div>
</div>
