import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, HostListener } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faAngleLeft, faAngleRight, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { Router, NavigationEnd } from '@angular/router';
import { LanguageChangerComponent } from "../language-changer/language-changer.component";
import { HamburgerMenuComponent } from "../hamburger-menu/hamburger-menu.component";
import { AnimationDirection } from "../../animations/animation.direction";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {SharedService} from "../../services/shared.service";
import {fade} from "../../animations/fade.animation";
import {slideVertically} from "../../animations/slide-vertically.animation";

interface menu {
    name: string;
    path: string;
    color: string;
    active: boolean;
    altMenus: altmenu[] | null;
}

interface altmenu {
    ScrollTo: string;
    Name: string;
    isDoc?: boolean;
}

@Component({
    standalone: true,
    selector: 'page-header',
    templateUrl: 'page-header.component.html',
    styleUrls: ['page-header.component.scss'],
    imports: [
        CommonModule,
        RouterLink,
        FontAwesomeModule,
        LanguageChangerComponent,
        HamburgerMenuComponent,
        TranslateModule,
    ],
    animations: [
        fade,
        trigger('slideVertically', [
            transition(
                ':enter',
                [
                    style({ transform: 'translateY(15px)', opacity: 0 }),
                    animate(
                        '{{delay}}' ? '180ms {{delay}}ms' : '180ms',
                        style({ transform: 'translateY(0)', opacity: 1 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
            transition(
                ':leave',
                [
                    style({ transform: 'translateY(0)', opacity: 1 }),
                    animate(
                        '{{delay}}' ? '180ms {{delay}}ms' : '180ms',
                        style({ transform: 'translateY(15px)', opacity: 0 })
                    ),
                ],
                { params: { delay: 0 } }
            ),
        ])
    ],
})
export class PageHeaderComponent implements AfterViewInit {
    urlName: string = '';
    altMenu: boolean = true;
    isOpen: boolean = false;
    mobileMenuIsOpened: boolean = false;
    loginBtnIcon: string = '/assets/GT_icon.png';
    logoImg: string | null = null;
    faAngleLeft = faAngleLeft;
    faAngleRight = faAngleRight;
    faClose = faCircleXmark;
    title: string = 'Page header component';
    morebtnShow: boolean = false;
    menus: menu[];

    delay(i: number) {
        return {
            value: '',
            params: { delay: i * 100 },
        };
    }

    languages: { name: string; active: boolean }[] = [
        { name: 'Hungarian', active: true },
        { name: 'English', active: false },
        { name: 'German', active: false },
    ];

    selectedFlag: string = this.languages.find((lang) => lang.active)?.name ?? '';

    windowWidth = Math.min(window.outerWidth, window.innerWidth);
    public isLoaded = false;

    constructor(private router: Router, public translate: TranslateService, private sharedService: SharedService) {
        this.menus = [
            {
                name: 'CONSTRUCTION',
                path: '/construction',
                color: '#ffaa00',
                active: false,
                altMenus:  [
                    { ScrollTo: 'applications', Name: 'APPLICATIONS' },
                    { ScrollTo: 'platform', Name: 'PLATFORM' },
                    { ScrollTo: 'technology', Name: 'TECHNOLOGY' },
                    { ScrollTo: 'products', Name: 'PRODUCTS' },
                    { ScrollTo: '', Name: 'DOCUMENTATIONS', isDoc: true },
                ]
            },
            {
                name: 'LOGISTICS',
                path: '/logistics',
                color: '#06a9f7',
                active: false,
                altMenus:  [
                    { ScrollTo: 'technology', Name: 'TECHNOLOGY' },
                    { ScrollTo: 'applications', Name: 'APPLICATIONS' },
                    { ScrollTo: 'platform', Name: 'PLATFORM' },
                    { ScrollTo: 'products', Name: 'PRODUCTS' },
                    { ScrollTo: '', Name: 'DOCUMENTATIONS', isDoc: true },
                ]
            },
            /*
            {
                name: 'Smart metering',
                path: '/smart-metering',
                active: false,
                altMenus:  [
                    { ScrollTo: 'solution', Name: 'Solution' },
                    { ScrollTo: 'customers', Name: 'Customers' },
                    { ScrollTo: '', Name: 'Documentation' },
                ]
            },
            */
            {
                name: 'CONSUMER',
                path: '/consumer',
                color: '#d14c48',
                active: false,
                altMenus:  [
                    { ScrollTo: 'technology', Name: translate.instant('TECHNOLOGY') },
                    { ScrollTo: 'applications', Name: translate.instant('APPLICATIONS') },
                    { ScrollTo: 'usage', Name: translate.instant('USAGE') },
                    { ScrollTo: 'prices', Name: translate.instant('PRICES') },
                    { ScrollTo: '', Name: translate.instant('DOCUMENTATIONS'), isDoc: true },
                ]
            },
        ];


        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.mobileMenuIsOpened = false;
                this.isOpen = false;

                // console.log(event.url);

                if (event.url.startsWith('/home')) {
                    this.loginBtnIcon = '/assets/GT_icon.png';
                    this.logoImg = '/assets/logos/logowhite.png';
                    this.altMenu = false;
                    this.urlName = '';
                } else if (event.url.startsWith('/construction')) {
                    this.loginBtnIcon = '/assets/GT ikon.png';
                    this.logoImg = '/assets/logos/logodark.webp';
                    this.altMenu = true;
                    this.urlName = '/construction';
                } else if (event.url.startsWith('/logistics')) {
                    this.loginBtnIcon = '/assets/GT ikon.png';
                    this.logoImg = '/assets/logos/logodark.webp';
                    this.altMenu = true;
                    this.urlName = '/logistics';
                } else if (event.url.startsWith('/consumer')) {
                    this.loginBtnIcon = '/assets/GT ikon.png';
                    this.logoImg = '/assets/logos/logodark.webp';
                    this.altMenu = true;
                    this.urlName = '/consumer';
                } else {
                    this.loginBtnIcon = '/assets/GT ikon.png';
                    this.logoImg = '/assets/logos/logodark.webp';
                    this.altMenu = false;
                    this.urlName = '';
                }
            }
        });
    }

    ngAfterViewInit() {
        this.isLoaded = true;
    }

    scrollTo(id: string): void {
        console.log(this.urlName, this.router.url);

        if (!this.router.url.startsWith(this.urlName) || this.router.url.includes('documentation')) {
            this.router.navigate([this.urlName], { fragment: id });
        } else {
            const element = document.getElementById(id);
            if (element) {
                const rect = element.getBoundingClientRect();
                const offsetTop = rect.top + window.pageYOffset;
                window.scrollTo({
                    top: offsetTop - 100,
                    behavior: "smooth"
                });
            }
        }
    }

    toggleMenu(menu: menu) {
        this.menus.forEach((m) => (m.active = false));
        menu.active = true;
    }

    toogleMoreMenu() {
        this.isOpen = !this.isOpen;
        this.morebtnShow = !this.morebtnShow;
    }

    @HostListener('window:resize', []) onResize() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
        this.mobileMenuIsOpened = false;
        this.isOpen = false;
    }

    @HostListener('window:orientationchange', []) onOrientationChange() {
        this.windowWidth = Math.min(window.outerWidth, window.innerWidth);
        this.mobileMenuIsOpened = false;
        this.isOpen = false;
    }

    @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
        const menuButton = document.getElementById('left-menu');
        const menuDiv = document.getElementById('left-menu-div');
        const langButton = document.getElementById('lang-button');
        if (this.isOpen && menuButton && !menuButton?.contains(event.target as Node) && !menuDiv?.contains(event.target as Node) && !langButton?.contains(event.target as Node)) {
            this.isOpen = false;
        }
    }

    protected readonly window = window;

    openFleet() {
        window.open('https://www.general-track.com/fleet/login.php')
    }

    get onDocumentationPage() {
        return this.sharedService.onDocumentationPage;
    }

    get activeAnchor(){
        return this.sharedService.activeAnchor || '';
    }
}
