import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons/faDotCircle';
import { fade } from '../../../../animations/fade.animation';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {StickyItemDirective} from "./directives/sticky-item.directive";

@Component({
    standalone: true,
    selector: 'new-platform',
    templateUrl: './platform.component.html',
    styleUrls: ['./platform.component.scss'],
    imports: [CommonModule, TranslateModule, StickyItemDirective],
        animations: [
        fade
    ]
})
export class PlatformComponent implements AfterViewInit {
    currentMockup = 0;
    images = [
        'assets/construction/platform/0_platform_graphic.webp',
        'assets/construction/platform/1_platform_devices.webp',
        'assets/construction/platform/2_platform_maintenance.webp',
        'assets/construction/platform/3_platform_hourmeter.webp',
        'assets/construction/platform/4_platform_motionevents.webp'
    ];

    isFixed = false;
    isReachedBottom = false;

    imageState: State = State.BEFORE_VIEWPORT;

    scrollY = 0;
    windowHeight = window.innerHeight;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        // this.onResize();
        console.log('Platform initialized');
    }

    get icons() {
        return {
            dummy: faDotCircle
        };
    }

    get State() {
        return State;
    }

    @HostListener('window:scroll', ['$event']) onScroll() {
        this.scrollY = (window.scrollY + window.innerHeight);


        const rect = (this.el?.nativeElement as HTMLDivElement)?.getBoundingClientRect();
        if (rect) {
            const reachedBottom = ((window.pageYOffset + rect.top) + rect.height) - window.innerHeight;
            if (reachedBottom <= window.scrollY) {
                console.log('BOTTOM');
            }
        }

    }

    @HostListener('window:resize', []) onResize() {
        this.windowHeight = window.innerHeight;
    }

    setCurrentMockup($event: State, id: number) {
        if ($event === State.IN_VIEWPORT) {
            this.currentMockup = id;
        } else if ($event === State.BEFORE_VIEWPORT) {
            this.currentMockup = (id || 1) - 1;
        }
    }

    get mockupImage() {
        return this.images[this.currentMockup];
    }
}

export enum State {
    BEFORE_VIEWPORT,
    IN_VIEWPORT,
    AFTER_VIEWPORT
}
