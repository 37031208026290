import { CommonModule } from '@angular/common';
import {Component, HostListener, Injectable, NgZone, OnInit} from '@angular/core';
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { FaIconComponent } from "@fortawesome/angular-fontawesome";
import { LocalStorageService } from "../../../../services/storage.service";
import { faDotCircle } from "@fortawesome/free-solid-svg-icons/faDotCircle";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faTemperatureHigh } from "@fortawesome/free-solid-svg-icons/faTemperatureHigh";
import { faRuler } from "@fortawesome/free-solid-svg-icons/faRuler";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons/faFileExcel";
import {
    faCircleXmark,
    faDoorOpen,
    faEarthEurope,
    faHouseChimneyUser, faPercent,
    faSignal, faSun
} from "@fortawesome/free-solid-svg-icons";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ScrollService} from "../../../../services/scroll.service";

@Component({
    selector: 'ourproducts-segment',
    standalone: true,
    imports: [ CommonModule, TranslateModule, FaIconComponent ],
    templateUrl: './ourproducts.component.html',
    styleUrl: './ourproducts.component.scss',
    animations: [trigger('fadeInOutAnimation', [
        state('in', style({opacity: 1, transform: 'translateX(0)', transition: 'all 0.4s ease'})),
        state('out', style({opacity: 0 , transform: 'translateX(-100%)',transition: 'all 0.4s ease'})),
        transition(':enter', [
            style({opacity: 0}),
            animate(0)
        ]),
        transition(':leave', [
            animate(0, style({opacity: 0}))
        ])
    ])]
})
export class OurproductsComponent implements OnInit{
    showAnimation: string = 'out';
    windowWidth = window.innerWidth;
    language;

    public cardState = {
        solo: false,
        connect: false,
        t20: false,
        connectXL: false,
        sense: false,
    };

    constructor(private storage: LocalStorageService, private translate: TranslateService,private ngZone: NgZone, private scrollService: ScrollService) {
        this.language = this.storage.get('language');
    }

    setState(product: string) {
        // @ts-ignore
        this.cardState[product] = !this.cardState[product];
    }

    ngOnInit() {
        this.scrollService.listenToElementPosition('ourproducts-section');
        this.showAnimation = 'in';
        //this.scrollService.elementPosition$.subscribe((pos: number) => {
        // if (pos <= 900 && pos > 0) {
        //     this.showAnimation = 'in';
        // } else {
        //     this.showAnimation = 'out';
        // }
        //});
    }

    get icons() {
        return {
            dummy: faDotCircle,
            marker: faMapMarkerAlt,
            temperature: faTemperatureHigh,
            size: faRuler,
            alarm: faBell,
            excel: faFileExcel,
            signal: faSignal,
            earth: faEarthEurope,
            door: faDoorOpen,
            humidity: faPercent,
            light: faSun,
            indoor: faHouseChimneyUser
        };
    }

    openPage($event: MouseEvent, url: string) {
        $event.preventDefault();
        const translatedUrl = this.translate.instant(url);
        window.open( translatedUrl, '_blank');
    }

    download($event: MouseEvent, key: string) {
        $event.preventDefault();

        const url = this.translate.instant(key);

        const a: HTMLAnchorElement = document.createElement('a')
        a.href = this.translate.instant(url);
        // @ts-ignore
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    @HostListener('window:resize', ['$event']) onResize(event : any) {
            this.windowWidth = event.target.innerWidth;
        }

    protected readonly faClose = faCircleXmark;
}
