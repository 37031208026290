import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import {NabiDifferentComponent} from "../../../../components/nabi-different/nabi-different.component";

@Component({
  selector: 'nabi-different-segment',
  standalone: true,
  imports: [CommonModule, NabiDifferentComponent],
  templateUrl: './nabi-different-segment.component.html',
  styleUrl: './nabi-different-segment.component.scss'
})
export class NabiDifferentSegmentComponent {

}
