import { Component } from '@angular/core';
import {CommonModule} from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: 'usage-of-nabi-segment',
  standalone: true,
    imports: [ CommonModule, TranslateModule ],
  templateUrl: './usage-of-nabi.component.html',
  styleUrl: './usage-of-nabi.component.scss'
})
export class UsageOfNabiComponent {

}
