<section class="Products" #products>
    <div class="Pane-backgroundContainer">
        <div class="Pane-background"></div>
    </div>

    <div class="Pane-content">
        <div class="space adaptive small"></div>
        <h1>{{ "PRODUCTS" | translate }}</h1>
        <div class="space adaptive small"></div>

        <div class="row">
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.solo" (click)="setState('solo')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/nabi_solo.png.webp" alt="Nabi Solo"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/nabi_solo_render.webp" alt="Nabi Solo Render"/>
                        </div>
                        <button type="button" class="btn btn-orange btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.signal"></fa-icon>
                                    <div>
                                        <b>{{ "CELLULAR_COMMUNICATION" | translate }}</b>
                                        <p>{{ "ULTRA_LOW_POWER_CONSUMPTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <b>{{ "GPS_POSITION" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/orientation.svg" alt="Oriantation icon">
                                    <div>
                                        <b>{{ "MOVEMENT_AND_OVERTURNING" | translate }}</b>
                                        <p>{{ "FREEFALL_DETECTION_TILT_DETECTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/vibration.svg" alt="Vibration icon">
                                    <div>
                                        <b>{{ "VIBRATION" | translate }}</b>
                                        <p>{{ "WORKHOUR_METERING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>4.3cm × 7.5cm</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_SOLO_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.connect" (click)="setState('connect')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/nabi_connect.png.webp" alt="Nabi Connect"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/nabi_connect_render.webp" alt="Nabi Connect Render"/>
                        </div>
                        <button type="button" class="btn btn-orange btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0; ">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <ul>
                                <li>
                                   <fa-icon [icon]="icons.signal"></fa-icon>
                                    <div>
                                        <b>{{ "CELLULAR_COMMUNICATION" | translate }}</b>
                                        <p>{{ "ULTRA_LOW_POWER_CONSUMPTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <b>{{ "GPS_POSITION" | translate }}</b>
                                        <p>{{ "REAL_TIME_TRACKING_FOR_YEARS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/orientation.svg" alt="Oriantation icon">
                                    <div>
                                        <b>{{ "MOVEMENT_AND_OVERTURNING" | translate }}</b>
                                        <p>{{ "FREEFALL_DETECTION_TILT_DETECTION" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/vibration.svg" alt="Vibration icon">
                                    <div>
                                        <b>{{ "VIBRATION" | translate }}</b>
                                        <p>{{ "WORKHOUR_METERING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.temperature"></fa-icon>
                                    <div>
                                        <b>{{ "TEMPERATURE" | translate }}</b>
                                        <p>{{ "MONITORING_AND_ALARM" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.size"></fa-icon>
                                    <div>
                                        <b>{{ "COMPACT_SIZE" | translate }}</b>
                                        <p>4.3cm × 7.5cm</p>
                                    </div>
                                </li>
                                <li>
                                       <fa-icon [icon]="icons.indoor"></fa-icon>
                                    <div>
                                        <b [style.color]="'#f49801'">{{ "INDOOR_POSITIONING" | translate }}</b>
                                        <p>{{ "LOCALIZE_YOU_EQUIPMENT_WITHIN_THE_BUILDING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <div>
                                        <b [style.color]="'#f49801'" [innerHTML]="'CONNECT_WITH_NABI_SENSE' | translate"></b>
                                        <p [innerHTML]="'SUPPORT_OF_SMALL_EXTERNAL_LOW_COST_BLUETOOTH_TAGS' | translate"></p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" class="btn btn-secondary btn-block" (click)="openPage($event, 'NABI_CONNECT_DOCS')">{{ "DATASHEET" | translate }}</button>
                        </div>
                    </div>
                </div></div>
            <div class="col-12 col-md-6 col-xl-4">
                <div class="Card" [class.active]="cardState.t20" (click)="setState('t20')">
                    <div class="card-side front">
                        <div class="image-container">
                            <img src="assets/construction/cards/t20.png.webp" alt="T20 tracker"/>
                        </div>
                        <div class="image-container render">
                            <img src="assets/construction/cards/T20_render.webp" alt="T20 Render"/>
                        </div>
                        <button type="button" class="btn btn-orange btn-block">{{ "SHOW_MORE" | translate }}</button>
                    </div>
                    <div class="card-side back">
                        <div class="position-relative w-100">
                            <button class="btn btn-link x-button" style="position: absolute; right: 0 !important; top: 0 !important; padding: 0;">
                                <fa-icon [icon]="faClose" type="button"></fa-icon>
                            </button>
                            <p style="padding: 32px 0;">{{ "T20_DESC" | translate }}</p>

                            <b>{{ "SERVICES" | translate }}</b>
                            <ul>
                                <li>
                                    <fa-icon [icon]="icons.marker"></fa-icon>
                                    <div>
                                        <p>{{ "CONTINUOUS_GPS_VEHICLE_TRACKING" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <img src="assets/geofence.svg" alt="Geofence logo">
                                    <div>
                                        <p>{{ "GEOFENCE" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.alarm"></fa-icon>
                                    <div>
                                        <p>{{ "PERSONALIZED_ALARMS" | translate }}</p>
                                    </div>
                                </li>
                                <li>
                                    <fa-icon [icon]="icons.excel"></fa-icon>
                                    <div>
                                        <p>{{ "ISSUE_LETTERS_OF_TRANSIT" | translate }}</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <a class="btn btn-orange btn-block" [href]="'T20_URL' | translate" target="_blank">{{ "MORE_DETAILS" | translate }}</a>
                    </div>
                </div></div>
        </div>
        <div class="space adaptive large"></div>
    </div>
</section>
