<section class="info-bg z-5">
    <div class="bg-img"></div>
    <div class="justify-content-center align-item-center text-white info-section container">

        <div class="row">
            <section class="col-12">
                <h1
                    class="text-uppercase display-1 fw-semibold"
                    [@slideVertically]>
                    {{ "WE_TRACK_EVERYTHING" | translate }}
                </h1>
            </section>
        </div>

        <div class="row pt-3 pt-3">
            <section class="col-12 d-flex justify-content-center align-items-center">
                <p
                    class="d-flex justify-content-center text-left align-items-center description-p lh-base mt-2 mt-lg-0"
                    [@slideVertically]>
                    {{ "WE_TRACK_EVERYTHING_DESC" | translate }}
                </p>
            </section>
        </div>

    </div>
    <div class="space adaptive small"></div>
    <div class="container">
        <div class="row flex-row-reverse justify-content-center circles z-1">

            <div class="col-12 col-lg-4" routerLink="/consumer">
                    <p class="circle-head" >
                        {{ "CIRCLE_CONSUMER" | translate }}
                    </p>
                <div class="circle-div">
                    <div class="circle-container">
                        <div class="circleimg consumer_img"></div>
                    </div>
                </div>
            </div>
                <div class="col-12 col-lg-4" routerLink="/logistics">
                        <p class="circle-head" >
                            {{ "CIRCLE_LOGISTICS" | translate }}
                        </p>
                    <div class="circle-div">
                        <div class="circle-container">
                            <div class="circleimg logistics_img"></div>
                        </div>
                    </div>
                </div>
                <!--
                    div class="col-12 col-lg-3" [@slideInHorizontallyDelay]>
                        <div>
                            <p class="row text-uppercase d-flex justify-content-center">
                                {{ "CIRCLE_SMART" | translate }}
                            </p>
                            <div class="circle-container">
                                <div class="circleimg pipe_img"></div>
                            </div>
                        </div>
                    </div
                 -->
            <div class="col-12 col-lg-4" routerLink="/construction">
                    <p class="circle-head" >
                        {{ "CIRCLE_CONSTRUCTION" | translate }}
                    </p>
                <div class="circle-div">
                    <div class="circle-container">
                        <div class="circleimg construction_img"></div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-6 col-lg-3">
              <div [@slideVertically]>
                  <p class="row text-uppercase d-flex justify-content-center">
                      Construction
                  </p>
                  <div class="circle-container">
                      <div class="circleimg construction_img"></div>
                  </div>
              </div>
          </div>
          <div class="col-6 col-lg-3">
              <div [@slideVertically]>
                  <p class="row text-uppercase d-flex justify-content-center">
                      Logistic
                  </p>
                  <div class="circle-container">
                      <div class="circleimg logistics_img"></div>
                  </div>
              </div>
          </div>
          <div class="col-6 col-lg-3">
              <div [@slideVertically]>
                  <p class="row text-uppercase d-flex justify-content-center">
                      Smart metering
                  </p>
                  <div class="circle-container">
                      <div class="circleimg pipe_img"></div>
                  </div>
              </div>
          </div>
          <div class="col-6 col-lg-3">
              <div [@slideVertically]>
                  <p class="row text-uppercase d-flex justify-content-center">
                      Consumer
                  </p>
                  <div class="circle-container">
                      <div class="circleimg consumer_img"></div>
                  </div>
              </div>
          </div> -->
        </div>
    </div>
    <div class="space adaptive large"></div>

</section>
<!--<section class="promo-video bg-black d-flex flex-column justify-content-center align-items-center">-->
<!--    <div class="container">-->
<!--        <video autoplay controls loop muted playsinline id="promoVideo" width="80%">-->
<!--            <source src="assets/videos/Promo_video_new_logo.mp4" type="video/mp4">-->
<!--            <span data-translate-text="">Az Ön böngészőjében nincs engedélyezve a videók lejátszása.</span>-->
<!--        </video>-->
<!--    </div>-->
<!--</section>-->
